import {MenuTypes, StorageFileCategory} from '@api';
import {TKeys} from '@src/i18n/useTranslate';
import {STORAGE_CATEGORY_TYPE_MAP} from '@src/shared/constants/enumsMaps';

export const getMenuTypesOptions = (
  translate: (str: TKeys<'storage'> | null | undefined) => string,
) => {
  return [
    {label: translate('VIEW_ALL'), value: MenuTypes.ALL},
    {label: translate('RECENT'), value: MenuTypes.RECENT},
    {label: translate('FAVORITES'), value: MenuTypes.FAVORITE},
  ];
};

export const categoryOptions = Object.values(StorageFileCategory).map((name) => ({name: STORAGE_CATEGORY_TYPE_MAP[name], value: name}));
