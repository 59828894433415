import {SxProps, Theme} from '@mui/material';

export const sx = {
  commentText: {
    pt: 5,
    whiteSpace: 'wrap',
    wordBreak: 'break-all',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': '4',
  },
  inProgressRow: {
    backgroundColor: 'grey.100',
  },
  headerCheckBox: {
    pl: 4,
    '.MuiFormControlLabel-label .MuiTypography-root': {
      fontWeight: 700,
    },
  },
  testNameWrapper: {
    justifyContent: 'center',
    minHeight: 29,
    overflow: 'hidden',
    whiteSpace: 'pre-wrap',
  },
} satisfies Record<string, SxProps<Theme>>;
