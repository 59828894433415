import {Theme} from '@mui/material';

export const sx = {
  mainContainer: (t: Theme) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: {xs: 18, sm: 24, lg: 36},
    flexGrow: 1,
    boxSizing: 'border-box',
    [t.breakpoints.between('sm', 'md')]: {
      padding: 24,
    },
    [t.breakpoints.down('sm')]: {
      paddingBottom: '110px',
    },
  }),
  topContainer: (t: Theme) => ({
    flexDirection: {lg: 'row'},
    alignItems: {xs: 'stretch', lg: 'center'},
    justifyContent: 'space-between',
    gap: {xs: 18, sm: 24, lg: 'initial'},
    [t.breakpoints.down('md')]: {
      alignItems: 'stretch',
    },
  }),
  buttonsContainer: (t: Theme) => ({
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    padding: 18,
    boxShadow: '0px -2px 12px 0px #0000001A',
    background: t.colors.background,
    gap: 12,
    display: 'grid',
  }),
  wBoxContainer: {
    flexGrow: 1,
  },
  bthContainer: {
    display: 'flex',
    gap: 36,
  },
  wBoxContent: {
    flexGrow: 1,
    p: {
      xs: '14px 18px',
      md: 0,
    },
  },
  gridWrapper: {
    padding: '0px',
  },
  boxPaddings: {
    padding: {xs: '18px 16px', md: 24, lg: '24px 36px'},
  },
  resetPaddingBox: {
    p: 0,
  },
  boxContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerSx: {
    mb: {xs: 12, lg: 36},
  },
  breadcrumbsText: (t: Theme) => ({
    color: t.colors.breadcrumbsText,
    [t.breakpoints.only('xs')]: {
      typography: '14_18_500',
    },
  }),
  container: {
    padding: {xs: 0, md: '24px 24px 0'},
    gap: {xs: 18, sm: 24, lg: 36},
  },
};
