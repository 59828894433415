import {StorageBiomarker, StorageFileCategory} from '@api';
import {Dialog, DialogTitle} from '@components';
import {Box, debounce, FormControlLabel, Radio, Stack, Typography} from '@mui/material';
import {AttachedFile} from '@src/components/AttachedFile';
import {VisuallyHiddenInput} from '@src/components/FileUploader/FileUploader';
import {FormDateInput} from '@src/components/FormDateInput';
import {FormInputControl} from '@src/components/FormInputControl';
import {FormMultiSelect} from '@src/components/FormMultiSelect';
import {FormRadioGroup} from '@src/components/FormRadioGroup';
import {FormSelect} from '@src/components/FormSelect';
import {FormTextareaControl} from '@src/components/FormTextareaControl';
import {MobileInteractionView} from '@src/components/MobileInteractionView';
import {useTranslate} from '@src/i18n/useTranslate';
import {DEFAULT_PER_PAGE} from '@src/pages/Storage/constants';
import {getCategoryOptions} from '@src/pages/Storage/helpers';
import {ReactComponent as DownloadIcon} from '@src/shared/assets/icons/download.svg';
import {ReactComponent as ErrorIcon} from '@src/shared/assets/icons/error.svg';
import {MAX_STORAGE_FILE_NAME_LENGTH} from '@src/shared/constants/formFields';
import {useMQuery} from '@src/shared/hooks';
import {getFileNameAndExtension} from '@src/shared/utils';
import {fetchHealthCases} from '@src/store/healthCases/slice';
import {useAppDispatch, useAppSelector} from '@store';
import {isEqual} from 'lodash-es';
import {ChangeEvent, FC, useEffect, useMemo, useState} from 'react';
import {FormProvider, useFieldArray, useForm} from 'react-hook-form';
import {Button, MenuItem} from 'ui-kit';

import {Biomarker} from './components/Biomarker';
import {FILES_ACCEPT, INITIAL_BIOMARKER, MAX_FILE_SIZE_BYTES} from './constants';
import {getDefaultValues} from './helpers';
import {useDocumentValidationSchema} from './hooks';
import {sx} from './styles';
import {DialogFile, EditDocumentDialogProps, EditFileDialogForm} from './types';

export const EditDocumentDialog: FC<EditDocumentDialogProps> = ({
  fileDetails,
  isOpen,
  onClose,
  onSave,
}) => {
  const dispatch = useAppDispatch();
  const {t} = useTranslate('storage');
  const {mobile: isMobile} = useMQuery();
  const [file, setFile] = useState<DialogFile | null>(null);
  const [errorType, setErrorType] = useState(false);
  const [errorSize, setErrorSize] = useState(false);

  const isEdit = !!fileDetails;

  const {cases, isLoading} = useAppSelector((state) => state.healthCases);

  const resolver = useDocumentValidationSchema();
  const methods = useForm<EditFileDialogForm>({
    defaultValues: getDefaultValues(fileDetails),
    resolver,
  });

  const [searchValueHealthCase, setSearchValueHealthCase] = useState('');

  const handleChangeSearchValue = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchValueHealthCase(value);
  };

  const handleClearSearchValue = () => {
    setSearchValueHealthCase('');
  };

  const isSaveDisabled = useMemo(() => {
    const formValues = methods.watch();
    const initialValues = getDefaultValues(fileDetails);

    if (file?.error || !file) {
      return true;
    }
    if (!isEqual(formValues, initialValues)) {
      return false;
    }
    if (file.size !== fileDetails?.size) {
      return false;
    }

    return true;
  }, [methods.watch(), file, fileDetails]);

  useEffect(() => {
    if (fileDetails) {
      const {size, type} = fileDetails;
      setFile({
        type,
        size,
      });
    }
  }, [fileDetails]);

  const formattedBiomarkers = useMemo(() => {
    if (!fileDetails?.biomarkers) {
      return {};
    }
    const biomarkersByKey: Record<string, StorageBiomarker> = {};
    fileDetails.biomarkers.forEach((biomarker) => {
      biomarkersByKey[biomarker.id] = biomarker;
    });

    return biomarkersByKey;
  }, [fileDetails?.biomarkers]);

  const handleUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files?.length) {
      return;
    }

    setErrorType(false);
    setErrorSize(false);
    const filesArray = Array.from(files);
    const firstFile = filesArray[0];

    const {name, extension} = getFileNameAndExtension(firstFile.name);
    const validExtensions = FILES_ACCEPT.replace(/\./g, '')
      .split(', ')
      .map((ext) => ext.trim());

    let localErrorType = false;
    let localErrorSize = false;

    if (!validExtensions.includes(extension.toLowerCase())) {
      localErrorType = true;
      setErrorType(true);
    }

    if (firstFile.size > MAX_FILE_SIZE_BYTES) {
      localErrorSize = true;
      setErrorSize(true);
    }

    if (localErrorType || localErrorSize) {
      setFile(null);
      return;
    }

    setFile({
      name,
      size: firstFile.size,
      type: extension,
      error: localErrorSize,
      file: firstFile,
    });

    if (event.target.value) {
      event.target.value = '';
    }
  };

  const handleDeleteFile = () => {
    setFile(null);
  };

  const {
    fields: createdBiomarkers,
    remove: removeCreatedBiomarker,
    append: appendCreatedBiomarker,
  } = useFieldArray({name: 'createBiomarkers', control: methods.control});

  const {fields: editedBiomarkers, remove: removeEditedBiomarker} = useFieldArray({
    name: 'editBiomarkers',
    control: methods.control,
  });

  const handleAddEmptyBiomarker = () => {
    appendCreatedBiomarker(INITIAL_BIOMARKER);
  };

  const handleRemoveEditedBiomarker = (index: number) => () => {
    removeEditedBiomarker(index);
    if (index === 0 && !createdBiomarkers.length) {
      handleAddEmptyBiomarker();
    }
  };

  const handleRemoveCreatedBiomarker = (index: number) => () => {
    removeCreatedBiomarker(index);
    if (index === 0 && !editedBiomarkers.length) {
      handleAddEmptyBiomarker();
    }
  };

  const isLabReports = methods.watch('category') === StorageFileCategory.LABORATORY_REPORT;
  const isRecognize = methods.watch('recognize');

  useEffect(() => {
    if (!fileDetails) {
      methods.setValue('recognize', isLabReports);
    }
  }, [isLabReports, fileDetails]);

  const handleClose = () => {
    methods.reset({
      ...getDefaultValues(),
      editBiomarkers: [],
    });
    onClose();
    setErrorType(false);
    setErrorSize(false);
    setFile(null);
  };

  const getDisplayedError = () => {
    return (
      <>
        {errorSize && <Typography variant="14_18_500">{t('ALLOWED_ATTACHMENT_SIZE')}</Typography>}
        {errorType && <Typography variant="14_18_500">{t('INVALID_FILE_TYPE')}</Typography>}
      </>
    );
  };

  const handleSubmit = async ({
    editBiomarkers,
    createBiomarkers,
    recognize,
    healthCaseIds,
    category,
    ...values
  }: EditFileDialogForm) => {
    await onSave(
      {
        editBiomarkers:
          isLabReports && !recognize
            ? editBiomarkers?.map(({initialId, biomarkerId, biomarkerUnitId, value}) => ({
              id: initialId as string,
              biomarkerId,
              biomarkerUnitId,
              value,
            }))
            : undefined,
        createBiomarkers:
          isLabReports && !recognize
            ? createBiomarkers?.map(({biomarkerId, biomarkerUnitId, value}) => ({
              biomarkerId,
              biomarkerUnitId,
              value,
            }))
            : undefined,
        favorite: fileDetails?.favorite || false,
        recognize: !isEdit && isLabReports ? recognize : undefined,
        healthCaseIds: isEdit ? healthCaseIds : undefined,
        category: (!isEdit && category) ? category : undefined,
        ...values,
      },
      file?.file,
    );
    handleClose();
  };

  const getDisabledAddBiomarker = (createBiomarkers: EditFileDialogForm['createBiomarkers']) => {
    const lastCreatedBiomarker = createBiomarkers?.[createBiomarkers.length - 1];
    if (!lastCreatedBiomarker) {
      return false;
    }

    const {hasUnits, biomarkerUnitId, value, biomarkerId} = lastCreatedBiomarker;

    const isBiomarkerIdMissing = !biomarkerId;
    const isValueMissing = !value;
    const isUnitIdMissing = hasUnits && !biomarkerUnitId;

    return isBiomarkerIdMissing || isValueMissing || isUnitIdMissing;
  };

  const handleFetchHealthCases = debounce((isNext: boolean) => {
    if (isNext && !cases.hasNext && !isLoading) {
      return;
    }
    const {from, to, ...restFilters} = cases.filters;
    void dispatch(
      fetchHealthCases({
        ...restFilters,
        from: from ? from.toISOString().split('T')[0] : undefined,
        to: to ? to.toISOString().split('T')[0] : undefined,
        startPage: isNext ? cases.page : 0,
        perPage: DEFAULT_PER_PAGE,
        isNext,
      }),
    );
  }, 300);

  useEffect(() => {
    handleFetchHealthCases(false);
  }, []);

  const renderForm = () => (
    <FormProvider {...methods}>
      <Stack flex={'1'} overflow={'scroll'}>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <Stack component={'form'} sx={sx.formWrapper} onSubmit={methods.handleSubmit(handleSubmit)}>
          <Box sx={sx.contentWrapper}>
            <Stack sx={sx.content}>
              <FormInputControl
                label={t('NAME')}
                ignoreLabelHeight
                name={'name'}
                max={MAX_STORAGE_FILE_NAME_LENGTH}
                maxlength={MAX_STORAGE_FILE_NAME_LENGTH}
              />
              <FormSelect
                label={t('CATEGORY')}
                placeholder={t('SELECT')}
                name={'category'}
                disabled={isEdit}
              >
                {getCategoryOptions(t).map((option) => (
                  <MenuItem key={option.label} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </FormSelect>
              {isLabReports && (
                <>
                  <Stack gap={12}>
                    <Typography variant={'14_18_700'}>{t('BIOMARKERS')}</Typography>
                    <FormRadioGroup
                      name={'recognize'}
                      row
                      isBooleanValue
                      sx={{gap: 10}}>
                      <FormControlLabel
                        value={true}
                        control={<Radio color={'secondary'} size={'small'} disabled={isEdit} />}
                        label={t('AUTOMATIC_RECOGNITION')}
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio color={'secondary'} size={'small'} disabled={isEdit} />}
                        label={t('MANUAL_ENTRY')}
                      />
                    </FormRadioGroup>
                    {(!isRecognize || !!editedBiomarkers.length) && (
                      <Stack gap={12} my={12}>
                        {editedBiomarkers.map((biomarker, index) => (
                          <Biomarker
                            key={biomarker.id}
                            biomarker={biomarker}
                            onRemove={handleRemoveEditedBiomarker(index)}
                            name={`editBiomarkers.${index}`}
                            initialBiomarker={formattedBiomarkers[biomarker.initialId || '']}
                            isEdit={isEdit && isRecognize}
                          />
                        ))}
                        {!isRecognize &&
                          createdBiomarkers.map((biomarker, index) => (
                            <Biomarker
                              key={biomarker.id}
                              biomarker={biomarker}
                              onRemove={handleRemoveCreatedBiomarker(index)}
                              name={`createBiomarkers.${index}`}
                            />
                          ))}
                      </Stack>
                    )}
                    {!isRecognize && (
                      <Box>
                        <Button
                          variant={'outlined'}
                          onClick={handleAddEmptyBiomarker}
                          disabled={getDisabledAddBiomarker(methods.watch('createBiomarkers'))}
                          size={'md'}
                        >
                          {t('ADD_BIOMARKER')}
                        </Button>
                      </Box>
                    )}
                    {isRecognize && !editedBiomarkers.length && (
                      <Typography variant={'14_18_500'} color={'grey.600'}>
                        {t('RECOGNIZE_TIP')}
                      </Typography>
                    )}
                  </Stack>
                </>
              )}
              <FormDateInput
                label={t('DATE_OF_THE_DOCUMENT')}
                labelTop
                name={'date'}
                placeholder={'mm/dd/yyyy'}
                disabledFuture
              />
              <Stack>
                <FormTextareaControl
                  name={'description'}
                  label={t('DESCRIPTION')}
                  limit={1000}
                  max={1000}
                  placeholder={t('YOUR_MESSAGE')}
                  optional
                />
                {isEdit && (
                  <FormMultiSelect
                    optional
                    label={t('HEALTH_CASES')}
                    placeholder={t('SELECT')}
                    name={'healthCaseIds'}
                    onChangeSearchValue={handleChangeSearchValue}
                    searchValue={searchValueHealthCase}
                    onClear={handleClearSearchValue}
                    renderValue={(val) =>
                      val.length > 1 ? val[0] + ` +${val.length - 1}` : val[0] || ''
                    }
                    options={cases.items.map(({name, id: value}) => ({name, value}))}
                  />
                )}
              </Stack>
              {!isEdit && (
                <>
                  <Stack alignItems={'flex-start'} gap={12}>
                    <Button
                      startIcon={<DownloadIcon />}
                      variant={'text'}
                      component={'label'}
                      sx={sx.uploadButton}
                      onClick={(e) => e.stopPropagation()}
                      disabled={!!file}
                    >
                      <VisuallyHiddenInput
                        type={'file'}
                        name={'file'}
                        accept={FILES_ACCEPT}
                        onChange={handleUploadFile}
                      />
                      {t('UPLOAD_DOCUMENT')}
                    </Button>
                    <Typography variant={'14_18_500'} color={(theme) => theme.palette.grey['500']}>
                      {t('UPLOAD_TIP')}
                    </Typography>
                  </Stack>
                  {(errorType || errorSize) && (
                    <Stack sx={sx.errorFile}>
                      <ErrorIcon />
                      <Stack flexDirection={'column'}>{getDisplayedError()}</Stack>
                    </Stack>
                  )}
                  {file && (
                    <AttachedFile
                      name={file.name || file.type}
                      ext={file.name ? file.type : ''}
                      size={file.size}
                      error={file.error}
                      onDelete={handleDeleteFile}
                      containerSx={sx.file}
                    />
                  )}
                </>
              )}
            </Stack>
          </Box>
          <Stack sx={sx.actionsContainer}>
            <Button variant={'outlined'} color={'secondary'} onClick={handleClose}>
              {t('CANCEL')}
            </Button>
            <Button color={'secondary'} type={'submit'} disabled={isSaveDisabled}>
              {t('SAVE')}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </FormProvider>
  );

  if (isMobile) {
    return (
      <Stack
        sx={{
          alignItems: 'center',
          position: 'relative',
        }}>
        <MobileInteractionView
          isOpen={isOpen}
          title={isEdit ? t('EDIT_DOCUMENT') : t('ADD_DOCUMENT')}
          onBack={onClose}
          onClose={handleClose}
        >
          {renderForm()}
        </MobileInteractionView>
      </Stack>
    );
  }

  return (
    <Dialog
      open={isOpen}
      size={'lg'}
      scroll={'body'}
      border={'unset'}
      sx={sx.dialog}>
      <DialogTitle onClose={handleClose}>
        {isEdit ? t('EDIT_DOCUMENT') : t('ADD_DOCUMENT')}
      </DialogTitle>
      {renderForm()}
    </Dialog>
  );
};
