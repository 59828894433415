import {
  Box,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import {FlagType, Result} from '@src/api';
import {ResultType} from '@src/api/__generated__/webApi';
import {CircleStatus} from '@src/components';
import {EllipsisTextTooltip} from '@src/components/EllipsisTextTooltip';
import {EBackgroundFlagColor, EFlagColor, EFlagName} from '@src/pages/Results/Results.constant';
import {ROUTERS_PATH} from '@src/routers';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {dateFormatted, getFullName, NO_VALUE} from '@src/shared/utils';
import {alpha} from '@src/theme/utils';
import {ISODate} from '@src/types';
import {useEffect, useRef, useState} from 'react';
import {generatePath, useNavigate} from 'react-router-dom';

const sx: Partial<Record<string, SxProps<Theme>>> = {
  tableContainer: (t) => ({
    borderTop: '1px solid',
    borderColor: alpha(t.palette.secondary.main, 14),
    overflowY: 'hidden',
    overflowX: 'hidden',
  }),
  table: {
    td: {
      height: {
        xs: 48,
        sm: 'inherit',
        lg: 48,
      },
      padding: {
        xs: '16px 0px',
        sm: '15px 0px',
        xxl: '16px 0px',
      },
      '&:nth-child(2)': {
        padding: {
          xs: '0px 20px',
          sm: '15px 20px 15px 20px',
          xxl: '16px 20px 16px 20px',
        },
      },
      '&:nth-child(3)': {
        padding: {
          xxl: '14px 0px',
        },
      },
    },
  },
  tableRow: (t) => ({
    height: {xs: 48, sm: 52, lg: 48},
    padding: {xs: '0px 18px', sm: '0px 24px'},
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    '&:hover': {
      backgroundColor: alpha(t.palette.secondary.main, 7),
      cursor: 'pointer',
    },
    '&:active': {
      backgroundColor: alpha(t.palette.secondary.main, 14),
      cursor: 'pointer',
    },
    '&:not(:last-child)::after': {
      content: "''",
      display: 'block',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      borderBottom: `1px solid ${alpha(t.palette.secondary.main, 14)}`,
    },
    '& td': {
      borderBottom: 'none',
    },
  }),
  link: {
    display: 'flex',
    height: '100%',
    cursor: 'pointer',
  },
  flag: {
    width: 24,
    height: 24,
    display: 'inline-flex',
    alignItems: 'center',
    padding: {
      xs: '0 8px',
      sm: 'inherit',
      xxl: '14px 0px',
    },
  },
  flagCell: (t: Theme) => ({
    minWidth: {xl: '8%'},
    fontSize: {
      xs: 12,
      sm: 16,
      lg: 18,
    },
    alignItems: 'center',
    justifyContent: 'flex-end',
    display: 'flex',
    pb: {
      xs: 7,
      sm: 'inherit',
    },
    [t.breakpoints.up('sm')]: {
      width: 90,
    },
  }),
  physicianCell: (t: Theme) => ({
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    flex: {xs: 2},
    fontSize: {
      xs: 12,
      sm: 16,
      lg: 18,
    },
    [t.breakpoints.down('sm')]: {
      width: 146,
      flex: 2,
    },
  }),
  reportedCell: {
    display: 'flex',
    alignItems: 'center',
    width: {xs: 93, sm: 120, md: 200, lg: 110, xl: 200},
    typography: {lg: '14_18_500'},
  },
  ellipsisText: {
    typography: {
      xs: '12_16_500',
      sm: '14_18_500',
    },
  },
};

const COLORS = {
  [EFlagName.ABNORMAL]: {
    color: EFlagColor.ABNORMAL,
    backgroundColor: EBackgroundFlagColor.ABNORMAL,
  },
  [EFlagName.NORMAL]: {
    color: EFlagColor.NORMAL,
    backgroundColor: EBackgroundFlagColor.NORMAL,
  },
  [EFlagName.MANUAL]: {
    color: EFlagColor.MANUAL,
    backgroundColor: EBackgroundFlagColor.MANUAL,
  },
  [EFlagName.CRITICAL]: {
    color: EFlagColor.CRITICAL,
    backgroundColor: EBackgroundFlagColor.CRITICAL,
  },
};

export const OverviewTable = ({results}: { results: Result[] }) => {
  const {mobile, tabletPortrait, lg: isDesktopLg, xl: isDesktopXl} = useMQuery();
  const navigate = useNavigate();

  const navigateToResults = ({id, type}: Result) => {
    const resultDetailPath = generatePath(ROUTERS_PATH.RESULT_DETAILS, {
      resultId: id,
    });
    navigate(`${resultDetailPath}?type=${type || ''}`);
  };

  const renderReportedDateCell = (reported?: ISODate) => (
    <TableCell sx={sx.reportedCell}>
      <Typography noWrap sx={sx.ellipsisText} component="p">
        {dateFormatted(reported) ?? NO_VALUE}
      </Typography>
    </TableCell>
  );

  const renderMobileFlagCell = (flag?: FlagType, type?: ResultType) => {
    let usedFlag = flag;
    if (type === ResultType.MANUAL) {
      usedFlag = 'NORMAL';
    }
    return (
      <TableCell sx={sx.flagCell}>
        {usedFlag && (
          <Box sx={sx.flag}>
            <CircleStatus color={EFlagColor[usedFlag]} /> {!mobile ? EFlagName[usedFlag] : ''}
          </Box>
        )}
      </TableCell>
    );
  };

  const renderDesktopFlagCell = (flag?: FlagType, type?: ResultType) => {
    let usedFlag: FlagType | 'MANUAL' | undefined = flag;
    if (type === ResultType.MANUAL) {
      usedFlag = 'MANUAL';
    }
    return (
      <TableCell sx={sx.flagCell}>
        {usedFlag && (
          <Box
            sx={{
              ...COLORS[EFlagName[usedFlag]],
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: 24,
              width: 'fit-content',
              padding: '0 8px',
            }}
          >
            <Typography variant="14_18_500"> {EFlagName[usedFlag]} </Typography>
          </Box>
        )}
      </TableCell>
    );
  };

  const [physicianCellWidth, setPhysicianCellWidth] = useState('715px');
  const tableContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleResize = () => {
      if (tableContainerRef.current) {
        const containerWidth = tableContainerRef.current.offsetWidth;

        if (containerWidth > 768) {
          setPhysicianCellWidth('297px');
        } else if (tabletPortrait && containerWidth >= 540) {
          setPhysicianCellWidth('147px');
        } else if (isDesktopXl && containerWidth >= 650) {
          setPhysicianCellWidth('147px');
        } else if (isDesktopLg && containerWidth >= 406) {
          setPhysicianCellWidth('147px');
        } else {
          setPhysicianCellWidth('715px');
        }
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [tableContainerRef, physicianCellWidth, tabletPortrait, isDesktopXl, isDesktopLg]);

  return (
    <TableContainer ref={tableContainerRef} sx={sx.tableContainer}>
      <Table sx={sx.table}>
        <TableBody>
          {results?.slice(0, 3).map((item) => (
            <TableRow key={item?.id} sx={sx.tableRow} onClick={() => navigateToResults(item)}>
              {renderReportedDateCell(item.reported || item.date)}
              <TableCell width={physicianCellWidth} sx={sx.physicianCell}>
                <EllipsisTextTooltip ellipsisText={getFullName(item?.physician)} sx={sx.ellipsisText} />
              </TableCell>
              {mobile && renderMobileFlagCell(item.flag, item.type)}
              {!mobile && renderDesktopFlagCell(item.flag, item.type)}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
