import {Stack, Box} from '@mui/material';
import Typography from '@mui/material/Typography';
import {useTranslate} from '@src/i18n/useTranslate';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {spreadSx} from '@src/shared/utils/spreadSx';
import {AccountType, RequestsContextType, RequestsType} from '@src/types';
import {useEffect, useLayoutEffect, useState} from 'react';
import {useLocation, useNavigate, useOutletContext} from 'react-router-dom';

import {Requests} from '../Requests';

import {AccessesCardsBlock} from './AccessesCardsBlock';
import {AccessesDeleteModal} from './AccessesDeleteModal';
import {MobileTabs} from './MobileTabs';
import {ExpiredCheckBox} from './common/ExpiredCheckBox';
import {RequestAccessBtn} from './common/RequestAccessBtn';
import {sx} from './styles';
import {useContainerAlignment} from './useContainerAlignment';

export const Accesses = () => {
  const {t, ready} = useTranslate('accesses');
  const {mobile: isMobile, tablet: isTablet, desktop: isDesktop} = useMQuery();

  const {isIncomingRequest, setIsIncomingRequest, accesses} = useOutletContext<RequestsContextType>();

  const {state} = useLocation();
  const navigate = useNavigate();

  const [showExpired, setShowExpired] = useState<boolean>(false);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isRequestsBlockOpen, setIsRequestsBlockOpen] = useState<boolean>(true);

  const {containerWidth, alignItems} = useContainerAlignment();

  const [accessesTypeForMobile, setAccessesTypeForMobile] = useState<AccountType>(
    state?.currTabActive || AccountType.Incoming,
  );

  const visibleAccesses = isIncomingRequest
    ? accesses?.incomingAccountAccesses
    : accesses?.outgoingAccountAccesses;

  const hasAccesses = accesses?.companyAccesses?.length !== 0 && visibleAccesses?.length !== 0;
  const disabledScroll = isRequestsBlockOpen && !isDesktop;

  useEffect(() => {
    if (state) {
      navigate(window.location.pathname, {replace: true});
    }
  }, [state, navigate]);

  useLayoutEffect(() => {
    setIsRequestsBlockOpen(!isTablet);
  }, [isTablet]);

  if (!ready) {
    return null;
  }

  if (isMobile) {
    return (
      <MobileTabs
        accessesTypeForMobile={accessesTypeForMobile}
        setAccessesTypeForMobile={setAccessesTypeForMobile}
      />
    );
  }

  const isAccessesEmpty =
    !accesses?.companyAccesses?.length && !visibleAccesses?.length;

  return (
    <>
      <Box sx={sx.container}>
        <Requests
          isIncomingRequest={isIncomingRequest}
          setIsIncomingRequest={setIsIncomingRequest}
          setIsRequestsBlockOpen={setIsRequestsBlockOpen}
          isRequestsBlockOpen={isRequestsBlockOpen}
          requestsType={RequestsType.Accesses}
        />
        <Stack sx={sx.content}>
          <Stack>
            <Stack ref={containerWidth} sx={[...spreadSx(sx.buttonGroups), alignItems]}>
              <Box sx={sx.boxContainer}>
                <Typography mr={36} variant="24_34_500">
                  {t('ACCEPTED')}
                </Typography>
                {!isMobile && hasAccesses && (
                  <ExpiredCheckBox
                    checked={showExpired}
                    onChange={() => setShowExpired(!showExpired)}
                  />
                )}
              </Box>
              {!isMobile && <RequestAccessBtn />}
            </Stack>
            {isAccessesEmpty && (
              <Typography sx={sx.emptyAccessesText}>{t('NO_ACCESSES')}</Typography>
            )}
          </Stack>
          <Stack
            sx={[
              ...spreadSx(sx.contentContainer),
              {overflowY: disabledScroll ? 'hidden' : 'auto'},
            ]}
          >
            <AccessesCardsBlock
              showExpired={showExpired}
              accesses={visibleAccesses}
              onOpenModal={setModalOpen}
              blockName={t('PERSONS')}
            />

            <AccessesCardsBlock
              showExpired={showExpired}
              accesses={accesses?.companyAccesses}
              onOpenModal={setModalOpen}
              blockName={t('ORGANIZATIONS')}
            />
          </Stack>
          <AccessesDeleteModal isOpen={isModalOpen} onClose={() => setModalOpen(!isModalOpen)} />
        </Stack>
      </Box>
    </>
  );
};
