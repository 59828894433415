import {MenuItem, MenuList, Stack, Typography} from '@mui/material';
import {useTranslate} from '@src/i18n/useTranslate';
import {ResultsHealthDetails} from '@src/pages/HealthCase/types';
import {ROUTERS_PATH} from '@src/routers';
import {ReactComponent as CloseIcon} from '@src/shared/assets/icons/close-icon.svg';
import {ReactComponent as MenuOptionIcon} from '@src/shared/assets/icons/vertical-dots.svg';
import {MobileDialog} from '@src/shared/ui/MobileDialog/MobileDialog';
import {DATE_FORMAT, dateToFormat} from '@src/shared/utils';
import {getName} from '@src/shared/utils/getName';
import {useAppDispatch} from '@src/store';
import {healthCasesActions} from '@src/store/healthCases/slice';
import {MouseEvent, useState} from 'react';
import {generatePath, useNavigate} from 'react-router-dom';
import {IconButtonNew} from 'ui-kit';

import {sx} from './styles';

export const CardCaseMobile = (result: ResultsHealthDetails) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {t} = useTranslate('healthCases');
  const firstName = result?.firstName;
  const lastName = result?.lastName;
  const middleName = result?.middleName;
  const tests = result?.tests ? result?.tests?.map((item) => item.name).join(', ') : '';
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const handleViewPage = () => {
    const resultDetailPath = generatePath(ROUTERS_PATH.RESULT_DETAILS, {
      resultId: result.id,
    });
    const query = new URLSearchParams();
    query.set('type', result.type);
    navigate(`${resultDetailPath}?${query.toString()}`);
  };

  const handleRemove = (e: MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();
    dispatch(healthCasesActions.removeResults(result.id));
    setIsOpenMenu(false);
  };

  const handleToggleMenu = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsOpenMenu(true);
  };

  const handleClose = () => {
    setIsOpenMenu(false);
  };

  return (
    <>
      <Stack
        key={result.id}
        sx={sx.caseWrapper}
        onClick={handleViewPage}
      >
        <Stack gap={12}>
          <Stack flexDirection={'row'} justifyContent={'space-between'}>
            <Typography color={'grey.400'} variant="14_18_500">{t('COLLECTED_ON', {date: dateToFormat(DATE_FORMAT, result?.collected || '')})}</Typography>
          </Stack>
          <Typography variant="14_18_500" sx={sx.description}>
            {tests}
          </Typography>
          <Typography variant="14_18_600">{getName({firstName, lastName, middleName})}</Typography>
        </Stack>
        <Stack>
          <IconButtonNew
            color={'tertiary'}
            size={'xs'}
            onClick={handleToggleMenu}>
            <MenuOptionIcon />
          </IconButtonNew>
        </Stack>
      </Stack>
      <MobileDialog
        isOpen={isOpenMenu}
        onClose={handleClose}
        aria-haspopup="true"
      >
        <Typography
          variant={'16_20_700'}
          p={'18px 12px'}
          color={'grey.800'}>
          {t('RESULT_DATE', {date: dateToFormat(DATE_FORMAT, result?.collected || '')})}
        </Typography>
        <MenuList sx={sx.menuList}>
          <MenuItem
            onClick={handleRemove}
            sx={sx.menuItem}
          >
            <CloseIcon />
            <Typography variant="14_18_500">{t('REMOVE_FROM_HEALTH_CASE')}</Typography>
          </MenuItem>
        </MenuList>
      </MobileDialog>
    </>
  );
};
