/* eslint-disable no-comments/disallowComments */
/** @jsxImportSource @emotion/react */
import {css, css as _, styled, useTheme} from '@mui/material';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {NotificationText} from 'components/Notification';
import {useTranslate} from 'i18n/useTranslate';
import {PropsWithChildren} from 'react';
import {Button} from 'ui-kit';

import {Dialog, DialogActions, DialogContent, DialogTitle} from './Dialog';

export interface DialogAlertProps {
  open: boolean
  title: string
  type?: 'warning' | 'success' | 'info' | 'error'
  onClose: () => void
  className?: string
}

const AlertDialogActions = styled(DialogActions)(() => css`
  margin: 0 !important;
  padding: 24px;
  justify-content: end !important;
`);
export const DialogAlert = ({open, title, children, type = 'warning', onClose, className}: PropsWithChildren<DialogAlertProps>) => {
  const {t} = useTranslate('common');
  const {mobile} = useMQuery();
  const theme = useTheme();
  let color: string;
  switch (type) {
    case 'success':
      color = theme.colors.all.success[200];
      break;
    case 'error':
      color = theme.colors.all.error[200];
      break;
    case 'warning':
      color = theme.colors.all.warning[200];
      break;
    default:
      color = '';
  }
  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={className}
      keepMounted={false}
    >
      <DialogTitle onClose={onClose} css={_`border: 1px solid ${color}`}>
        <NotificationText type={type} sx={{fontWeight: 700, fontSize: 18, gap: 12, alignItems: 'center'}}>{title}</NotificationText>
      </DialogTitle>
      <DialogContent sx={{fontSize: 16, border: `1px solid ${color}`, borderTop: 'none'}}>
        {children}
      </DialogContent>
      <AlertDialogActions sx={{backgroundColor: color, paddingTop: 16, paddingBottom: 16}}>
        <Button
          variant={'contained'}
          color='inherit'
          onClick={onClose}
          sx={(t) => ({width: mobile ? '100%' : 'initial', backgroundColor: t.palette.common.white})}>{t('OK')}</Button>
      </AlertDialogActions>
    </Dialog>
  );
};
