import {EditFileDialogForm} from './types';

export const INITIAL_BIOMARKER = {value: '', biomarkerId: '', biomarkerName: '', hasUnits: false};

export const DEFAULT_DOCUMENT_FORM_VALUES: EditFileDialogForm = {
  name: '',
  category: '',
  date: '',
  description: '',
  recognize: false,
  createBiomarkers: [INITIAL_BIOMARKER],
  healthCaseIds: [],
};

export const FILES_ACCEPT = '.pdf, .jpeg, .jpg, .png';
export const MAX_FILE_SIZE_BYTES = 1024 * 1024 * 5;
export const MAX_DESCRIPTION_LENGTH = 1000;
