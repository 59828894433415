/* eslint-disable no-comments/disallowComments */
import {Box, Stack, Typography} from '@mui/material';
import {
  Panel,
  Product,
  storageApi,
  StorageFileUpdateBody,
  Test,
  TestStatus,
} from '@src/api';
import {ResultType} from '@src/api/__generated__/webApi';
import {
  Breadcrumbs,
  EmptySearch,
  ITableProps,
  Link,
  PageHeader,
  RowInformation,
  Table,
  Text,
} from '@src/components';
import {EditResultDialog} from '@src/components/EditResultDialog';
import Grid from '@src/components/Grid';
import {PulseLoader} from '@src/components/PulseLoader';
import {SendResult} from '@src/components/SendResult';
import {HealthCaseSelector} from '@src/features/HealthCaseSelector';
import {useTranslate} from '@src/i18n/useTranslate';
import {TableManualRow} from '@src/pages/Details/TableManualRow';
import {DeleteManualResultButton} from '@src/pages/Details/components/DeleteManualResultButton';
import {ROUTERS_PATH} from '@src/routers';
import {ReactComponent as DownloadIcon} from '@src/shared/assets/icons/download.svg';
import {ReactComponent as AddIcon} from '@src/shared/assets/icons/plus_circle.svg';
import {envs} from '@src/shared/constants/envs';
import {useColumnsNumber, useSidebarClassObserver} from '@src/shared/hooks';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {useAppDispatch, useAppSelector} from '@src/store';
import {useAccount} from '@src/store/account/hooks';
import {healthCasesActions} from '@src/store/healthCases/slice';
import {useEmailForm, useResultDetail} from '@src/store/results/hooks';
import {resultsActions} from '@src/store/results/slice';
import {deleteStorageFile, updateStorageFile} from '@src/store/storage/slice';
import {Steps} from '@src/types';
import {BoxBody, BoxHeader, BoxPaddings, WhiteBox} from 'components/WhiteBox/WhiteBox';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {Button} from 'ui-kit';
import {EditIcon} from 'ui-kit/lib/icons/24';

import {ExpandableTableRowContainer} from './ExpandableTableRowContainer/ExpandableTableRowContainer';
import {UpdateCode} from './ExpandableTableRowContainer/types';
import {
  customColumns,
  defaultColumns,
  laboratoryDesktopGridColumns,
  laboratoryDesktopLgGridColumns,
  laboratoryDesktopLgOpenSidebarGridColumns,
  laboratoryMobileGridColumns,
  laboratoryTabletGridColumns,
  manualDesktopGridColumns,
  manualDesktopLgGridColumns,
  manualDesktopLgOpenSidebarGridColumns,
  manualMobileGridColumns,
  manualTabletGridColumns,
} from './constants';
import {filterValidTestsForPanels, filterValidTestsForProducts, getBreakpoints, getLaboratoryData, getManualData} from './helpers';
import {sx} from './styles';
import {useHeader} from './utils/useHeader';

export const Details: React.FC = () => {
  const {t} = useTranslate('details');
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [testCodes, setTestCodes] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const {mobile, tablet, tabletPortrait, desktop: isDesktop, lg: isDesktopLg} = useMQuery();
  const [allCodeSelect, setAllCodeSelect] = useState<string[]>([]);
  const [selectHeaderCheckbox, setSelectHeaderCheckbox] = useState(false);
  const {isSidebarOpen} = useSidebarClassObserver();
  const containerHealcthCases = useRef<HTMLDivElement | null>(null);
  const isSaving = useAppSelector((state) => state.healthCases.isSaving);
  const {resultId} = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const {submit} = useEmailForm();
  const {account} = useAccount();

  const dispatch = useAppDispatch();

  const {step} = useAppSelector((state) => state.results);

  const breakpoints = getBreakpoints();

  const {colsNumberLg, colsNumberMd, containerRef} = useColumnsNumber({
    breakpoints,
    defaultColumns,
    customColumns,
  });

  const closeSendResultModal = () => {
    void dispatch(resultsActions.setStep({step: Steps.INIT}));
  };

  const handleToggleEditDialogOpen = () => setIsEditDialogOpen((prev) => !prev);
  const {
    resultLaboratoryDetails,
    resultManualDetails,
    isFetching: resultLoading,
    isLoading: loading,
    isNotFound,
    isForbidden,
    refetch,
  } = useResultDetail({resultId, type: searchParams.get('type') || ''});

  const isManual = !!resultManualDetails;

  useEffect(() => {
    const allTestCodesInPanels = resultLaboratoryDetails?.panels?.map((panel) =>
      panel.products?.flatMap((product) => product.tests.flatMap((test) => test.loinc)),
    );
    const allTestCodesProducts = resultLaboratoryDetails?.products?.flatMap((product) =>
      product.tests
        .filter((test) => test.status === TestStatus.COMPLETED && test.value)
        .map((test) => test.loinc),
    );

    const allManualTestsCodes = resultManualDetails?.storageBiomarkers?.map(
      (biomarker) => biomarker.biomarkerObj?.loinc || '',
    );

    const allTestCodes = [
      ...(allTestCodesInPanels ?? []),
      ...(allTestCodesProducts ?? []),
      ...(allManualTestsCodes ?? []),
    ].flat() as string[];

    if (allTestCodes) {
      setAllCodeSelect(allTestCodes);
    }
  }, [resultLaboratoryDetails, resultManualDetails]);

  useEffect(() => {
    if (isSaving && resultId) {
      refetch();
    }
  }, [isSaving, resultId, refetch]);

  const filteredData = useMemo(() => {
    const filteredProducts = filterValidTestsForProducts(resultLaboratoryDetails?.products || []);
    const filteredPanels = filterValidTestsForPanels(resultLaboratoryDetails?.panels || []);

    const tests = [...filteredProducts, ...filteredPanels];
    if (tests.length) {
      return tests;
    }
    return null;
  }, [resultLaboratoryDetails]);

  useEffect(() => {
    if (allCodeSelect.length > 0 && testCodes.length === allCodeSelect.length) {
      setSelectHeaderCheckbox(true);
      setSelectAll(true);
    } else {
      setSelectHeaderCheckbox(false);
      setSelectAll(false);
    }
  }, [testCodes, allCodeSelect]);

  useEffect(() => {
    if (!resultId) return;
    dispatch(healthCasesActions.toggleResultsSelection(resultId));
    dispatch(healthCasesActions.toggleDocumentsSelection(resultId));

    return () => {
      dispatch(healthCasesActions.clearSelectedResults());
      dispatch(healthCasesActions.clearSelectedDocuments());
    };
  }, [resultId]);

  const goToDynamics = () => {
    const params = encodeURI(JSON.stringify([...new Set(testCodes)]));
    const query = new URLSearchParams();
    query.set('type', isManual ? ResultType.MANUAL : ResultType.LABORATORY);

    navigate(`dynamics/${params}?${query.toString()}`);
  };

  const changeCodesForDynamics: UpdateCode = (item, checked, flag) => {
    if (typeof item === 'string') {
      if (checked) {
        setTestCodes((prev) => [...prev, item]);
      } else {
        const findCodeIndex = testCodes.indexOf(item);
        setTestCodes((prev) => {
          prev.splice(findCodeIndex, 1);
          return [...prev];
        });
      }
    } else {
      const tests: string[] = flag ? item.flat().map((t) => t.loinc) : item.map((t) => t.loinc);

      if (checked) {
        setTestCodes((prev) => [...new Set([...prev, ...tests])]);
      } else {
        setTestCodes((prev) => {
          return prev.filter((t) => {
            const findIndex = tests.indexOf(t);
            if (findIndex >= 0) {
              tests.splice(findIndex, 1);
            }
            return findIndex < 0;
          });
        });
      }
    }
  };

  const handleSendResult = (email: string) => {
    submit({resultId, email, type: isManual ? ResultType.MANUAL : ResultType.LABORATORY});
  };

  const handleDeleteManualResult = () => {
    if (!resultManualDetails?.id) {
      return;
    }
    void dispatch(
      deleteStorageFile({id: resultManualDetails.id, successText: t('RESULT_DELETE_SUCCESS')}),
    )
      .then(() => {
        navigate(ROUTERS_PATH.RESULTS);
        dispatch(resultsActions.removeResult(resultManualDetails.id));
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const handleUpdateManualResult = async (updateBody: StorageFileUpdateBody) => {
    if (!resultManualDetails) {
      return;
    }

    if (!resultManualDetails.recognition) {
      try {
        const deletedBiomarkers = resultManualDetails.storageBiomarkers?.filter(
          (biomarker) =>
            !updateBody.editBiomarkers?.find((editBiomarker) => biomarker.id === editBiomarker.id),
        );
        const deleteBiomarkerPromises = deletedBiomarkers?.map((biomarker) =>
          storageApi.deleteStorageBiomarker(biomarker.id),
        );
        if (deleteBiomarkerPromises) {
          await Promise.all(deleteBiomarkerPromises);
        }
      } catch (e) {
        console.error(e);
      }
    }

    try {
      await dispatch(
        updateStorageFile({...updateBody, favorite: false, id: resultManualDetails.id, showSuccessToast: true}),
      ).unwrap();
    } catch (e) {
      console.error(e);
    }

    refetch();
    handleToggleEditDialogOpen();
  };

  const changeAllTests = (_: any, checked: boolean) => {
    if (checked) {
      if (isManual) {
        const biomarkerCodes =
          resultManualDetails.storageBiomarkers?.reduce<string[]>((acc, biomarker) => {
            const loincCode = biomarker.biomarkerObj?.loinc;
            if (loincCode) {
              acc.push(loincCode);
            }
            return acc;
          }, []) || [];

        setSelectAll(true);
        setTestCodes(biomarkerCodes);
      } else {
        const completedCodes = allCodeSelect.filter((code) => {
          const test = resultLaboratoryDetails?.panels
            ?.flatMap((panel) => panel?.products?.flatMap((product) => product.tests))
            ?.concat(resultLaboratoryDetails?.products?.flatMap((product) => product.tests) || [])
            ?.find((test) => test?.loinc === code);

          return test?.status === TestStatus.COMPLETED;
        });

        setSelectAll(true);
        setTestCodes(completedCodes);
      }
    } else {
      setSelectAll(false);
      setTestCodes([]);
    }
  };

  const {HEADER_LIST} = useHeader(changeAllTests, selectHeaderCheckbox);

  const gridTemplateColumns = useMemo(() => {
    if (isManual) {
      if (tablet) {
        return manualTabletGridColumns;
      }

      if (mobile) {
        return manualMobileGridColumns;
      }

      if (isDesktopLg) {
        return isSidebarOpen ? manualDesktopLgOpenSidebarGridColumns : manualDesktopLgGridColumns;
      }
      return manualDesktopGridColumns;
    }

    if (tablet) {
      return laboratoryTabletGridColumns;
    }

    if (mobile) {
      return laboratoryMobileGridColumns;
    }

    if (isDesktopLg) {
      return isSidebarOpen
        ? laboratoryDesktopLgOpenSidebarGridColumns
        : laboratoryDesktopLgGridColumns;
    }
    return laboratoryDesktopGridColumns;
  }, [tablet, mobile, isDesktopLg, isSidebarOpen, isManual]);

  if (!loading && (isNotFound || isForbidden)) {
    const route = isNotFound ? ROUTERS_PATH.NOT_FOUND : ROUTERS_PATH.FORBIDDEN;
    navigate(route);
    return null;
  }

  if (loading) {
    return (
      <Stack sx={{flexGrow: 1, justifyContent: 'center', alignItems: 'center'}}>
        <PulseLoader loading={loading} />
      </Stack>
    );
  }

  const downloadResulstLink = `${envs.BASE_API}/api/results/${resultId ?? ''}/fn/download?type=${
    isManual ? ResultType.MANUAL : ResultType.LABORATORY
  }`;
  const nameTypography = isDesktop ? '16_24_700' : '14_18_700';
  const valueTypography = isDesktop ? '16_24_500' : '14_18_500';
  const columnSpacing = isDesktop ? 18 : 0;

  const caclWBoxCollapsible = () => {
    if (tablet) {
      return containerRef.current && containerRef.current?.offsetWidth < breakpoints.md;
    }
    if (isDesktop) {
      return containerRef.current && containerRef.current?.offsetWidth < breakpoints.lg;
    }
    if (mobile) {
      return true;
    }
  };

  const isCollapsible = caclWBoxCollapsible() ?? false;

  const laboratoryData = getLaboratoryData(resultLaboratoryDetails, t);
  const manualData = getManualData(resultManualDetails, t, account);

  const healthCases = laboratoryData?.healthCases || manualData?.healthCases;

  return (
    <Box sx={sx.mainContainer}>
      <Stack gap={24}>
        <Breadcrumbs>
          <Link to={ROUTERS_PATH.RESULTS}>{t('RESULTS')}</Link>
          <Text sx={sx.breadcrumbsText}>
            {laboratoryData?.resultTitle || manualData?.resultTile || ''}
          </Text>
        </Breadcrumbs>
        <PageHeader
          itemAction={
            <Stack flexDirection={'row'} gap={12}>
              {(!!laboratoryData || manualData?.hasFile) && <SendResult onSubmit={handleSendResult} step={step} close={closeSendResultModal} />}
              {isManual && !mobile && (
                <>
                  <Button
                    startIcon={<EditIcon />}
                    variant={'outlined'}
                    color={'secondary'}
                    onClick={handleToggleEditDialogOpen}
                  >
                    {t('EDIT')}
                  </Button>
                  <DeleteManualResultButton onDelete={handleDeleteManualResult} />
                </>
              )}
            </Stack>
          }
        >
          {laboratoryData?.resultTitle || manualData?.resultTile || ''}
        </PageHeader>
        {isManual && mobile && (
          <Stack flexDirection={'row'} gap={12}>
            <Button
              startIcon={<EditIcon />}
              variant={'outlined'}
              color={'secondary'}
              fullWidth
              onClick={handleToggleEditDialogOpen}
            >
              {t('EDIT')}
            </Button>
            <DeleteManualResultButton onDelete={handleDeleteManualResult} />
          </Stack>
        )}
      </Stack>
      <Grid ref={containerRef} container spacing={{xs: 18, sm: 24, lg: 36}}>
        <Grid
          mt={mobile ? 6 : 0}
          xs={12}
          md={colsNumberMd}
          lg={colsNumberLg}
          display={'flex'}>
          <WhiteBox
            notApplySx
            collapsible={isCollapsible}
            sx={sx.wBoxContent}
            id={'boxOne'}
            headerSx={sx.headerSx}
            hasIconBox
            header={isCollapsible ? t('PATIENT_DETAILS') : ''}
          >
            {!isCollapsible && <BoxHeader sx={sx.boxHeader}>{t('PATIENT_DETAILS')}</BoxHeader>}
            <BoxBody>
              <Grid container rowSpacing={0} columnSpacing={columnSpacing}>
                <Grid xs={12}>
                  <Grid>
                    <RowInformation
                      variant={nameTypography}
                      valueTypographyVariant={valueTypography}
                      sx={sx.rowInformation}
                      noMargin={isDesktop}
                      tooltip={isDesktop}
                      value={laboratoryData?.firstName || manualData?.firstName}
                      noWrap={!isDesktop}
                      name={t('FIRST_NAME')}
                    />
                    <RowInformation
                      variant={nameTypography}
                      valueTypographyVariant={valueTypography}
                      sx={sx.rowInformation}
                      noMargin={isDesktop}
                      tooltip={isDesktop}
                      value={laboratoryData?.lastName || manualData?.lastName}
                      noWrap={!isDesktop}
                      name={t('LAST_NAME')}
                    />
                  </Grid>
                </Grid>
                <Grid xs={12}>
                  <Grid>
                    <RowInformation
                      variant={nameTypography}
                      valueTypographyVariant={valueTypography}
                      sx={sx.rowInformation}
                      noMargin={isDesktop}
                      tooltip={isDesktop}
                      noWrap={!isDesktop}
                      value={laboratoryData?.birthDateValue || manualData?.birthDateValue}
                      name={t('DATE_OF_BIRTH')}
                    />
                    <RowInformation
                      variant={nameTypography}
                      valueTypographyVariant={valueTypography}
                      noMargin
                      noWrap={!isDesktop}
                      tooltip={isDesktop}
                      value={laboratoryData?.sex || manualData?.sex}
                      name={t('BIRTH_SEX')}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </BoxBody>
          </WhiteBox>
        </Grid>
        <Grid
          xs={12}
          md={colsNumberMd}
          lg={colsNumberLg}
          display={'flex'}>
          <WhiteBox
            notApplySx
            sx={sx.wBoxContent}
            collapsible={isCollapsible}
            id={'boxTwo'}
            headerSx={sx.headerSx}
            hasIconBox
            header={isCollapsible ? t(isManual ? 'MANUALLY_ADDED_VALUE' : 'ORDER_DETAILS') : ''}
          >
            {!isCollapsible && (
              <BoxHeader sx={sx.boxHeader}>
                {isManual ? t('MANUALLY_ADDED_VALUE') : t('ORDER_DETAILS')}
              </BoxHeader>
            )}
            <BoxBody>
              <Grid container rowSpacing={0} columnSpacing={columnSpacing}>
                <Grid xs={12}>
                  {!isManual && (
                    <Grid>
                      <RowInformation
                        variant={nameTypography}
                        valueTypographyVariant={valueTypography}
                        sx={sx.rowInformation}
                        noMargin={isDesktop}
                        tooltip={isDesktop}
                        noWrap={!isDesktop}
                        value={laboratoryData?.number}
                        name={t('ORDER_ID')}
                      />
                      <RowInformation
                        variant={nameTypography}
                        valueTypographyVariant={valueTypography}
                        sx={sx.rowInformation}
                        noMargin={isDesktop}
                        tooltip={isDesktop}
                        noWrap={!isDesktop}
                        value={laboratoryData?.collected}
                        name={t('COLLECTION_DATE')}
                      />
                    </Grid>
                  )}
                  {isManual && (
                    <Grid>
                      <RowInformation
                        variant={nameTypography}
                        valueTypographyVariant={valueTypography}
                        sx={sx.rowInformation}
                        noMargin={isDesktop}
                        tooltip={isDesktop}
                        noWrap={!isDesktop}
                        value={manualData?.resultTile}
                        name={t('COLLECTION_DATE')}
                      />
                      <RowInformation
                        variant={nameTypography}
                        valueTypographyVariant={valueTypography}
                        sx={sx.rowInformation}
                        noMargin={isDesktop}
                        tooltip={isDesktop}
                        noWrap={!isDesktop}
                        value={manualData?.description}
                        name={t('DESCRIPTION')}
                        containerSx={{wordBreak: 'break-all'}}
                      />
                    </Grid>
                  )}
                </Grid>
                {!isManual && (
                  <Grid xs={12}>
                    <Grid>
                      <RowInformation
                        variant={nameTypography}
                        valueTypographyVariant={valueTypography}
                        sx={sx.rowInformation}
                        noMargin={isDesktop}
                        tooltip={isDesktop}
                        noWrap={!isDesktop}
                        value={laboratoryData?.reported}
                        name={t('REPORTED_DATE')}
                      />
                      <RowInformation
                        variant={nameTypography}
                        valueTypographyVariant={valueTypography}
                        noMargin
                        noWrap={!isDesktop}
                        tooltip={isDesktop}
                        value={laboratoryData?.provider}
                        name={t('ORDERING_PROVIDER')}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </BoxBody>
          </WhiteBox>
        </Grid>
        <Grid
          xs={12}
          md={colsNumberMd}
          lg={colsNumberLg}
          display={'flex'}>
          <WhiteBox
            notApplySx
            sx={sx.wBoxContent}
            collapsible={isCollapsible}
            id={'boxThree'}
            headerSx={sx.headerSx}
            hasIconBox
            header={isCollapsible ? t('HEALTH_CASES') : ''}
          >
            {!isCollapsible && <BoxHeader sx={sx.boxHeader}>{t('HEALTH_CASES')}</BoxHeader>}
            <Box ref={containerHealcthCases} sx={sx.healthCasesContainer}>
              {healthCases?.map((healthCase, key) => (
                <Link sx={sx.healthCase} to={`/health-cases/${healthCase.id || ''}`} key={key}>
                  {healthCase.name}
                </Link>
              ))}
              <HealthCaseSelector
                isText
                containerRef={containerHealcthCases}
                healthCases={healthCases}
                isDetails={!isManual}
              >
                <Stack gap={4} flexDirection={'row'}>
                  <AddIcon />
                  <Typography variant="14_18_500">{t('ADD')}</Typography>
                </Stack>
              </HealthCaseSelector>
            </Box>
          </WhiteBox>
        </Grid>
      </Grid>
      <WhiteBox noPaddings sx={sx.wBoxContainer}>
        <BoxBody>
          <BoxPaddings sx={sx.boxPaddings}>
            <Box sx={sx.boxContainer}>
              <BoxHeader>{t('RESULTS')}</BoxHeader>
              {!mobile && (
                <Box sx={sx.bthContainer}>
                  {(!isManual || manualData?.hasFile) && (
                    <Button
                      variant="text"
                      startIcon={<DownloadIcon />}
                      href={downloadResulstLink}
                      target="_blank"
                    >
                      {t('DOWNLOAD_RESULTS')}
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    onClick={goToDynamics}
                    disabled={testCodes.length === 0}
                  >
                    {t('DYNAMICS_OF_RESULTS')}
                  </Button>
                </Box>
              )}
            </Box>
          </BoxPaddings>
          <Table
            paddingLeft={isDesktop ? '28px' : '18px'}
            header={!mobile && !tabletPortrait}
            keyIndicator="id"
            data={
              (filteredData as ITableProps<Product & Panel>['data']) ||
              (manualData?.tableData as ITableProps<Test>['data']) ||
              []
            }
            gridTemplateColumns={gridTemplateColumns}
            headerList={HEADER_LIST}
            rowComponent={{
              // @ts-expect-error
              component: isManual ? TableManualRow : ExpandableTableRowContainer,
              props: {
                changeCodesForDynamics,
                testCodes,
                selectAll,
                selectRest: selectHeaderCheckbox,
                gridTemplateColumns,
              },
            }}
            loading={resultLoading}
            emptyData={<EmptySearch isLoading={loading} isNew isDetails />}
            minHeight={1565}
            isModifiedScroll
          />
        </BoxBody>
        {mobile && (
          <Box sx={sx.buttonsContainer}>
            <Button
              fullWidth
              variant={'contained'}
              onClick={goToDynamics}
              disabled={testCodes.length === 0}
            >
              {t('DYNAMICS_OF_RESULTS')}
            </Button>
            {(!isManual || manualData?.hasFile) && (
              <Button
                fullWidth
                variant="outlined"
                href={downloadResulstLink}
                target="_blank">
                {t('DOWNLOAD_RESULTS')}
              </Button>
            )}
          </Box>
        )}
      </WhiteBox>
      {resultManualDetails && isEditDialogOpen && (
        <EditResultDialog
          isOpen
          recognize={resultManualDetails.recognition}
          onClose={handleToggleEditDialogOpen}
          onSave={handleUpdateManualResult}
          manualResult={resultManualDetails}
        />
      )}
    </Box>
  );
};
