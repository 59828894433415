import {ManualResultDetail} from '@api';
import {StorageBiomarker as ResultStorageBiomarker} from '@src/api/__generated__/webApi';
import {DialogBiomarker} from '@src/components/EditDocumentDialog/types';

import {DEFAULT_RESULT_FORM_VALUES} from './constants';
import {EditResultDialogForm} from './types';

export const getBiomarkersFromManualResult = (
  storageBiomarkers?: ResultStorageBiomarker[],
): DialogBiomarker[] => {
  if (!storageBiomarkers) {
    return [];
  }
  return storageBiomarkers.map(({value, biomarkerObj, biomarkerUnit, id}) => ({
    value: value || '',
    biomarkerId: biomarkerObj?.id || '',
    biomarkerName: biomarkerObj?.name || '',
    biomarkerUnitId: biomarkerUnit?.id || '',
    hasUnits: !!biomarkerUnit?.id,
    initialId: id,
  }));
};

export const getDefaultValues = (
  recognize: boolean,
  manualResult: ManualResultDetail | undefined | null,
): EditResultDialogForm => {
  if (manualResult) {
    const {name, date, description, storageBiomarkers, healthCases, recognition} = manualResult;
    return {
      name,
      date: date || '',
      description: description || '',
      editBiomarkers: getBiomarkersFromManualResult(storageBiomarkers),
      recognize: recognition,
      healthCaseIds: healthCases?.map(({id}) => id),
    };
  }

  return {...DEFAULT_RESULT_FORM_VALUES, recognize};
};
