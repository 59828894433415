import {InfiniteScrollContainer} from '@components';
import {styled, SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  iconButton: {
    svg: {
      width: 24,
      height: 24,
    },
  },
  generalContainer: (t) => ({
    flex: 1,
    border: '1px solid',
    borderTop: 'unset',
    backgroundColor: t.colors.background,
    borderColor: t.colors.borderBlueLight,
    [t.breakpoints.down('md')]: {
      backgroundColor: 'transparent',
      gap: 12,
      border: '0px solid',
    },
  }),
  emptyWrapper: (t) => ({
    background: t.colors.background,
    flexGrow: 1,
    padding: '24px',
    [t.breakpoints.down('sm')]: {
      padding: '14px',
    },
  }),
  contentWrapper: (t) => ({
    height: 'calc(100vh - 323px)',
    overflow: 'auto',
    [t.breakpoints.down('sm')]: {
      height: 'calc(100vh - 290px)',
    },
  }),
};

export const MobileWrapper = styled(InfiniteScrollContainer)(({theme}) => ({
  [theme.breakpoints.up('xs')]: {
    paddingTop: 18,
    paddingBottom: 18,
    height: `calc(100vh - 200px - ${theme.header.height.sm}px)`,
  },
}));
