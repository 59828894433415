// eslint-disable-next-line no-comments/disallowComments
/** @jsxImportSource @emotion/react */

import {
  DatePickerRange,
} from '@components';
import {css as _, SelectChangeEvent, Stack} from '@mui/material';
import {FormSelectControl} from '@src/components/FormSelectControl';
import {MultiSelect} from '@src/components/MultiSelect';
import {FiltersDialog} from '@src/components/fragments/FiltersDialog';
import {useTranslate} from '@src/i18n/useTranslate';
import {useMQuery} from '@src/shared/hooks';
import {ChangeEvent, FC, useEffect, useState} from 'react';
import {MenuItem} from 'ui-kit';

import {initialFilter} from '../../Results.constant';

import {IFilterDialogProps} from './types';

export const FilterDialog: FC<IFilterDialogProps> = ({
  filter,
  handleClose,
  isFilterOpen,
  physicianOptions,
  healthCasesOptions,
  setNewFilters,
}) => {
  const {t} = useTranslate('results');
  const {mobile, tabletPortrait} = useMQuery();
  const onPortrait = (mobile || tabletPortrait);
  const [dialogFilters, setDialogFilters] = useState(filter);
  const [searchValue, setSearchValue] = useState('');
  const [selectedPhysician, setSelectedPhysician] = useState('');

  const handleChangeSearchValue = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchValue(value);
  };

  const handleClearSearchValue = () => {
    setSearchValue('');
  };

  useEffect(() => {
    if (isFilterOpen) {
      setDialogFilters(filter);
    }
  }, [isFilterOpen]);

  const handlePhysicianChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setSelectedPhysician(value);

    const data = physicianOptions.find((item) => item.name === value);
    if (data && event) {
      setDialogFilters((prev) => ({
        ...prev,
        physician: data ? {value: data.value, name: data.name} : null,
      }));
    }
  };

  const handleHealthCaseChange = (value: string[]) => {
    const selectedHealthCases = value.map((id) => {
      const healthCase = healthCasesOptions.find(hc => hc.value === id);
      return healthCase ? {value: healthCase.value, name: healthCase.name} : null;
    }).filter(Boolean) as Array<{ value: string, name: string }>;

    setDialogFilters((prevFilters) => ({
      ...prevFilters,
      healthCase: selectedHealthCases,
    }));
  };

  const handleChangeDate = (name: string) => (date: Date | null) => {
    setDialogFilters((prev) => ({...prev, [name]: date}));
  };

  const handleClearCollectionDate = () => {
    setDialogFilters((prev) => {
      return {
        ...prev,
        collectionDateFrom: null,
        collectionDateTo: null,
      };
    });
  };

  const handleClear = () => {
    setDialogFilters(initialFilter);
  };

  const handleApply = () => {
    handleClose();
    setNewFilters(dialogFilters);
  };

  return (
    <FiltersDialog
      isOpen={isFilterOpen}
      title={t('FILTERS')}
      onClose={handleClose}
      onApply={handleApply}
      onClear={handleClear}
    >
      <Stack gap={24}>
        {onPortrait && (
          <>
            <FormSelectControl
              name={'physician'}
              value={selectedPhysician}
              onChange={handlePhysicianChange}
              label={t('PHYSICIAN')}
              placeholder={t('SELECT')}
            >
              {physicianOptions.map((option) => (
                <MenuItem key={option.name} value={option.name}>
                  {option.name}
                </MenuItem>
              ))}
            </FormSelectControl>
            <MultiSelect
              options={healthCasesOptions}
              value={dialogFilters?.healthCase?.map(hc => hc.value) || []}
              onChange={handleHealthCaseChange}
              label={t('HEALTH_CASES')}
              placeholder={t('SELECT')}
              onChangeSearchValue={handleChangeSearchValue}
              searchValue={searchValue}
              onClear={handleClearSearchValue}
              renderValue={(val) => (val.length > 1 ? val[0] + ` +${val.length - 1}` : val[0] || '')}
            />
          </>
        )}
        <DatePickerRange
          label="Collection Date"
          variant="outlined"
          startDate={dialogFilters.collectionDateFrom}
          setStartDate={handleChangeDate('collectionDateFrom')}
          setEndDate={handleChangeDate('collectionDateTo')}
          endDate={dialogFilters.collectionDateTo}
          css={_`width: 100%; margin-bottom: 0 !important`}
          clearDate={handleClearCollectionDate}
          labelTop
          isIconVisible={false}
        />
      </Stack>
    </FiltersDialog>
  );
};
