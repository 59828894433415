import {Grid, SelectChangeEvent, Stack, Typography} from '@mui/material';
import {Result} from '@src/api';
import {DatePickerRange} from '@src/components';
import {FormSelectControl} from '@src/components/FormSelectControl';
import {MultiSelect} from '@src/components/MultiSelect';
import {BoxPaddings} from '@src/components/WhiteBox';
import {useTranslate} from '@src/i18n/useTranslate';
import {ReactComponent as RefreshIcon} from '@src/shared/assets/icons/refresh.svg';
import {ReactComponent as SettingsIcon} from '@src/shared/assets/images/icons/icon_settings.svg';
import {useMQuery} from '@src/shared/hooks';
import {getFullName} from '@src/shared/utils';
import {useAppDispatch} from '@src/store';
import {resultsActions} from '@src/store/results/slice';
import {FC, useCallback, useEffect, useMemo, useState, ChangeEvent} from 'react';
import {IconButton, MenuItem} from 'ui-kit';

import {initialFilter} from '../../Results.constant';
import {dateInInterval} from '../../Results.helpers';
import {FilterDialog} from '../FiltersDialog';

import {sx} from './styles';
import {ResultsFilterTableProps} from './types';

export const ResultsFilters: FC<ResultsFilterTableProps> = ({
  isOpen,
  handleCloseFilter,
  handleOpenFilter,
  handleSetFilter,
  filter,
  data,
}) => {
  const {t, ready} = useTranslate('results');
  const {mobile, laptop, tabletPortrait} = useMQuery();
  const dispatch = useAppDispatch();
  const [tempFilter, setTempFilter] = useState(filter);

  const [selectedPhysician, setSelectedPhysician] = useState('');
  const [tempSelectedPhysician, setTempSelectedPhysician] = useState(selectedPhysician);
  const [selectedHealthCase, setSelectedHealthCase] = useState<string[]>([]);
  const [tempSelectedHealthCase, setTempSelectedHealthCase] = useState<string[]>(selectedHealthCase);
  const onPortrait = (mobile || tabletPortrait);

  const [searchValueHealthCase, setSearchValueHealthCase] = useState('');

  const handleChangeSearchValue = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchValueHealthCase(value);
  };

  const handleClearSearchValue = () => {
    setSearchValueHealthCase('');
  };

  const getPhysicians = useMemo(() => {
    const uniquePhysiciansMap = new Map(
      data?.map((item) => [item?.physician?.id, item.physician]),
    );

    const uniquePhysicians = [...uniquePhysiciansMap.values()];

    const physiciansOptions = uniquePhysicians.map((physician) => ({
      value: physician?.id || '',
      name: getFullName(physician),
      key: physician?.id || '',
    }));

    return physiciansOptions.filter((item) => item.name && item.value);
  }, [data]);

  const healthCasesOptions = useMemo(() => {
    const allHealthCases = data?.flatMap((item) => item?.healthCases) || [];

    const uniqueHealthCasesMap = new Map(
      allHealthCases.map((healthCase) => [healthCase?.name, healthCase]),
    );

    const uniqueHealthCases = Array.from(uniqueHealthCasesMap.values());

    return uniqueHealthCases.map((healthCase) => ({
      value: healthCase?.id || '',
      name: healthCase?.name || '',
    }));
  }, [data]);

  const handleUpdateHealthCase = (newValue: string[]) => {
    setTempSelectedHealthCase(newValue);
  };

  const handlePhysicianSelect = (event: SelectChangeEvent<string>) => {
    const selectedValue = event.target.value;
    setTempSelectedPhysician(selectedValue);
    const selectedPhysician = getPhysicians.find((item) => item.name === event.target.value);
    if (selectedPhysician) {
      setTempFilter((prev) => ({
        ...prev,
        physician: {value: selectedPhysician.value, name: selectedPhysician.name},
      }));
    }
  };

  const handleHealthCaseSelect = (value: string[]) => {
    const selectedHealthCases = value.map((id) => {
      const healthCase = healthCasesOptions.find(hc => hc.value === id);
      return healthCase ? {value: healthCase.value, name: healthCase.name} : null;
    }).filter(Boolean) as Array<{ value: string, name: string }>;

    setTempSelectedHealthCase(value);
    setTempFilter((prevFilter) => ({
      ...prevFilter,
      healthCase: selectedHealthCases,
    }));
  };

  const filterByPhysician = (resultItem: Result, physician: { value: string, name: string } | null) => {
    if (!physician) return true;
    return resultItem.physician?.id === physician.value;
  };

  const filterByHealthCase = (resultItem: Result, selectedHealthCases: Array<{ value: string, name: string }> | null) => {
    if (!selectedHealthCases || selectedHealthCases.length === 0) return true;
    return resultItem.healthCases?.some((hc) =>
      selectedHealthCases.some(selectedCase => selectedCase.value === hc?.id),
    );
  };

  const filterByCollectionDate = (resultItem: Result, collectionDateFrom: Date | null, collectionDateTo: Date | null) => {
    if ((!collectionDateFrom && !collectionDateTo)) return true;
    return dateInInterval(collectionDateFrom, collectionDateTo, resultItem.collected || resultItem.date);
  };

  const handleChangeDate = (name: string) => (date: Date | null) => {
    setTempFilter((prev) => ({...prev, [name]: date}));
  };

  const handleClearCollectionDate = () => {
    setTempFilter((prev) => {
      return {
        ...prev,
        collectionDateFrom: null,
        collectionDateTo: null,
      };
    });
  };

  const handleApplyFilter = useCallback(() => {
    const {physician, healthCase, collectionDateFrom, collectionDateTo} = tempFilter;

    handleSetFilter(tempFilter);
    setSelectedPhysician(tempSelectedPhysician);
    setSelectedHealthCase(tempSelectedHealthCase);

    const doFiltration = (resultItem: Result) => {
      return (
        filterByPhysician(resultItem, physician) &&
        filterByHealthCase(resultItem, healthCase) &&
        filterByCollectionDate(resultItem, collectionDateFrom, collectionDateTo)
      );
    };

    if (data) dispatch(resultsActions.setFilterResults(data.filter(doFiltration)));
  }, [data, tempFilter, isOpen]);

  const handleClearFilter = () => {
    setSelectedPhysician('');
    setTempSelectedPhysician('');
    setSelectedHealthCase([]);
    setTempSelectedHealthCase([]);
    setTempFilter(initialFilter);
  };

  useEffect(() => {
    if (!isOpen) {
      handleApplyFilter();
    };
  }, [tempFilter]);

  if (!ready) return null;

  return (
    <>
      {!onPortrait && (
        <BoxPaddings
          noBottom
          noTop
          sx={sx.filterWrapper}>
          <Grid
            container
            flexGrow={1}
            gap={24}
          >
            <Grid xs={5} lg={5.5} xl={3.5}>
              <Stack justifyContent={'center'} pb={2}>
                <Typography variant='14_18_700' width={75}>{t('PHYSICIAN')}</Typography>
              </Stack>
              <FormSelectControl
                placeholder={t('SELECT')}
                name={'physician'}
                value={tempSelectedPhysician}
                onChange={handlePhysicianSelect}
              >
                {getPhysicians.map((option) => (
                  <MenuItem key={option.name} value={option.name}>
                    {option.name}
                  </MenuItem>
                ))}
              </FormSelectControl>
            </Grid>
            <Grid
              xs={5}
              lg={5.5}
              xl={3.5}
              sx={sx.inputWrapper}
            >
              <MultiSelect
                options={healthCasesOptions}
                value={selectedHealthCase}
                onChange={handleHealthCaseSelect}
                label={t('HEALTH_CASES')}
                placeholder={t('SELECT')}
                onChangeSearchValue={handleChangeSearchValue}
                searchValue={searchValueHealthCase}
                onClear={handleClearSearchValue}
                renderValue={(val) => (val.length > 1 ? val[0] + ` +${val.length - 1}` : val[0] || '')}
              />
            </Grid>
            {(!laptop) && (
              <Grid xs={2.5} xl={3.5}>
                <DatePickerRange
                  isIconVisible={false}
                  variant="outlined"
                  label={t('COLLECTED')}
                  startDate={filter.collectionDateFrom}
                  setStartDate={handleChangeDate('collectionDateFrom')}
                  setEndDate={handleChangeDate('collectionDateTo')}
                  endDate={filter.collectionDateTo}
                  clearDate={handleClearCollectionDate}
                  labelTop
                />
              </Grid>
            )}
          </Grid>
          <Stack flexDirection={'row'} gap={12} sx={{alignItems: 'flex-end'}}>
            {laptop && (
              <IconButton
                color={'secondary-light'}
                variant={'outlined'}
                onClick={handleOpenFilter}
              >
                <SettingsIcon />
              </IconButton>
            )}
            <IconButton
              color={'secondary-light'}
              onClick={handleClearFilter}
            >
              <RefreshIcon />
            </IconButton>
          </Stack>
        </BoxPaddings >
      )}
      <FilterDialog
        isFilterOpen={isOpen}
        handleClose={handleCloseFilter}
        physicianOptions={getPhysicians}
        healthCasesOptions={healthCasesOptions}
        tempSelectedHealthCase={tempSelectedHealthCase}
        handleUpdateHealthCase={handleUpdateHealthCase}
        filter={filter}
        setNewFilters={setTempFilter}
      />
    </>
  );
};
