import {SxProps, Theme} from '@mui/material';
import {PartialRecord} from '@src/types';

export const sx = {
  tooltipContainer: {
    background: (theme) => theme.palette.grey[800],
    padding: '4px 4px 4px 12px',
    gap: 2,
    boxShadow: (theme) => theme.boxShadowTooltip,
  },
  text: {
    color: (theme) => theme.palette.icon.white,
  },
  textDate: {
    color: (theme) => theme.palette.grey[500],
  },
  mainWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 24,
  },
} satisfies PartialRecord<string, SxProps<Theme>>;
