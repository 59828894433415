import {styled, Box, Typography} from '@mui/material';
import {paletteColors} from '@src/theme';
import {FC} from 'react';
import {Button} from 'ui-kit';
import {IGroupButton} from './types';

export const GroupButton: FC<IGroupButton> = ({sx, options, value, onChange, color, variant, fullWidth, disabled, mobile}) => {
  return (
    <Box sx={sx}>
      {options.map((opt) => (
        <ButtonCheck
          color={color}
          fullWidth={fullWidth}
          mobile={mobile}
          variant={variant}
          key={opt.value}
          checked={value === opt.value}
          disabled={disabled}
          onClick={() => onChange(opt.value)}>
          <Typography variant='14_18_600'>{opt.label}</Typography>
        </ButtonCheck>
      ))}
    </Box>
  );
};

const ButtonCheck = styled(Button)<{checked: boolean, mobile?: boolean}>(({theme, checked, fullWidth}) => ({
  borderWidth: checked ? 2 : 1,
  borderColor: checked ? paletteColors.blue[400] : paletteColors.grey[300],
  color: checked ? paletteColors.blue[400] : paletteColors.grey[700],
  padding: fullWidth ? 0 : 0,
  minWidth: fullWidth ? '63px' : '75px',
  height: fullWidth ? '32px' : '42px',
  ':hover': {
    borderWidth: checked ? 2 : 1,
    borderColor: !checked ? theme.palette.field.border.hover : '',
  },
  '&:disabled': {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.grey[600],
    borderWidth: checked ? 2 : 1,
  },
}));
