export const DYNAMICS_DATA_TEST_ID = {
  dynamicsFiltersTestsSelect: 'dynamics__filters_tests-select',
  dynamicsFiltersTestsSelectDropdownCheckUncheckAll: 'dynamics__filters_tests-select_dropdown_check-uncheck-all',
  dynamicsFiltersTestsSelectDropdownItem: 'dynamics__filters_tests-select_dropdown_item',
  dynamicsBreadcrumbsResults: 'dynamics__breadcrumbs_results',
  dynamicsBreadcrumbsResultDetails: 'dynamics__breadcrumbs_result-details',
  dynamicsDialogFiltersTestsSelect: 'dynamics__dialog_filters_tests-select',
  dynamicsDialogFiltersTestsSelectDropdownCheckUncheckAll: 'dynamics__dialog_filters_tests-select_dropdown_check-uncheck-all',
  dynamicsDialogFiltersTestsSelectDropdownItem: 'dynamics__dialog_filters_tests-select_dropdown_item',
} as const;
