import {Stack} from '@mui/material';
import {
  Biomarker as BiomarkerType,
  BiomarkerUnit,
  FetchBiomarkersResponse,
  storageApi,
} from '@src/api';
import {FormInputControl} from '@src/components/FormInputControl';
import {FormSelectControlNew} from '@src/components/FormSelectControlNew';
import {FormTempSelect} from '@src/components/FormTempSelect';
import {useTranslate} from '@src/i18n/useTranslate';
import {MAX_STORAGE_BIOMARKER_VALUE_LENGTH} from '@src/shared/constants/formFields';
import {useMQuery} from '@src/shared/hooks';
import {useDebounce} from '@src/shared/hooks/useDebounce';
import React, {ChangeEvent, FC, useEffect, useMemo, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {IconButtonNew, MenuItem} from 'ui-kit';
import DeleteIcon from 'ui-kit/lib/icons/24/Action/Delete';

import {DEFAULT_BIOMARKER_PER_PAGE} from './constants';
import {sx} from './styles';
import {BiomarkerProps} from './types';

export const Biomarker: FC<BiomarkerProps> = ({biomarker, onRemove, name, initialBiomarker, isEdit}) => {
  const {t} = useTranslate('storage');
  const {mobile: isMobile} = useMQuery();
  const formContext = useFormContext();

  const formBiomarkerIdName = name + '.biomarkerId';
  const formBiomarkerNameName = name + '.biomarkerName';
  const formBiomarkerUnitIdName = name + '.biomarkerUnitId';
  const formValueName = name + '.value';
  const formHasUnitsName = name + '.hasUnits';

  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 2000);
  const [currentBiomarkerPage, setCurrentBiomarkerPage] = useState(0);
  const [isBiomarkersLoading, setIsBiomarkersLoading] = useState(false);
  const [biomarkers, setBiomarkers] = useState<FetchBiomarkersResponse>({
    hasNext: true,
    items: [],
  });

  const [selectedBiomarker, setSelectedBiomarker] = useState<BiomarkerType>({
    name: formContext.getValues(formBiomarkerNameName),
    id: formContext.getValues(formBiomarkerIdName),
  });

  const [biomarkerUnits, setBiomarkerUnits] = useState<BiomarkerUnit[]>([]);

  const handleChangeSearchValue = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchValue(value);
  };

  const handleChangeSelectedBiomarker = (newBiomarker: BiomarkerType) => {
    setSelectedBiomarker(newBiomarker);
    formContext.setValue(formBiomarkerNameName, newBiomarker.name);

    if (biomarker.biomarkerId !== selectedBiomarker?.id) {
      formContext.clearErrors(name);
      formContext.setValue(formBiomarkerUnitIdName, '');
      formContext.setValue(formValueName, '');
    }
  };

  const handleFetchBiomarkers = async (isNext?: boolean) => {
    if (isBiomarkersLoading || (isNext && !biomarkers.hasNext)) {
      return;
    }
    setIsBiomarkersLoading(true);
    try {
      const fetchPage = isNext ? currentBiomarkerPage + 1 : 0;
      const response = await storageApi.getBiomarkers({
        perPage: DEFAULT_BIOMARKER_PER_PAGE,
        startPage: fetchPage,
        phrase: debouncedSearchValue,
      });
      if (isNext) {
        setBiomarkers((prev) => ({
          hasNext: response.hasNext,
          items: [...prev.items, ...response.items],
        }));
      } else {
        setBiomarkers(response);
      }
      setCurrentBiomarkerPage(fetchPage);
    } catch (e) {
      console.error(e);
    }
    setIsBiomarkersLoading(false);
  };

  const createBiomarkersArr = formContext.watch('createBiomarkers');
  const editBiomarkersArr = formContext.watch('editBiomarkers');

  const filteredBiomarkers = useMemo(() => {
    const createBiomarkers = formContext.getValues('createBiomarkers') || [];
    const editBiomarkers = formContext.getValues('editBiomarkers') || [];
    const combinedBiomarkers = [...editBiomarkers, ...createBiomarkers];

    return biomarkers.items.filter((responseBiomarker) => {
      if (responseBiomarker.id === selectedBiomarker?.id) {
        return true;
      }
      return !combinedBiomarkers.find((cBiomarker) => {
        if (cBiomarker.biomarkerId === selectedBiomarker?.id) {
          return false;
        }
        return responseBiomarker.id === cBiomarker.biomarkerId;
      });
    });
  }, [biomarkers.items, createBiomarkersArr, editBiomarkersArr]);

  const handleFetchSelectedBiomarkerUnits = async () => {
    if (!selectedBiomarker) {
      return;
    }
    try {
      const response = await storageApi.getBiomarkersUnits({
        requests: [selectedBiomarker.id],
      });
      setBiomarkerUnits(response);
      formContext.setValue(formHasUnitsName, !!response.length);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (initialBiomarker) {
      const {biomarkerObj} = initialBiomarker;
      setSelectedBiomarker(biomarkerObj);
    }
  }, [initialBiomarker]);

  useEffect(() => {
    void handleFetchBiomarkers();
  }, [debouncedSearchValue]);

  useEffect(() => {
    void handleFetchSelectedBiomarkerUnits();
  }, [selectedBiomarker]);

  const handleBiomarkerRenderValue = (value: string) => {
    if (value === selectedBiomarker?.id) {
      return selectedBiomarker?.name || '';
    }
    const newBiomarker = filteredBiomarkers.find((item) => item.id === value);
    if (newBiomarker) {
      handleChangeSelectedBiomarker(newBiomarker);
      return newBiomarker.name;
    }
    return '';
  };

  return (
    <Stack sx={sx.container}>
      <Stack flexDirection={'row'} gap={12} flex={1}>
        <FormTempSelect
          name={formBiomarkerIdName}
          options={filteredBiomarkers.map(({name, id}) => ({name, value: id}))}
          inputSx={sx.biomarkerSelect}
          mobileLabel={t('BIOMARKERS')}
          placeholder={t('SELECT_BIOMARKER')}
          noOptionsText={t('NO_RESULTS')}
          searchValue={searchValue}
          onChangeSearchValue={handleChangeSearchValue}
          renderValue={handleBiomarkerRenderValue}
          onNext={() => {
            void handleFetchBiomarkers(true);
          }}
          disabled={isEdit}
        />

        {isMobile && !isEdit && (
          <IconButtonNew
            onClick={onRemove}
            size={'lg'}
            color={'tertiary'}
          >
            <DeleteIcon />
          </IconButtonNew>
        )}
      </Stack>
      <Stack sx={sx.wrapperForm}>
        <FormInputControl
          name={formValueName}
          placeholder={t('VALUE')}
          compact={false}
          sx={sx.valueInput}
          showErrorText={false}
          disabled={!selectedBiomarker || isEdit}
          maxlength={MAX_STORAGE_BIOMARKER_VALUE_LENGTH}
          max={MAX_STORAGE_BIOMARKER_VALUE_LENGTH}
        />
        <FormSelectControlNew
          name={formBiomarkerUnitIdName}
          disabled={!biomarkerUnits.length || isEdit}
          sx={sx.unitSelect}
          noMobileView
          options={biomarkerUnits.map((option) => ({
            value: option.id,
            label: option.name,
          }))}
          showErrorText={false}
        >
          {biomarkerUnits.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </FormSelectControlNew>
      </Stack>
      {!isMobile && !isEdit && (
        <IconButtonNew
          onClick={onRemove}
          size={'lg'}
          color={'tertiary'}
        >
          <DeleteIcon />
        </IconButtonNew>
      )}
    </Stack>
  );
};
