import {Race, Gender, BirthSex, Ethnicity, StateType, SexualOrientationMap} from '@src/api';
import {RelationshipType} from '@src/api/relations';
import {
  BIRTH_SEX_TYPE_MAP,
  ETHNICITY_TYPE_MAP,
  GENDER_TYPE_MAP,
  RACE_TYPE_MAP,
  RELATIONSHIP_TYPE_MAP,
  SEXYAL_ORIENTATION_TYPE_MAP,
  STATES_TYPE_MAP,
} from '@src/shared/constants/enumsMaps';
import {RelationshipTypes} from '@src/types';

export const raceOptions = Object.values(Race).map((name) => ({name: RACE_TYPE_MAP[name], value: name}));

export const ethnicityOptions = Object.values(Ethnicity).map((name) => ({name: ETHNICITY_TYPE_MAP[name], value: name}));

export const genderOptions = Object.values(Gender).map((name) => ({name: GENDER_TYPE_MAP[name], value: name}));

export const sexualOrientationOptions = Object.values(SexualOrientationMap).map((name) => ({name: SEXYAL_ORIENTATION_TYPE_MAP[name], value: name}));

export const birthSexSelectOptions = Object.values(BirthSex).map((name) => ({name: BIRTH_SEX_TYPE_MAP[name], value: name}));

export const statesSelectOptions = Object.values(StateType).map((name) => ({name: STATES_TYPE_MAP[name], value: name}));

export const relationTypesSelectOptions = Object.values(RelationshipTypes).map((name) => ({name}));

export const relationshipTypeSelectOptions = Object.values(RelationshipType).map((name) => ({name: RELATIONSHIP_TYPE_MAP[name], value: name}));
