import {accessesActions} from '@src/store/accesses/slice';
import {UUID} from '@src/types';
import {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState, useAppSelector} from 'store';

const useAccessesState = () => useSelector((state: RootState) => state.accesses);

export const useAccesses = () => {
  const accessesState = useAccessesState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!accessesState.accesses) {
      dispatch(accessesActions.requestAccesses());
    }
  }, [accessesState.accesses, dispatch]);

  return {
    accesses: accessesState.accesses,
    isFetching: !!accessesState.fetching?.accesses,
  };
};

export const useAccessesRequests = () => {
  const {accessesRequests, fetching} = useAppSelector((state) => state.accesses);
  const dispatch = useDispatch();
  const incomingRequests = accessesRequests?.incoming.length
    ? [
      ...(Array.isArray(accessesRequests?.incoming)
        ? accessesRequests?.incoming
        : [accessesRequests?.incoming]),
    ]
    : null;

  useEffect(() => {
    if (!accessesRequests) {
      dispatch(accessesActions.requestAccessesRequests());
    }
  }, [dispatch]);

  return {
    incoming: incomingRequests
      ? incomingRequests.sort((a, b) => (a.createdDate > b.createdDate ? 1 : -1))
      : null,
    outgoing: accessesRequests?.outgoing || null,
    fetching: !!fetching?.accessesRequests,
  };
};

export const useApproveAccessesRequest = () => {
  const accessesRequests = useSelector((state: RootState) => state.accesses.accessesRequests);
  const dispatch = useDispatch();
  const callbacks = useMemo(() => {
    return {
      approve: ({requestId}: {requestId: UUID | undefined}) => {
        if (!requestId) {
          console.error('[useApproveAccessesRequest] companyId or relationId is undefined');
        } else {
          dispatch(accessesActions.approveAccessesRequest({requestId}));
          const updatedRequests = accessesRequests?.incoming?.filter((request) => request.id !== requestId);
          if (updatedRequests && accessesRequests) {
            dispatch(accessesActions.setAccessesRequests({
              accessesRequests: {incoming: updatedRequests, outgoing: accessesRequests.outgoing},
            }));
          }
        }
      },
    };
  }, [dispatch, accessesRequests]);
  return {
    ...callbacks,
  };
};

export const useDeclineAccessesRequest = () => {
  const accessesRequests = useSelector((state: RootState) => state.accesses.accessesRequests);
  const dispatch = useDispatch();
  const callbacks = useMemo(() => {
    return {
      decline: ({requestId}: {requestId: UUID | undefined}) => {
        if (!requestId) {
          console.error('[useDeclineAccessesRequest] companyId or relationId is undefined');
        } else {
          dispatch(accessesActions.declineAccessesRequest({requestId}));
          const updatedRequests = accessesRequests?.incoming?.filter((request) => request.id !== requestId);
          if (updatedRequests && accessesRequests) {
            dispatch(accessesActions.setAccessesRequests({
              accessesRequests: {incoming: updatedRequests, outgoing: accessesRequests.outgoing},
            }));
          }
        }
      },
    };
  }, [dispatch, accessesRequests]);
  return {
    ...callbacks,
  };
};

export const useDeleteAccessesRequest = () => {
  const accessesRequests = useSelector((state: RootState) => state.accesses.accessesRequests);
  const dispatch = useDispatch();
  const callbacks = useMemo(() => {
    return {
      deleteRequest: ({requestId}: {requestId: UUID | undefined}) => {
        if (!requestId) {
          console.error('[useDeleteAccessesRequest] companyId or relationId is undefined');
        } else {
          dispatch(accessesActions.deleteAccessesRequest({requestId}));
          const updatedRequests = accessesRequests?.outgoing?.filter((request) => request.id !== requestId);
          if (updatedRequests && accessesRequests) {
            dispatch(accessesActions.setAccessesRequests({
              accessesRequests: {incoming: accessesRequests.incoming, outgoing: updatedRequests},
            }));
          }
        }
      },
    };
  }, [dispatch, accessesRequests]);
  return {
    ...callbacks,
  };
};
