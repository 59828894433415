/* eslint-disable react-hooks/exhaustive-deps */
import {FullContainerLoader} from '@src/components/FullContainerLoader';
import {WBox} from '@src/components/WhiteBox';
import {useTranslate} from '@src/i18n/useTranslate';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {spreadSx} from '@src/shared/utils/spreadSx';
import {useAccesses, useAccessesRequests} from '@src/store/accesses/hooks';
import {useContactRelations, useRelationRequests} from '@src/store/relations/hooks';
import {useEffect, useState} from 'react';
import {Outlet, useLocation} from 'react-router-dom';

import {PageHeader} from './fragments/Relations/fragments/PageHeader';
import {sx} from './styles';

export const Connections = () => {
  const {mobile, tablet, desktop} = useMQuery();
  const {ready} = useTranslate('connections');
  const location = useLocation();
  const [value, setValue] = useState(0);
  const [isIncomingRequest, setIsIncomingRequest] = useState<boolean>(true);
  const {groupedRelations, fetching: isContactRelationsFetching} = useContactRelations('');
  const {accesses, isFetching: isAccessesFetching} = useAccesses();
  const {fetching: isRelationsFetching} = useRelationRequests();
  const {fetching: isAccessFetching} = useAccessesRequests();

  useEffect(() => {
    setValue(Number(window.localStorage.getItem('value')));
  }, []);

  useEffect(() => {
    window.localStorage.setItem('value', JSON.stringify(value));
  }, [value]);

  const isAccesses = location.pathname.includes('accesses');

  if (!ready || isContactRelationsFetching || isAccessesFetching || isRelationsFetching || isAccessFetching) {
    return <FullContainerLoader />;
  }

  return (
    <>
      <PageHeader />
      {desktop && (
        <WBox
          noPaddings
          sx={{
            height: 'calc(100vh - 210px)',
            pr: isAccesses || desktop ? 0 : 16,
          }}
        >
          <Outlet
            context={{isIncomingRequest, setIsIncomingRequest, groupedRelations, accesses}}
          />
        </WBox>
      )}
      {(tablet || mobile) && (
        <WBox
          sx={[
            ...spreadSx(sx.contentContainer),
            {overflowX: mobile && isAccesses ? 'hidden' : 'inherit'},
          ]}
        >
          <Outlet
            context={{isIncomingRequest, setIsIncomingRequest, groupedRelations, accesses}}
          />
        </WBox>
      )}
    </>
  );
};
