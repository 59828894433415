import {SxProps, Theme} from '@mui/material';
import {EBackgroundFlagColor} from '@src/pages/Results/Results.constant';
import {PartialRecord} from '@src/types';

export const sx: PartialRecord<string, SxProps<Theme>> = {
  dialog: t => ({
    '.MuiPaper-root': {
      mt: {
        md: 60,
        xs: 24,
      },
      mb: {
        md: 60,
        xs: 24,
      },
      '&.MuiDialog-paperScrollBody': {
        [t.breakpoints.down('md')]: {
          width: '100%',
          maxWidth: 'calc(100% - 48px)',
        },
      },
    },
  }),
  formWrapper: {
    height: {
      xs: 'calc(100% - 10px)',
      sm: 'calc(100% - 49px)',
    },
    justifyContent: {
      xs: 'space-between',
      sm: 'unset',
    },
  },
  contentWrapper: {
    height: {
      xs: 'calc(100% - 133px)',
      sm: 'unset',
    },
    display: {xs: 'flex', sm: 'unset'},
    flexDirection: {xs: 'column', sm: 'unset'},
  },
  content: {
    padding: {sm: '48px 24px 24px 24px', xs: '36px 18px 18px 18px'},
    gap: 24,
    overflowY: 'scroll',
  },
  datePickerLabel: {
    position: 'absolute',
    top: -20,
  },
  uploadButton: {
    typography: '16_20_600',
    display: 'inline-flex',
    padding: 0,
    height: 'unset',
    transition: '0.2s',
  },
  file: {
    height: 30,
  },
  actionsContainer: t => ({
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 24,
    borderTop: '1px solid',

    borderColor: t.colors.borderBlueLight,
    [t.breakpoints.only('xs')]: {
      flexDirection: 'column-reverse',
      gap: 12,
      padding: 18,
      position: 'fixed',
      bottom: 0,
      width: '100%',
      backgroundColor: t.palette.common.white,
    },
  }),
  errorFile: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12,
    padding: {
      xs: 9,
      sm: 12,
    },
    backgroundColor: EBackgroundFlagColor.CRITICAL,
    svg: {
      width: {
        xs: 18,
        sm: 24,
      },
      height: {
        xs: 18,
        sm: 24,
      },
    },
  },
};
