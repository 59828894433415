import {IconButton, Stack, Typography} from '@mui/material';
import {HealthcareInsurancePolicy} from '@src/api';
import {RowInformation} from '@src/components';
import {ListWrapper} from '@src/components/ListWrapper/ListWrapper';
import {WBox} from '@src/components/WhiteBox';
import {ROUTERS_PATH} from '@src/routers';
import {ReactComponent as EditIcon} from '@src/shared/assets/icons/edit-icon.svg';
import {ReactComponent as Add} from '@src/shared/assets/icons/plus.svg';
import {ReactComponent as TrashIcon} from '@src/shared/assets/icons/trash.svg';
import {STATES_TYPE_MAP} from '@src/shared/constants/enumsMaps';
import {useMQuery} from '@src/shared/hooks';
import {NO_VALUE, dateFormatted} from '@src/shared/utils';
import {formatPhoneNumber} from '@src/shared/utils/formatPhoneNumber';
import {getRowInformationTypography} from '@src/shared/utils/getRowInformationTypography';
import {spreadSx} from '@src/shared/utils/spreadSx';
import {useInsurances} from '@src/store/insurance/hooks';
import {useTranslate} from 'i18n/useTranslate';
import {FC} from 'react';
import {generatePath, useNavigate} from 'react-router-dom';
import {Button} from 'ui-kit';

import {getListWrapperPaddings} from '../utils/getListWrapperPaddings';
import {getRowInfoSpaceBetween} from '../utils/getRowInfoSpaceBetween';

import {COUNT_INSURANCE} from './constants';
import {sx} from './styles';

interface InsuranceContentProps {
  setId: (id?: string) => void
  activeInsurance?: HealthcareInsurancePolicy[]
}

export const InsuranceContent: FC<InsuranceContentProps> = ({setId, activeInsurance}) => {
  const {t, ready} = useTranslate('insurance');
  const {t: tEnums, ready: enumsReady} = useTranslate('enums');
  const navigate = useNavigate();
  const {mobile: isMobile, desktop: isDesktop} = useMQuery();
  const {healthcareInsurancePolicies} = useInsurances();

  const hasHealthcareInsurancePolicies = healthcareInsurancePolicies?.length && healthcareInsurancePolicies?.length > 0;
  const {rowInfoNameTypography, rowInfoValueTypography} = getRowInformationTypography(isMobile);

  const spaceBetween = getRowInfoSpaceBetween(isMobile);
  const listWrapperPaddings = getListWrapperPaddings(isMobile);

  const goToInsuranceEdit = (id: HealthcareInsurancePolicy['id']) => {
    if (!id) {
      return;
    }

    const path = generatePath(ROUTERS_PATH.EDIT_INSURANCE, {
      id,
    });
    navigate(path);
  };

  return (
    <>
      {hasHealthcareInsurancePolicies && (
        <Stack sx={sx.contentWrapper}>
          {healthcareInsurancePolicies.map((insurance) => (
            <WBox
              key={insurance.id}
              sx={[
                ...spreadSx(sx.whiteBox),
                {
                  mb: {
                    xs: (!insurance.primary &&
                    (activeInsurance?.length ?? 0) < 2)
                      ? 76
                      : 0,
                    sm: 0,
                  },
                },
              ]}
            >
              {ready && enumsReady && (
                <Stack sx={sx.content}>
                  <Stack sx={sx.insuranceWrapper}>
                    <Stack sx={sx.listHeaderWrapper}>
                      <Typography variant={isMobile ? '22_30_500' : '24_30_500'}>
                        {insurance.primary ? t('PRIMARY_INSURANCE') : t('SECONDARY_INSURANCE')}
                      </Typography>
                      <Stack sx={sx.wrapperEditButton}>
                        {insurance.primary &&
                        !activeInsurance?.find((insurance) => insurance.primary)
                          ? null
                          : (
                            <IconButton
                              sx={sx.icon}
                              onClick={() => goToInsuranceEdit(insurance.id)}
                            >
                              <EditIcon />
                            </IconButton>
                          )}
                        <IconButton sx={sx.icon} onClick={() => setId(insurance.id)} >
                          <TrashIcon />
                        </IconButton>
                      </Stack>
                    </Stack>
                    <ListWrapper
                      padding={listWrapperPaddings}
                      noLast={{xs: 0}}
                    >
                      <RowInformation
                        variant={rowInfoNameTypography}
                        valueTypographyVariant={rowInfoValueTypography}
                        noWrap={!isDesktop}
                        name={t('CARRIER')}
                        value={insurance.insurancePlan?.carrier?.name}
                        noMargin
                        tooltip={isDesktop}
                        spaceBetween={spaceBetween}
                      />
                      <RowInformation
                        variant={rowInfoNameTypography}
                        valueTypographyVariant={rowInfoValueTypography}
                        noWrap={!isDesktop}
                        name={t('PLAN')}
                        value={insurance.insurancePlan?.name}
                        noMargin
                        tooltip={isDesktop}
                        spaceBetween={spaceBetween}
                      />
                      <RowInformation
                        variant={rowInfoNameTypography}
                        valueTypographyVariant={rowInfoValueTypography}
                        noWrap={!isDesktop}
                        name={t('POLICY_ID')}
                        value={insurance.number}
                        noMargin
                        tooltip={isDesktop}
                        spaceBetween={spaceBetween}
                      />
                      <RowInformation
                        variant={rowInfoNameTypography}
                        valueTypographyVariant={rowInfoValueTypography}
                        noWrap={!isDesktop}
                        name={t('END_DATE')}
                        value={dateFormatted(insurance.endDate)}
                        noMargin
                        tooltip={isDesktop}
                        spaceBetween={spaceBetween}
                      />
                    </ListWrapper>
                  </Stack>
                  <Stack sx={sx.guarantorWrapper}>
                    <Typography variant={isMobile ? '18_24_700' : '20_24_700'}>{t('GUARANTOR')}</Typography>
                    <Stack sx={sx.guarantorContentWrapper}>
                      <Stack sx={sx.listWrapperContainer}>
                        <ListWrapper
                          padding={listWrapperPaddings}
                          noLast={{xs: 0}}
                        >
                          <RowInformation
                            variant={rowInfoNameTypography}
                            valueTypographyVariant={rowInfoValueTypography}
                            noWrap={!isDesktop}
                            tooltip={isDesktop}
                            value={t(insurance.guarantor?.relationType)}
                            name={t('RELATIONSHIP')}
                            noMargin
                            spaceBetween={spaceBetween}
                          />
                          <RowInformation
                            variant={rowInfoNameTypography}
                            valueTypographyVariant={rowInfoValueTypography}
                            noWrap={!isDesktop}
                            tooltip={isDesktop}
                            value={insurance.guarantor?.firstName}
                            name={t('FIRST_NAME')}
                            noMargin
                            spaceBetween={spaceBetween}
                          />
                          <RowInformation
                            variant={rowInfoNameTypography}
                            valueTypographyVariant={rowInfoValueTypography}
                            noWrap={!isDesktop}
                            tooltip={isDesktop}
                            value={insurance.guarantor?.lastName}
                            name={t('LAST_NAME')}
                            noMargin
                            spaceBetween={spaceBetween}
                          />
                          <RowInformation
                            variant={rowInfoNameTypography}
                            valueTypographyVariant={rowInfoValueTypography}
                            noWrap={!isDesktop}
                            tooltip={isDesktop}
                            value={insurance.guarantor?.middleName}
                            name={t('MIDDLE_NAME')}
                            noMargin
                            spaceBetween={spaceBetween}
                          />
                          <RowInformation
                            variant={rowInfoNameTypography}
                            valueTypographyVariant={rowInfoValueTypography}
                            noWrap={!isDesktop}
                            tooltip={isDesktop}
                            value={dateFormatted(insurance.guarantor?.birthDate ?? NO_VALUE)}
                            name={t('DATE_OF_BIRTH')}
                            noMargin
                            spaceBetween={spaceBetween}
                          />
                          <RowInformation
                            variant={rowInfoNameTypography}
                            valueTypographyVariant={rowInfoValueTypography}
                            noWrap={!isDesktop}
                            tooltip={isDesktop}
                            value={t(insurance.guarantor?.birthSex)}
                            name={t('BIRTH_SEX')}
                            noMargin
                            spaceBetween={spaceBetween}
                          />
                          <RowInformation
                            variant={rowInfoNameTypography}
                            valueTypographyVariant={rowInfoValueTypography}
                            noWrap={!isDesktop}
                            tooltip={isDesktop}
                            value={insurance.guarantor?.ssnTail}
                            name={t('SSN_LAST_4')}
                            noMargin
                            spaceBetween={spaceBetween}
                          />
                          {isMobile && (
                            <>
                              <RowInformation
                                variant={rowInfoNameTypography}
                                valueTypographyVariant={rowInfoValueTypography}
                                noWrap={!isDesktop}
                                tooltip={isDesktop}
                                value={insurance.guarantor?.state
                                  ? tEnums(STATES_TYPE_MAP[insurance.guarantor?.state])
                                  : ''}
                                name={t('STATE')}
                                noMargin
                                spaceBetween={spaceBetween}
                              />
                              <RowInformation
                                variant={rowInfoNameTypography}
                                valueTypographyVariant={rowInfoValueTypography}
                                noWrap={!isDesktop}
                                tooltip={isDesktop}
                                value={insurance.guarantor?.city}
                                name={t('CITY')}
                                noMargin
                                spaceBetween={spaceBetween}
                              />
                              <RowInformation
                                variant={rowInfoNameTypography}
                                valueTypographyVariant={rowInfoValueTypography}
                                noWrap={!isDesktop}
                                tooltip={isDesktop}
                                value={insurance.guarantor?.main}
                                name={t('ADDRESS_1')}
                                noMargin
                                spaceBetween={spaceBetween}
                              />
                              <RowInformation
                                variant={rowInfoNameTypography}
                                valueTypographyVariant={rowInfoValueTypography}
                                noWrap={!isDesktop}
                                tooltip={isDesktop}
                                value={insurance.guarantor?.additional}
                                name={t('ADDRESS_2')}
                                noMargin
                                spaceBetween={spaceBetween}
                              />
                              <RowInformation
                                variant={rowInfoNameTypography}
                                valueTypographyVariant={rowInfoValueTypography}
                                noWrap={!isDesktop}
                                tooltip={isDesktop}
                                value={insurance.guarantor?.postalCode}
                                name={t('ZIP_CODE')}
                                noMargin
                                spaceBetween={spaceBetween}
                              />
                              <RowInformation
                                variant={rowInfoNameTypography}
                                valueTypographyVariant={rowInfoValueTypography}
                                noWrap={!isDesktop}
                                tooltip={isDesktop}
                                value={formatPhoneNumber(insurance.guarantor?.phone)}
                                name={t('PHONE')}
                                noMargin
                                spaceBetween={spaceBetween}
                              />
                            </>
                          )}
                        </ListWrapper>
                      </Stack>
                      {!isMobile && (
                        <Stack sx={sx.listWrapperContainer}>
                          <ListWrapper
                            padding={listWrapperPaddings}
                            noLast={{xs: 0}}>
                            <RowInformation
                              variant={rowInfoNameTypography}
                              valueTypographyVariant={rowInfoValueTypography}
                              noWrap={!isDesktop}
                              tooltip={isDesktop}
                              value={insurance.guarantor?.state
                                ? tEnums(STATES_TYPE_MAP[insurance.guarantor?.state])
                                : ''}
                              name={t('STATE')}
                              noMargin
                              spaceBetween={spaceBetween}
                            />
                            <RowInformation
                              variant={rowInfoNameTypography}
                              valueTypographyVariant={rowInfoValueTypography}
                              noWrap={!isDesktop}
                              tooltip={isDesktop}
                              value={insurance.guarantor?.city}
                              name={t('CITY')}
                              noMargin
                              spaceBetween={spaceBetween}
                            />
                            <RowInformation
                              variant={rowInfoNameTypography}
                              valueTypographyVariant={rowInfoValueTypography}
                              noWrap={!isDesktop}
                              tooltip={isDesktop}
                              value={insurance.guarantor?.main}
                              name={t('ADDRESS_1')}
                              noMargin
                              spaceBetween={spaceBetween}
                            />
                            <RowInformation
                              variant={rowInfoNameTypography}
                              valueTypographyVariant={rowInfoValueTypography}
                              noWrap={!isDesktop}
                              tooltip={isDesktop}
                              value={insurance.guarantor?.additional}
                              name={t('ADDRESS_2')}
                              noMargin
                              spaceBetween={spaceBetween}
                            />
                            <RowInformation
                              variant={rowInfoNameTypography}
                              valueTypographyVariant={rowInfoValueTypography}
                              noWrap={!isDesktop}
                              tooltip={isDesktop}
                              value={insurance.guarantor?.postalCode}
                              name={t('ZIP_CODE')}
                              noMargin
                              spaceBetween={spaceBetween}
                            />
                            <RowInformation
                              variant={rowInfoNameTypography}
                              valueTypographyVariant={rowInfoValueTypography}
                              noWrap={!isDesktop}
                              tooltip={isDesktop}
                              value={formatPhoneNumber(insurance.guarantor?.phone)}
                              name={t('PHONE')}
                              noMargin
                              spaceBetween={spaceBetween}
                            />
                          </ListWrapper>
                        </Stack>
                      )
                      }
                    </Stack>
                  </Stack>
                </Stack>
              )}
            </WBox>
          ))}
        </Stack>
      )}
      {!healthcareInsurancePolicies?.length && (
        <WBox sx={sx.whiteBox}>
          <>
            <Stack gap={24}>
              <Typography variant={isMobile ? '22_30_500' : '24_30_500'}>
                {t('PRIMARY_INSURANCE')}
              </Typography>
              <Typography
                variant={'14_18_500'}
                component="p"
                sx={sx.noInsuranceDescription}
              >
                {t('NO_INSURANCE_PLANS_WERE_FOUND')}
              </Typography>
            </Stack>
            {isMobile && (healthcareInsurancePolicies?.length ?? 0) < COUNT_INSURANCE && (
              <WBox sx={sx.addInsuranceButtonOnMobile}>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<Add />}
                  onClick={() => navigate(ROUTERS_PATH.ADD_INSURANCE)}
                >
                  {t('ADD_INSURANCE')}
                </Button>
              </WBox>
            )}
          </>
        </WBox>
      )}
    </>
  );
};
