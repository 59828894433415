import {Theme} from '@mui/material';
import {SxProps} from '@mui/system';

export const stylesSx = {
  boxPaddings: {
    padding: {
      xs: '12px 24px 12px 52px',
      sm: '12px 24px 12px 52px',
      md: '24px 24px 24px 81px',
    },
  },
  checkboxPosition: {
    position: 'absolute',
    top: -3,
    left: -32,
  },
  dlPosition: {
    position: 'relative',
  },
  commentText: {
    whiteSpace: 'wrap',
    wordBreak: 'break-all',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': '4',
  },
} satisfies Record<string, SxProps<Theme>>;
