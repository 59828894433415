import {Stack, Typography} from '@mui/material';
import {StorageFile} from '@src/api';
import {useTranslate} from '@src/i18n/useTranslate';
import {STORAGE_CATEGORY_TYPE_MAP} from '@src/shared/constants/enumsMaps';
import {useMQuery} from '@src/shared/hooks';
import {dateFormatted, formatSize} from '@src/shared/utils';
import {useAppDispatch, useAppSelector} from '@src/store';
import {healthCasesActions} from '@src/store/healthCases/slice';
import {Checkbox} from 'ui-kit';

import {MobileCardContainer} from '../../ResultsTableDialog/CardResultsMobile/styles';

import {sx} from './styles';

export const DocumentCard = (file: StorageFile) => {
  const {t, ready} = useTranslate('healthCases');
  const {t: tEnums} = useTranslate('enums');
  const {mobile} = useMQuery();
  const dispatch = useAppDispatch();
  const {selectedDocuments, initialDocuments, savedDocuments} = useAppSelector((state) => state.healthCases);
  const isDisabled = initialDocuments.has(file.id) || savedDocuments.has(file.id);

  const handleCheckboxChange = () => {
    if (!isDisabled) {
      dispatch(healthCasesActions.toggleDocumentsSelection(file.id));
    }
  };

  const isChecked = selectedDocuments.has(file.id) || initialDocuments.has(file.id) || savedDocuments.has(file.id);
  const healthCases = file.healthCases?.map((el) => el.name).join(', ');

  const typeVariant = mobile ? '12_18_700' : '14_18_700';
  const descriptionVariant = mobile ? '12_18_500' : '14_18_500';

  if (!ready) return null;

  return (
    <MobileCardContainer
      key={file.id}
      onClick={handleCheckboxChange}
      isSelected={isChecked}
      isDisabled={isDisabled}
    >
      <Stack sx={sx.checkboxContainer}>
        <Checkbox
          disabled={isDisabled}
          value={isChecked}
          sx={sx.checkbox}
        />
      </Stack>
      <Stack gap={6} sx={{width: '100%'}}>
        <Typography variant='20_24_700' sx={sx.name}>{file.name}</Typography>
        <Stack flexDirection={'row'} gap={12}>
          <Typography variant={typeVariant}>{t('CATEGORY')}</Typography>
          <Typography variant={descriptionVariant}>{tEnums(STORAGE_CATEGORY_TYPE_MAP[file.category])}</Typography>
        </Stack>
        <Typography variant={descriptionVariant} sx={sx.healthCaseText}>
          <b>{t('HEALTH_CASES')}</b>
          {healthCases}
        </Typography>
        <Stack flexDirection={'row'} justifyContent={'space-between'}>
          <Stack flexDirection={'row'} gap={12}>
            <Stack flexDirection={'row'} gap={12}>
              <Typography variant={typeVariant}>{t('SIZE')}</Typography>
              <Typography variant={descriptionVariant}>{formatSize(file.size)}</Typography>
            </Stack>
            <Stack flexDirection={'row'} gap={12}>
              <Typography variant={typeVariant}>{t('TYPE')}</Typography>
              <Typography variant={descriptionVariant}>{file.type}</Typography>
            </Stack>
          </Stack>
          <Typography color={'grey.400'} variant={descriptionVariant}>{dateFormatted(file.date)}</Typography>
        </Stack>
      </Stack>
    </MobileCardContainer>
  );
};
