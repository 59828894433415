import {Stack, Typography} from '@mui/material';
import {RecaptchaNote} from '@src/components/RecaptchaNote';
import {WBox} from '@src/components/WhiteBox';
import {useTranslate} from '@src/i18n/useTranslate';
import {paletteColors} from '@src/theme';
import {FC} from 'react';
import {Button, InputControl} from 'ui-kit';

import {sx} from './styled';
import {IResultFormProps} from './types';

export const ResultForm: FC<IResultFormProps> = ({lastName, onSubmit, onChange, error, errorTime}) => {
  const {t} = useTranslate('results');

  const displayedTextButton = errorTime
    ? (
      <Typography
        variant='14_22_500'
        sx={{color: paletteColors.grey[700]}}
      >
        {errorTime}
      </Typography>
    )
    : (
      t('CONFIRM')
    );

  return (
    <WBox sx={sx.mainBlock}>
      <Stack sx={{flexGrow: 1}}>
        <Typography sx={sx.title} >{t('RESULT_VERIFICATION_SERVICE')}</Typography>
        <Typography sx={sx.subtitle}>{t('RESULT_VERIFICATION_SERVICE_SUBTITLE')}</Typography>
        <Typography sx={sx.text}>{t('RESULT_VERIFICATION_SERVICE_TEXT')}</Typography>
        <Stack>
          <InputControl
            sx={sx.input}
            name="lastName"
            label={t('LAST_NAME')}
            value={lastName}
            error={error}
            onChange={onChange}
            max={50}
          />
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            disabled={!!errorTime}
            onClick={onSubmit}>
            {displayedTextButton}
          </Button>
        </Stack>
        <Stack sx={sx.recaptchaWrapper}>
          <RecaptchaNote sx={sx.recaptcha} />
        </Stack>
      </Stack>
    </WBox>
  );
};
