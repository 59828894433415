import {RadioGroup} from '@mui/material';
import {FC, PropsWithChildren} from 'react';
import {useController, useFormContext} from 'react-hook-form';

import {FormRadioGroupProps} from './types';

const STRING_TO_BOOLEAN: Record<string, boolean> = {
  true: true,
  false: false,
};

export const FormRadioGroup: FC<PropsWithChildren<FormRadioGroupProps>> = ({
  name,
  children,
  isBooleanValue,
  ...groupProps
}) => {
  const formContext = useFormContext();
  const {
    field: {value, onChange},
  } = useController({
    control: formContext.control,
    name,
  });

  const handleChange = (_: any, value: string) => {
    onChange(isBooleanValue ? STRING_TO_BOOLEAN[value] || false : value);
  };

  return (
    <RadioGroup {...groupProps} value={value} onChange={handleChange}>
      {children}
    </RadioGroup>
  );
};
