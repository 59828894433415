import {Stack, Typography} from '@mui/material';
import {ReactComponent as CloseIcon} from '@src/shared/assets/icons/close-sm.svg';
import {formatSize} from '@src/shared/utils';
import {spreadSx} from '@src/shared/utils/spreadSx';
import {FC} from 'react';
import {IconButton} from 'ui-kit';
import FileCheckIcon from 'ui-kit/lib/icons/24/Action/FileCheck';

import {sx} from './styles';
import {AttachedFileProps} from './types';

export const AttachedFile: FC<AttachedFileProps> = ({
  name,
  ext,
  size,
  onDelete,
  containerSx,
  error,
}) => {
  return (
    <Stack sx={[...spreadSx(sx.container), ...spreadSx(containerSx), {background: error ? t => t.colors.fileCardErrorBackground : ''}]}>
      <FileCheckIcon size='18' />
      <Stack sx={sx.text}>
        <Typography sx={sx.name} variant={'12_16_500'}>
          {name}
        </Typography>
        {ext && <Typography variant={'12_16_500'}>.{ext}</Typography>}
      </Stack>
      <Stack sx={sx.cardEnd}>
        <Typography variant={'12_16_500'}>{formatSize(size)}</Typography>
        <IconButton
          variant={'text'}
          color={'white'}
          size={'xs'}
          onClick={onDelete}>
          <CloseIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
};
