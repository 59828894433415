import {useField} from 'formik';

export const getErrorForDisplay = ({
  error,
  touched,
  value,
  submitCount,
  immediate,
}: {
  error: string | undefined
  touched: boolean
  value: unknown
  submitCount: number
  immediate?: string[]
}) => {
  if (error && immediate && immediate.some((s) => s === error)) {
    return error;
  }

  if (error && touched) {
    if (value) {
      return error;
    } else {
      if (submitCount) {
        return error;
      } else {
        return undefined;
      }
    }
  }

  return undefined;
};

export const useFormikError = (fieldName: string, submitCount: number) => {
  const [_, {error, touched, value}] = useField(fieldName);
  return getErrorForDisplay({error, value, touched, submitCount});
};
