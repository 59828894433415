import {FC} from 'react';
import {useController, useFormContext} from 'react-hook-form';
import {OptionValue, SelectControl} from 'ui-kit';

import {FormSelectProps} from './type';

export const FormSelectControlNew: FC<FormSelectProps> = ({
  name,
  options,
  showErrorText = true,
  ...restProps
}) => {
  const formContext = useFormContext();
  const {
    field: {value, onChange},
    fieldState: {error},
  } = useController({
    control: formContext.control,
    name,
  });

  const errorTextHandler = () => {
    if (showErrorText) {
      return error?.message;
    }

    return error?.message ? ' ' : error?.message;
  };

  const selectedOption = options.find((option) => option.value === value);

  const valueWithLabel: OptionValue<string> | undefined = selectedOption
    ? {
      value: selectedOption.value,
      label: selectedOption.label,
    }
    : undefined;

  return (
    <SelectControl
      value={valueWithLabel}
      placeholder={restProps.placeholder}
      onChange={onChange}
      error={errorTextHandler()}
      {...restProps}
    />
  );
};
