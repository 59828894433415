import {Stack, Typography, useTheme} from '@mui/material';
import {useTranslate} from '@src/i18n/useTranslate';
import {DateFormat, dateFormatted} from '@src/shared/utils';
import {CloseIcon} from 'ui-kit/lib/icons/24/Action/Close';
import {BMIDataType, TtProps} from './types';
import {IconButton} from '@src/components/IconButton';

import {sx} from './styles';

export const CustomTooltip = ({active, payload, closeTooltip}: TtProps) => {
  const {t} = useTranslate('journal');
  const theme = useTheme();
  if (!active || !payload || payload.length === 0) return null;

  const data: BMIDataType = payload[0].payload;
  const {value, createdTime} = data;

  if (value === 0) return null;

  return (
    <Stack sx={sx.tooltipContainer}>
      <Stack sx={sx.mainWrapper}>
        <Typography variant="14_18_700" sx={sx.text}>
          {t('BMI_VALUE', {value})}
        </Typography>
        <IconButton onClick={closeTooltip} size='lg' color='tertiary'>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Typography variant="14_18_700" sx={sx.textDate}>
        {dateFormatted(createdTime, DateFormat.DATE_SHORT)}
      </Typography>
    </Stack>
  );
};
