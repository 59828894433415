import {useTranslate} from '@src/i18n/useTranslate';

export const getTextValue = (count: number, mobile?: boolean) => {
  const {t, ready} = useTranslate('healthCases');
  if (!ready) return null;

  if (count === 0) {
    return t('NO_ITEMS');
  }

  if (count === 1) {
    return `${count} ${t('ITEM')}`;
  }

  if (count > 1) {
    return `${count} ${t('ITEMS')}`;
  }
};
