import {notifyActions} from '@src/store/notifications/slice';
import {envs} from '@src/shared/constants/envs';
import i18n from '@src/i18n/i18n';

export const notifyError = (dispatch: Function) => {
  dispatch(
    notifyActions.showNotifications([
      {
        id: '',
        type: 'error',
        text: i18n.t('SMTH_WENT_WRONG', {link: envs.PATIENT_CONTACT_SUPPORT_URL}),
      },
    ]),
  );
};
