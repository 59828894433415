import {StorageBiomarker} from '@api';
import {Dialog, DialogTitle} from '@components';
import {Box, debounce, Stack, Typography} from '@mui/material';
import {AttachedFile} from '@src/components/AttachedFile';
import {Biomarker} from '@src/components/EditDocumentDialog/components/Biomarker';
import {MAX_FILE_SIZE_BYTES} from '@src/components/EditDocumentDialog/constants';
import {VisuallyHiddenInput} from '@src/components/FileUploader/FileUploader';
import {FormDateInput} from '@src/components/FormDateInput';
import {FormInputControl} from '@src/components/FormInputControl';
import {FormMultiSelect} from '@src/components/FormMultiSelect';
import {FormTextareaControl} from '@src/components/FormTextareaControl';
import {MobileInteractionView} from '@src/components/MobileInteractionView';
import {useTranslate} from '@src/i18n/useTranslate';
import {DEFAULT_PER_PAGE} from '@src/pages/Storage/constants';
import {ReactComponent as DownloadIcon} from '@src/shared/assets/icons/download.svg';
import {ReactComponent as ErrorIcon} from '@src/shared/assets/icons/error.svg';
import {MAX_STORAGE_FILE_NAME_LENGTH} from '@src/shared/constants/formFields';
import {useMQuery} from '@src/shared/hooks';
import {getFileNameAndExtension} from '@src/shared/utils';
import {fetchHealthCases} from '@src/store/healthCases/slice';
import {useAppDispatch, useAppSelector} from '@store';
import {isEqual} from 'lodash-es';
import {ChangeEvent, FC, useEffect, useMemo, useState} from 'react';
import {FormProvider, useFieldArray, useForm} from 'react-hook-form';
import {Button} from 'ui-kit';

import {FILES_ACCEPT, INITIAL_BIOMARKER, MAX_DESCRIPTION_LENGTH} from './constants';
import {getBiomarkersFromManualResult, getDefaultValues} from './helpers';
import {useResultValidationSchema} from './hooks';
import {sx} from './styles';
import {EditResultDialogProps, DialogFile, EditResultDialogForm} from './types';

export const EditResultDialog: FC<EditResultDialogProps> = ({
  isOpen,
  onClose,
  manualResult,
  recognize,
  onSave,
}) => {
  const {mobile: isMobile} = useMQuery();
  const dispatch = useAppDispatch();
  const {t} = useTranslate('results');
  const [file, setFile] = useState<DialogFile | null>(null);
  const [errorType, setErrorType] = useState(false);
  const [errorSize, setErrorSize] = useState(false);

  const isEdit = !!manualResult;

  const {cases, isLoading} = useAppSelector((state) => state.healthCases);

  const resolver = useResultValidationSchema();
  const methods = useForm<EditResultDialogForm>({
    defaultValues: getDefaultValues(recognize, manualResult),
    resolver,
  });

  const [searchValueHealthCase, setSearchValueHealthCase] = useState('');

  const handleChangeSearchValue = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchValueHealthCase(value);
  };

  const handleClearSearchValue = () => {
    setSearchValueHealthCase('');
  };

  const handleDeleteFile = () => {
    setFile(null);
    methods.setValue('isFileAdded', false);
  };

  const handleUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files?.length) {
      return;
    }

    setErrorType(false);
    setErrorSize(false);
    const filesArray = Array.from(files);
    const firstFile = filesArray[0];

    const {name, extension} = getFileNameAndExtension(firstFile.name);
    const validExtensions = FILES_ACCEPT.replace(/\./g, '')
      .split(', ')
      .map((ext) => ext.trim());

    let localErrorType = false;
    let localErrorSize = false;

    if (!validExtensions.includes(extension.toLowerCase())) {
      localErrorType = true;
      setErrorType(true);
    }

    if (firstFile.size > MAX_FILE_SIZE_BYTES) {
      localErrorSize = true;
      setErrorSize(true);
    }

    if (localErrorType || localErrorSize) {
      setFile(null);
      return;
    }

    setFile({
      name,
      size: firstFile.size,
      type: extension,
      error: localErrorSize,
      file: firstFile,
    });

    methods.setValue('isFileAdded', true);

    if (event.target.value) {
      event.target.value = '';
    }
  };

  const handleClose = () => {
    onClose();
    methods.reset({
      ...getDefaultValues(recognize, manualResult),
      editBiomarkers: [],
    });
    setErrorType(false);
    setErrorSize(false);
    setFile(null);
  };

  const handleSubmit = async (data: EditResultDialogForm) => {
    await onSave({
      name: data.name,
      date: data.date,
      editBiomarkers:
        !recognize
          ? data.editBiomarkers?.map(({initialId, biomarkerId, biomarkerUnitId, value}) => ({
            id: initialId as string,
            biomarkerId,
            biomarkerUnitId,
            value,
          }))
          : undefined,
      createBiomarkers:
        !recognize
          ? data.createBiomarkers?.map(({biomarkerId, biomarkerUnitId, value}) => ({
            biomarkerId,
            biomarkerUnitId,
            value,
          }))
          : undefined,
      description: data.description,
      healthCaseIds: data.healthCaseIds,
      recognize,
    }, file?.file);
  };

  const {
    fields: createdBiomarkers,
    remove: removeCreatedBiomarker,
    append: appendCreatedBiomarker,
  } = useFieldArray({name: 'createBiomarkers', control: methods.control});

  const {fields: editedBiomarkers, remove: removeEditedBiomarker} = useFieldArray({
    name: 'editBiomarkers',
    control: methods.control,
  });

  const formattedBiomarkers = useMemo(() => {
    const biomarkersByKey: Record<string, StorageBiomarker> = {};

    if (!manualResult?.storageBiomarkers) {
      return {};
    }

    if (manualResult) {
      const biomarkers = getBiomarkersFromManualResult(manualResult.storageBiomarkers);
      biomarkers.forEach((biomarker: any) => {
        biomarkersByKey[biomarker.id] = biomarker;
      });
    }

    return biomarkersByKey;
  }, [manualResult?.storageBiomarkers]);

  const handleAddBiomarker = () => {
    appendCreatedBiomarker(INITIAL_BIOMARKER);
  };

  const getDisabledAddBiomarker = (createBiomarkers: EditResultDialogForm['createBiomarkers']) => {
    const lastCreatedBiomarker = createBiomarkers?.[createBiomarkers.length - 1];
    if (!lastCreatedBiomarker) {
      return false;
    }

    const {hasUnits, biomarkerUnitId, value, biomarkerId} = lastCreatedBiomarker;

    return hasUnits ? !biomarkerUnitId : !(value && biomarkerId);
  };

  const isSaveDisabled = useMemo(() => {
    const formValues = methods.watch();
    const initialValues = getDefaultValues(recognize, manualResult);

    if (!formValues.createBiomarkers?.length) {
      delete formValues.createBiomarkers;
    }

    if (!manualResult && recognize && (file?.error || !file)) {
      return true;
    }
    if (!isEqual(formValues, initialValues)) {
      return false;
    }

    return true;
  }, [methods.watch(), file, manualResult]);

  const handleFetchHealthCases = debounce((isNext: boolean) => {
    if (isNext && !cases.hasNext && !isLoading) {
      return;
    }
    const {from, to, ...restFilters} = cases.filters;
    void dispatch(
      fetchHealthCases({
        ...restFilters,
        from: from ? from.toISOString().split('T')[0] : undefined,
        to: to ? to.toISOString().split('T')[0] : undefined,
        startPage: isNext ? cases.page : 0,
        perPage: DEFAULT_PER_PAGE,
        isNext,
      }),
    );
  }, 300);

  if (methods.watch('editBiomarkers')?.length === 0 && methods.watch('createBiomarkers')?.length === 0) {
    appendCreatedBiomarker(INITIAL_BIOMARKER);
  }

  useEffect(() => {
    handleFetchHealthCases(false);
  }, []);

  const formComponent = (
    <FormProvider {...methods}>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <Stack component={'form'} sx={sx.formWrapper} onSubmit={methods.handleSubmit(handleSubmit)}>
        <Box sx={sx.contentWrapper}>
          <Stack sx={sx.content}>
            {(manualResult?.hasFile || recognize) && (
              <FormInputControl
                label={t('DOCUMENT_NAME')}
                name={'name'}
                max={MAX_STORAGE_FILE_NAME_LENGTH}
                maxlength={MAX_STORAGE_FILE_NAME_LENGTH}
              />
            )}
            <FormDateInput
              label={t('COLLECTED_DATE')}
              labelTop
              name={'date'}
              placeholder={'mm/dd/yyyy'}
              disabledFuture
            />
            {isEdit && (
              <FormMultiSelect
                optional
                label={t('HEALTH_CASES')}
                placeholder={t('SELECT')}
                name={'healthCaseIds'}
                onChangeSearchValue={handleChangeSearchValue}
                searchValue={searchValueHealthCase}
                onClear={handleClearSearchValue}
                renderValue={(val) =>
                  val.length > 1 ? val[0] + ` +${val.length - 1}` : val[0] || ''
                }
                options={cases.items.map(({name, id: value}) => ({name, value}))}
              />
            )}
            <FormTextareaControl
              name={'description'}
              label={t('DESCRIPTION')}
              max={MAX_DESCRIPTION_LENGTH}
              limit={MAX_DESCRIPTION_LENGTH}
              placeholder={t('YOUR_MESSAGE')}
              optional
            />
            {(isEdit || !recognize) && (
              <>
                <Stack gap={12}>
                  <Typography variant={'14_18_700'}>{t('BIOMARKERS')}</Typography>
                  <Stack gap={12}>
                    {editedBiomarkers.map((biomarker, index) => (
                      <Biomarker
                        key={biomarker.id}
                        biomarker={biomarker}
                        onRemove={() => removeEditedBiomarker(index)}
                        name={`editBiomarkers.${index}`}
                        initialBiomarker={formattedBiomarkers[biomarker.initialId || '']}
                        isEdit={recognize}
                      />
                    ))}
                    {createdBiomarkers.map((biomarker, index) => (
                      <Biomarker
                        key={biomarker.id}
                        biomarker={biomarker}
                        onRemove={() => removeCreatedBiomarker(index)}
                        name={`createBiomarkers.${index}`}
                      />
                    ))}
                  </Stack>
                </Stack>
                {!recognize && (
                  <Box>
                    <Button
                      variant={'outlined'}
                      onClick={handleAddBiomarker}
                      disabled={getDisabledAddBiomarker(methods.watch('createBiomarkers'))}
                      size={'md'}
                      fullWidth={isMobile}
                    >
                      {t('ADD_BIOMARKER')}
                    </Button>
                  </Box>
                )}
              </>
            )}

            {!isEdit && (
              <Stack alignItems={'flex-start'} gap={12}>
                <Stack flexDirection={'row'} gap={12}>
                  <Button
                    startIcon={<DownloadIcon />}
                    variant={'text'}
                    component={'label'}
                    sx={sx.uploadButton}
                    onClick={(e) => e.stopPropagation()}
                    disabled={!!file}
                  >
                    <VisuallyHiddenInput
                      type={'file'}
                      name={'file'}
                      accept={FILES_ACCEPT}
                      onChange={handleUploadFile}
                    />
                    {t('UPLOAD_DOCUMENT')}
                  </Button>
                  {!recognize && (
                    <Typography
                      variant={'14_18_500'}
                      color={'grey.400'}
                      sx={{fontStyle: 'italic'}}
                    >
                      ({t('OPTIONAL')})
                    </Typography>
                  )}
                </Stack>

                <Typography variant={'14_18_500'} color={(theme) => theme.palette.grey['500']}>
                  {t('UPLOAD_TIP')}
                </Typography>
              </Stack>
            )}
            {!isEdit && (errorType || errorSize) && (
              <Stack sx={sx.errorFile}>
                <ErrorIcon />
                <Stack flexDirection={'column'}>
                  <>
                    {errorSize && (
                      <Typography variant="14_18_500">{t('ALLOWED_ATTACHMENT_SIZE')}</Typography>
                    )}
                    {errorType && (
                      <Typography variant="14_18_500">{t('INVALID_FILE_TYPE')}</Typography>
                    )}
                  </>
                </Stack>
              </Stack>
            )}
            {file && !isEdit && (
              <AttachedFile
                name={file.name || file.type}
                ext={file.name ? file.type : ''}
                size={file.size}
                error={file.error}
                onDelete={handleDeleteFile}
                containerSx={sx.file}
              />
            )}
            {file && !recognize && (
              <FormInputControl
                label={t('DOCUMENT_NAME')}
                name={'name'}
                max={MAX_STORAGE_FILE_NAME_LENGTH}
                maxlength={MAX_STORAGE_FILE_NAME_LENGTH}
              />
            )}
          </Stack>
        </Box>

        <Stack sx={sx.actionsContainer}>
          <Button variant={'outlined'} color={'secondary'} onClick={handleClose}>
            {t('CANCEL')}
          </Button>
          <Button color={'secondary'} type={'submit'} disabled={isSaveDisabled}>
            {t('SAVE')}
          </Button>
        </Stack>
      </Stack>
    </FormProvider>
  );

  if (isMobile) {
    return (
      <MobileInteractionView
        isOpen={isOpen}
        title={isEdit ? t('EDIT_RESULT') : t('ADD_RESULT')}
        onBack={onClose}
        onClose={handleClose}
      >
        {formComponent}
      </MobileInteractionView>
    );
  }

  return (
    <Dialog
      open={isOpen}
      size={'lg'}
      scroll={'body'}
      border={'unset'}
      sx={sx.dialog}>
      <DialogTitle onClose={handleClose}>{isEdit ? t('EDIT_RESULT') : t('ADD_RESULT')}</DialogTitle>
      {formComponent}
    </Dialog>
  );
};
