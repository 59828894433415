import {SelectChangeEvent, useTheme, Box, Stack} from '@mui/material';
import {Link, Text, Breadcrumbs} from '@src/components';
import {WhiteBox, BoxBody, BoxPaddings} from '@src/components/WhiteBox';
import {SettingsButton} from '@src/components/fragments/SettingsButton';
import {ResultDynamicsTable} from '@src/features/ResultDynamicsTable';
import {Graphics, Table} from '@src/pages/Dynamics/Board';
import {dataWithoutEmptyValue, getTimePeriod} from '@src/pages/Dynamics/Dynamic.helpers';
import {TIME_PERIOD_OPTIONS} from '@src/pages/Dynamics/Dynamics.constant';
import {RadioButton} from '@src/pages/Dynamics/Dynamics.styled';
import {dateInInterval} from '@src/pages/Results/Results.helpers';
import {ROUTERS_PATH} from '@src/routers';
import {ReactComponent as GraphIcon} from '@src/shared/assets/icons/graph.svg';
import {ReactComponent as TableIcon} from '@src/shared/assets/icons/table.svg';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {spreadSx} from '@src/shared/utils/spreadSx';
import {
  useContactRelations,
  useDynamicsData,
  useRelationDetails,
} from '@src/store/relations/hooks';
import {DynamicTestTransformed} from '@src/store/results/helpers';
import {useTranslate} from 'i18n/useTranslate';
import {FC, useEffect, useMemo, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';

import {Loader} from '../Loader';

import {ResultDynamicsFilters} from './ResultDynamicsFilters/ResultDynamicsFilters';
import {ResultFilterDialog} from './ResultFilterDialog/ResultFilterDialog';
import {initialDates} from './constants';
import {Stripe, sx} from './styles';
import {IFilter, IOptions} from './types';
import {initialFilter} from './utils/initialFilter';
import {getResultTitle} from '../ResultDetails/utils/getResultTitle';

export const ResultDynamics: FC = () => {
  const {id, tests, companyId, resultId} = useParams();
  const {relationId, fetching} = useContactRelations(id);
  const parsedTestCodes: string[] = useMemo(() => (tests ? JSON.parse(tests) : []), [tests]);

  const [timePeriod, setTimePeriod] = useState(TIME_PERIOD_OPTIONS[4].name);
  const {t, ready} = useTranslate('details');
  const initial = initialFilter(tests);
  const [filter, setFilter] = useState(initial);
  const [tempFilter, setTempFilter] = useState(filter);
  const [filterApplied, setFilterApplied] = useState(false);
  const [tempTimePeriod, setTempTimePeriod] = useState(timePeriod);

  const [isGraphics, setIsGraphics] = useState(true);
  const [testsOptions, setTestOptions] = useState<IOptions[]>([]);
  const [dates, setDates] = useState<Pick<IFilter, 'start' | 'end'>>(initialDates);
  const {mobile, tablet, desktop, xl, lg} = useMQuery();
  const theme = useTheme();
  const [filterOpened, setFilterOpened] = useState(false);

  const {result} = useRelationDetails({relationId, resultId});
  const resultTitle = result?.orderDetails ? getResultTitle(result.orderDetails) : '...';

  const handleSetTimePeriod = (newTimePeriod: string) => {
    setTempTimePeriod(newTimePeriod);
  };

  const changeTimePeriod = (event: SelectChangeEvent<string>) => {
    const {value} = event.target;
    handleSetTimePeriod(value);

    const dates = getTimePeriod(value);
    setTempFilter((prev) => ({...prev, ...dates}));
  };

  const changeDisableVariantDynamics = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!(e.target instanceof HTMLElement)) return;
    const {textContent} = e.target;
    setIsGraphics(textContent === 'Graph');
  };

  const {dynamics, isLoading} = useDynamicsData(relationId);
  const [dynamicsWithTests, setDynamicsWithTests] = useState<DynamicTestTransformed[]>([]);

  const [isLocalLoading, setIsLocalLoading] = useState(true);

  const handleSetDynamicsWithTests = (newValue: DynamicTestTransformed[], loading?: boolean) => {
    setDynamicsWithTests(newValue);
    if (loading !== undefined) {
      setIsLocalLoading(loading);
    }
  };

  const {testCodes, start, end} = tempFilter;

  useEffect(() => {
    if (!dynamics.testResults.length) return;

    const optionsT = dynamics.testResults.map((item) => ({
      name: item.testName as string,
      value: item.loinc as string,
    }));

    setTestOptions(optionsT);

    const result = dataWithoutEmptyValue(dynamics.testResults, parsedTestCodes);
    handleSetDynamicsWithTests(result, false);
  }, [dynamics?.testResults, parsedTestCodes, filter]);

  useEffect(() => {
    if (isLoading) {
      setIsLocalLoading(true);
    }
  }, [isLoading]);

  const refToDynamicsWithTests = useRef(dynamicsWithTests);
  refToDynamicsWithTests.current = dynamicsWithTests;

  const changeTestCodes = (values: string[]) => {
    setTempFilter((prev) => ({
      ...prev,
      testCodes: values,
    }));
  };

  useEffect(() => {
    if (filterApplied) {
      const {start, end, testCodes} = filter;

      const filteredDynamicsByTests = dynamics.testResults.filter((item) => doFiltration(item));
      const clearedFilteredDynamics = dataWithoutEmptyValue(filteredDynamicsByTests, testCodes);

      if (!end || !start) {
        handleSetDynamicsWithTests(clearedFilteredDynamics);
        return;
      }

      const dataFil =
        clearedFilteredDynamics.map((dataItem) => ({
          ...dataItem,
          resultLaboratoryAnalyses: dataItem?.resultLaboratoryAnalyses?.filter(
            (column) =>
              dateInInterval(start, end, column.biomaterialSamplingDate) &&
              column.value !== null &&
              column.value !== undefined,
          ),
        })) ?? [];

      handleSetDynamicsWithTests(dataFil.filter((it) => it.resultLaboratoryAnalyses.length > 0));
    }
  }, [filter, filterApplied, dynamics?.testResults]);

  const doFiltration = (item: DynamicTestTransformed) => {
    return testCodes.length > 0 ? testCodes.includes(item.loinc || '') : true;
  };

  const setTestWithFilter = () => {
    if (!dynamics.testResults.length) {
      return;
    }
    const filtered1 = dynamics.testResults?.filter((item) => doFiltration(item));
    const filtered2 = dataWithoutEmptyValue(filtered1, testCodes);
    handleSetDynamicsWithTests(filtered2);
  };

  const handleClear = () => {
    setTempFilter(initialFilter());
    setTempTimePeriod(TIME_PERIOD_OPTIONS[4].name);
    setDates(initialDates);
  };

  const handleChangeDate = (name: string) => (date: Date | null) => {
    setTempFilter((prevState) => ({...prevState, [name]: date}));
    setTimePeriod('custom');
  };

  const handleApply = () => {
    setFilter(tempFilter);
    setTimePeriod(tempTimePeriod);
    setFilterApplied(true);
    setTestWithFilter();
    setFilterOpened(false);
  };

  const handleCloseFilterDialog = () => {
    if (!filterApplied) {
      setTempFilter(filter);
      setTempTimePeriod(timePeriod);
    }
    setFilterOpened(false);
  };

  const clearDate = () => {
    setDates(initialDates);
  };

  const inputTitlePosition = xl ? 'flex-start' : 'center';

  useEffect(() => {
    if (desktop) {
      setTestWithFilter();
    }
  }, [desktop]);

  useEffect(() => {
    if (!filterOpened) {
      handleApply();
    }
  }, [tempFilter]);

  const handleOpenFilterClick = () => {
    setFilterApplied(false);
    setFilterOpened(true);
  };

  const renderTable = () => {
    if (!mobile && !tablet) {
      return (
        <ResultDynamicsTable dynamicsWithTests={dynamicsWithTests} isLoading={isLocalLoading} />
      );
    } else {
      return (
        <Table
          start={start}
          end={end}
          onlySemanticBlock={false}
          data={dynamicsWithTests}
          isLoading={isLocalLoading}
          isConnections
        />
      );
    }
  };

  const isGeneralLoading = isLocalLoading || fetching || isLoading;

  if (isGeneralLoading || !dynamics || !ready) return <Loader />;

  return (
    <Box sx={sx.mainContainer}>
      <Stack sx={sx.container}>
        <Stack sx={sx.topContainer}>
          <Breadcrumbs>
            <Link to={`${ROUTERS_PATH.CONNECTIONS_RELATIONS}/relation/${id ?? ''}/results`}>
              {t('RESULTS')}
            </Link>
            <Link
              to={`${ROUTERS_PATH.CONNECTIONS_RELATIONS}/relation/${id ?? ''}/${resultId ?? ''}/${companyId ?? ''
              }/results`}
            >
              {resultTitle}
            </Link>
            <Text sx={{color: 'grey.300'}}>{t('DYNAMICS_OF_RESULTS')}</Text>
          </Breadcrumbs>
          {!lg && !(mobile || tablet) && (
            <Stripe>
              <RadioButton
                size='lg'
                active={isGraphics}
                endIcon={
                  <GraphIcon
                    fill={isGraphics ? theme.palette.common.white : theme.palette.secondary.main}
                  />
                }
                sx={[...spreadSx(sx.radioBtn), {
                  '& svg path': {
                    fill: isGraphics
                      ? `${theme.palette.common.white}!important`
                      : `${theme.palette.secondary.main}!important`,
                  },
                }]}
                onClick={changeDisableVariantDynamics}
              >
                {t('GRAPH')}
              </RadioButton>
              <RadioButton
                size='lg'
                active={!isGraphics}
                endIcon={
                  <TableIcon
                    fill={isGraphics ? theme.palette.secondary.main : theme.palette.common.white}
                  />
                }
                sx={[...spreadSx(sx.radioBtn), {
                  '& svg path': {
                    fill: isGraphics
                      ? `${theme.palette.secondary.main}!important`
                      : `${theme.palette.common.white}!important`,
                  },
                }]}
                onClick={changeDisableVariantDynamics}
              >
                {t('TABLE')}
              </RadioButton>
            </Stripe>
          )}
        </Stack>
        {(tablet || mobile) && (
          <Stack
            sx={{
              ...sx.mobileNavigationPanel,
              padding: mobile ? '0px' : '0 18px',
              gap: 24,
            }}
          >
            <Stack flexDirection={'row'} minWidth={236}>
              <RadioButton
                size='lg'
                active={isGraphics}
                endIcon={
                  <GraphIcon
                    fill={isGraphics ? theme.colors.all.white : theme.palette.secondary.main}
                  />
                }
                sx={[...spreadSx(sx.radioBtn), {
                  '& svg path': {
                    fill: isGraphics
                      ? `${theme.palette.common.white}!important`
                      : `${theme.palette.secondary.main}!important`,
                  },
                }]}
                onClick={changeDisableVariantDynamics}
              >
                {t('GRAPH')}
              </RadioButton>
              <RadioButton
                size='lg'
                active={!isGraphics}
                endIcon={
                  <TableIcon
                    fill={isGraphics ? theme.palette.secondary.main : theme.colors.all.white}
                  />
                }
                sx={[...spreadSx(sx.radioBtn), {
                  '& svg path': {
                    fill: isGraphics
                      ? `${theme.palette.secondary.main}!important`
                      : `${theme.palette.common.white}!important`,
                  },
                }]}
                onClick={changeDisableVariantDynamics}
              >
                {t('TABLE')}
              </RadioButton>
            </Stack>
            {(mobile || tablet) && <SettingsButton onClick={handleOpenFilterClick} />}
          </Stack>
        )}
      </Stack>
      <WhiteBox noPaddings noBorder>
        <BoxBody>
          {desktop && lg && (
            <Stack sx={sx.desktopNavigationPanel}>
              <Stack flexDirection={'row'}>
                <RadioButton
                  size={'lg'}
                  active={isGraphics}
                  endIcon={
                    <GraphIcon
                      fill={isGraphics ? theme.palette.common.white : theme.palette.secondary.main}
                    />
                  }
                  sx={[...spreadSx(sx.radioBtn), {
                    '& svg path': {
                      fill: isGraphics
                        ? `${theme.palette.common.white}!important`
                        : `${theme.palette.secondary.main}!important`,
                    },
                  }]}
                  onClick={changeDisableVariantDynamics}
                >
                  {t('GRAPH')}
                </RadioButton>
                <RadioButton
                  active={!isGraphics}
                  size={'lg'}
                  endIcon={
                    <TableIcon
                      fill={isGraphics ? theme.palette.secondary.main : theme.palette.common.white}
                    />
                  }
                  sx={[...spreadSx(sx.radioBtn), {
                    '& svg path': {
                      fill: isGraphics
                        ? `${theme.palette.secondary.main}!important`
                        : `${theme.palette.common.white}!important`,
                    },
                  }]}
                  onClick={changeDisableVariantDynamics}
                >
                  {t('TABLE')}
                </RadioButton>
              </Stack>
              <SettingsButton onClick={handleOpenFilterClick} />
            </Stack>
          )}
          {!lg && !(mobile || tablet) && (
            <ResultDynamicsFilters
              onApply={handleApply}
              onClear={handleClear}
              timePeriod={timePeriod}
              changeTimePeriod={changeTimePeriod}
              start={start}
              end={end}
              handleChangeDate={handleChangeDate}
              clearDate={clearDate}
              inputTitlePosition={inputTitlePosition}
              selectedTests={filter.testCodes}
              testsOptions={testsOptions}
              changeTestCodes={changeTestCodes}
            />
          )}
          <Box>
            {isGraphics
              ? (
                <BoxPaddings paddings={'0px'}>
                  <Graphics data={dynamicsWithTests} isLoading={isLocalLoading} isConnections />
                </BoxPaddings>
              )
              : (
                renderTable()
              )}
          </Box>
        </BoxBody>
      </WhiteBox>
      <ResultFilterDialog
        filterOpened={filterOpened}
        filter={tempFilter}
        onApply={handleApply}
        onClear={handleClear}
        timePeriod={tempTimePeriod}
        changeTimePeriod={changeTimePeriod}
        handleChangeDate={handleChangeDate}
        clearDate={clearDate}
        inputTitlePosition={inputTitlePosition}
        testsOptions={testsOptions}
        changeTestCodes={changeTestCodes}
        onClose={handleCloseFilterDialog}
      />
    </Box>
  );
};
