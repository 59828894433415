import {BirthSex, SexualOrientation, StateType, StorageFileCategory} from '@src/api';
import {EthnicityType} from '@src/api/accesses';
import {GenderType, RaceType, RequestRelationType} from '@src/api/relations';
import {TKeys} from '@src/i18n/useTranslate';
import {RelationshipTypes} from '@src/types';

export const STATES_TYPE_MAP: Record<StateType, TKeys<'enums'>> = {
  AK: 'STATE_AK',
  AL: 'STATE_AL',
  AR: 'STATE_AR',
  AS: 'STATE_AS',
  AZ: 'STATE_AZ',
  CA: 'STATE_CA',
  CO: 'STATE_CO',
  CT: 'STATE_CT',
  DC: 'STATE_DC',
  DE: 'STATE_DE',
  FL: 'STATE_FL',
  FM: 'STATE_FM',
  GA: 'STATE_GA',
  GU: 'STATE_GU',
  HI: 'STATE_HI',
  IA: 'STATE_IA',
  ID: 'STATE_ID',
  IL: 'STATE_IL',
  IN: 'STATE_IN',
  KS: 'STATE_KS',
  KY: 'STATE_KY',
  LA: 'STATE_LA',
  MA: 'STATE_MA',
  MD: 'STATE_MD',
  ME: 'STATE_ME',
  MH: 'STATE_MH',
  MI: 'STATE_MI',
  MN: 'STATE_MN',
  MO: 'STATE_MO',
  MP: 'STATE_MP',
  MS: 'STATE_MS',
  MT: 'STATE_MT',
  NC: 'STATE_NC',
  ND: 'STATE_ND',
  NE: 'STATE_NE',
  NH: 'STATE_NH',
  NJ: 'STATE_NJ',
  NM: 'STATE_NM',
  NV: 'STATE_NV',
  NY: 'STATE_NY',
  OH: 'STATE_OH',
  OK: 'STATE_OK',
  OR: 'STATE_OR',
  PA: 'STATE_PA',
  PR: 'STATE_PR',
  PW: 'STATE_PW',
  RI: 'STATE_RI',
  SC: 'STATE_SC',
  SD: 'STATE_SD',
  TN: 'STATE_TN',
  TX: 'STATE_TX',
  UT: 'STATE_UT',
  VA: 'STATE_VA',
  VI: 'STATE_VI',
  VT: 'STATE_VT',
  WA: 'STATE_WA',
  WI: 'STATE_WI',
  WV: 'STATE_WV',
  WY: 'STATE_WY',
  ZZ: 'STATE_ZZ',
};

export const BIRTH_SEX_TYPE_MAP: Record<BirthSex, TKeys<'enums'>> = {
  MALE: 'BIRTH_SEX_MALE',
  FEMALE: 'BIRTH_SEX_FEMALE',
  UNKNOWN: 'BIRTH_SEX_UNKNOWN',
};

export const RELATIONSHIP_TYPE_MAP: Record<RelationshipTypes, TKeys<'enums'>> = {
  SELF: 'RELATIONSHIP_TYPE_SELF',
  CHD: 'RELATIONSHIP_TYPE_CHD',
  EMC: 'RELATIONSHIP_TYPE_EMC',
  FTH: 'RELATIONSHIP_TYPE_FTH',
  GCH: 'RELATIONSHIP_TYPE_GCH',
  GRP: 'RELATIONSHIP_TYPE_GRP',
  MTH: 'RELATIONSHIP_TYPE_MTH',
  OTH: 'RELATIONSHIP_TYPE_OTH',
  SPO: 'RELATIONSHIP_TYPE_SPO',
  HUSB: 'RELATIONSHIP_TYPE_HUSB',
  WIFE: 'RELATIONSHIP_TYPE_WIFE',
  WRD: 'RELATIONSHIP_TYPE_WRD',
};

export const REQUEST_RELATION_TYPE_MAP: Record<RequestRelationType, TKeys<'enums'>> = {
  CHD: 'RELATIONSHIP_TYPE_CHD',
  DEP: 'RELATIONSHIP_TYPE_DEP',
  FCH: 'RELATIONSHIP_TYPE_FCH',
  FTH: 'RELATIONSHIP_TYPE_FTH',
  GCH: 'RELATIONSHIP_TYPE_GCH',
  GRP: 'RELATIONSHIP_TYPE_GRP',
  MTH: 'RELATIONSHIP_TYPE_MTH',
  SPO: 'RELATIONSHIP_TYPE_SPO',
  HUSB: 'RELATIONSHIP_TYPE_HUSB',
  WIFE: 'RELATIONSHIP_TYPE_WIFE',
};

export const RACE_TYPE_MAP: Record<RaceType, TKeys<'enums'>> = {
  AMERICAN_INDIAN_OR_ALASKA_NATIVE: 'RACE_AMERICAN_INDIAN_OR_ALASKA_NATIVE',
  ASIAN: 'RACE_ASIAN',
  BLACK_OR_AFRICAN_AMERICAN: 'RACE_BLACK_OR_AFRICAN_AMERICAN',
  NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER: 'RACE_NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER',
  WHITE: 'RACE_WHITE',
  OTHER: 'RACE_OTHER',
  ASKU: 'RACE_ASKU',
  UNKNOWN: 'RACE_UNKNOWN',
};

export const GENDER_TYPE_MAP: Record<GenderType, TKeys<'enums'>> = {
  MALE: 'GENDER_MALE',
  FEMALE: 'GENDER_FEMALE',
  FEMALE_TO_MALE: 'GENDER_FEMALE_TO_MALE',
  MALE_TO_FEMALE: 'GENDER_MALE_TO_FEMALE',
  GENDERQUEER: 'GENDER_GENDERQUEER',
  OTH: 'GENDER_OTH',
  ASKU: 'GENDER_ASKU',
};

export const ETHNICITY_TYPE_MAP: Record<EthnicityType, TKeys<'enums'>> = {
  HISPANIC_OR_LATINO: 'ETHNICITY_HISPANIC_OR_LATINO',
  NOT_HISPANIC_OR_LATINO: 'ETHNICITY_NOT_HISPANIC_OR_LATINO',
  OTH: 'ETHNICITY_OTH',
  UNK: 'ETHNICITY_UNK',
  ASKU: 'ETHNICITY_ASKU',
};

export const SEXYAL_ORIENTATION_TYPE_MAP: Record<SexualOrientation, TKeys<'enums'>> = {
  LESBIAN_GAY_HOMOSEXUAL: 'SEXYAL_ORIENTATION_LESBIAN_GAY_HOMOSEXUAL',
  STRAIGHT_HETEROSEXUAL: 'SEXYAL_ORIENTATION_STRAIGHT_HETEROSEXUAL',
  BISEXUAL: 'SEXYAL_ORIENTATION_BISEXUAL',
  OTH: 'SEXYAL_ORIENTATION_OTH',
  UNK: 'SEXYAL_ORIENTATION_UNK',
  ASKU: 'SEXYAL_ORIENTATION_ASKU',
};

export const STORAGE_CATEGORY_TYPE_MAP: Record<StorageFileCategory, TKeys<'enums'>> = {
  LABORATORY_REPORT: 'STORAGE_CATEGORY_LABORATORY_REPORT',
  MEDICAL_RECORD: 'STORAGE_CATEGORY_MEDICAL_RECORD',
  IMAGING_REPORT: 'STORAGE_CATEGORY_IMAGING_REPORT',
  MEDICAL_IMAGE: 'STORAGE_CATEGORY_MEDICAL_IMAGE',
  PRESCRIPTION: 'STORAGE_CATEGORY_PRESCRIPTION',
  MISCELLANEOUS: 'STORAGE_CATEGORY_MISCELLANEOUS',
};
