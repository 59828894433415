/* eslint-disable no-comments/disallowComments */
import {yupResolver} from '@hookform/resolvers/yup';
import {EditResultDialogForm} from '@src/components/EditResultDialog/types';
import {useTranslate} from '@src/i18n/useTranslate';
import {array, object, string, TestContext} from 'yup';
import {AnyObject} from 'yup/lib/types';

const recognizeTest = (value: string | undefined, context: TestContext<AnyObject>) => {
  // @ts-expect-error
  const root = context.from[1].value;
  const recognize = root?.recognize as EditResultDialogForm['recognize'];
  return recognize ? true : !!value;
};

export const useResultValidationSchema = () => {
  const {t} = useTranslate('results');

  return yupResolver(
    object({
      // @ts-expect-error
      name: string().when(['recognize', 'isFileAdded'], (recognize: boolean, isFileAdded: boolean, schema) => {
        if (recognize) {
          return schema.required(t('REQUIRED_FIELD'));
        } else {
          return isFileAdded ? schema.required(t('REQUIRED_FIELD')) : schema.nullable().notRequired();
        }
      }),
      date: string().required(t('REQUIRED_FIELD')),
      description: string().nullable(),
      createBiomarkers: array().of(
        object().shape({
          biomarkerId: string()
            .test('unique', t('REQUIRED_FIELD'), (value, context) => {
              // @ts-expect-error
              const root = context.from[1].value;

              const editBiomarkers = root?.editBiomarkers as EditResultDialogForm['editBiomarkers'];
              const createBiomarkers =
                root?.createBiomarkers as EditResultDialogForm['createBiomarkers'];

              const createBiomarkersIds = createBiomarkers?.map((item) => item.biomarkerId) || [];
              const editBiomarkersIds = editBiomarkers?.map((item) => item.biomarkerId) || [];

              const combinedBiomarkersIds = [...createBiomarkersIds, ...editBiomarkersIds];
              const duplicateCount = combinedBiomarkersIds.filter((id) => id === value).length;

              return duplicateCount === 1;
            }).test('recognizeTest', t('REQUIRED_FIELD'), recognizeTest),
          biomarkerUnitId: string().when('hasUnits', {
            is: true,
            then: string().test('recognizeTest', t('REQUIRED_FIELD'), recognizeTest),
            otherwise: string().notRequired(),
          }),
          value: string().test('recognizeTest', t('REQUIRED_FIELD'), recognizeTest),
        }),
      ),
      editBiomarkers: array().of(
        object().shape({
          biomarkerId: string().required(),
          biomarkerUnitId: string().when('hasUnits', {
            is: true,
            then: string().required(),
            otherwise: string().notRequired(),
          }),
          value: string().required(),
        }),
      ),
    }),
  );
};
