import {Stack, SvgIcon, Typography} from '@mui/material';
import {Box} from '@mui/system';
import {Product} from '@src/api';
import {EllipsisTextTooltip} from '@src/components/EllipsisTextTooltip';
import {Checkbox} from '@src/components/form/CheckboxControl';
import {useTranslate} from '@src/i18n/useTranslate';
import {getReferenceText, getResultColor} from '@src/pages/Details/helpers';
import {ReactComponent as ArrowIcon} from '@src/shared/assets/icons/arrow-down.svg';
import {Table as NewTable} from '@src/shared/ui/Table/Table';
import {ColumnDef, Table as TanstackTable} from '@tanstack/react-table';
import {ChangeEvent, FC, useMemo} from 'react';

import {sx} from './styles';

interface ResultDetailsTableProps {
  data: Product[]
  handleToggleAllRowsSelection: (table: TanstackTable<Product>, checked: boolean) => void
  handleToggleRowSelection: (row: Product, checked: boolean) => void
}

export const ResultDetailsTable: FC<ResultDetailsTableProps> = ({
  data,
  handleToggleAllRowsSelection,
  handleToggleRowSelection,
}) => {
  const {t} = useTranslate('details');

  const columns = useMemo<Array<ColumnDef<any>>>(
    () => [
      {
        accessorKey: 'name',
        minSize: 400,
        maxSize: 400,
        header: ({table}) => {
          return (
            <>
              <Checkbox
                sx={{mr: 0}}
                {...{
                  checked: table.getIsAllRowsSelected(),
                  onChange: (e: ChangeEvent<HTMLInputElement>) =>
                    handleToggleAllRowsSelection(table, e.target.checked),
                }}
              />{' '}
              {t('TEST_PANEL')}
            </>
          );
        },
        cell: ({row, getValue}) => {
          const isTest = !row.subRows.length;
          const leftPaddingsByDepth = [4, 38];
          return (
            <Stack
              style={{
                paddingLeft: isTest ? '102px' : `${leftPaddingsByDepth[row.depth] || 0}px`,
                justifyContent: 'center',
              }}
            >
              <Stack
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                {row.getCanExpand()
                  ? (
                    <SvgIcon
                      sx={{
                        transform: row.getIsExpanded() ? 'rotate(0deg)' : 'rotate(-90deg)',
                        fontSize: '20px',
                        marginRight: '11px',
                        color: '#C7CDD6',
                      }}
                      {...{
                        onClick: row.getToggleExpandedHandler(),
                        style: {cursor: 'pointer'},
                      }}
                    >
                      {row.getIsExpanded() ? <ArrowIcon /> : <ArrowIcon />}
                    </SvgIcon>
                  )
                  : (
                    ''
                  )}{' '}
                <Checkbox
                  sx={{mr: 0}}
                  {...{
                    checked: row.getIsSelected(),
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                      row.getToggleSelectedHandler()(e);
                      handleToggleRowSelection(row.original, e.target.checked);
                    },
                  }}
                />{' '}
                {getValue<boolean>()}
              </Stack>
            </Stack>
          );
        },
        enableSorting: false,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'value',
        id: 'Results',
        cell: (info) => {
          const {row} = info;
          const value = row.original.value;
          const resultColor = getResultColor(row.original);
          const fontVariant = resultColor ? '14_18_700' : '14_18_500';

          if (row.subRows.length) {
            return null;
          }

          return (
            <Typography
              variant={fontVariant}
              sx={{
                color: resultColor,
                paddingTop: 5,
              }}
            >
              {value || '--'}
            </Typography>
          );
        },
        header: () => <Box>{t('RESULTS')}</Box>,
        footer: (props) => props.column.id,
        minSize: 550,
        enableSorting: false,
      },
      {
        accessorKey: 'units',
        id: 'units',
        minSize: 150,
        maxSize: 150,
        cell: (info) => {
          const {row} = info;
          if (row.subRows.length) {
            return null;
          }
          return (
            <Typography variant="14_18_500" pt={5}>
              {row.original.units}
            </Typography>
          );
        },
        header: () => <Box>{t('UNITS')}</Box>,
        footer: (props) => props.column.id,
        enableSorting: false,
      },
      {
        accessorKey: 'comment',
        id: 'comment',
        minSize: 250,
        cell: (info) => {
          const {row} = info;
          if (row.subRows.length) {
            return null;
          }
          return (
            <EllipsisTextTooltip
              ellipsisText={row.original.comment || t('NO_COMMENTS')}
              heightCheck
              variant={'14_18_500'}
              sx={sx.commentText}
            />
          );
        },
        header: () => <Box>{t('COMMENTS')}</Box>,
        footer: (props) => props.column.id,
        enableSorting: false,
      },
      {
        accessorKey: 'reference',
        id: 'reference',
        minSize: 200,
        maxSize: 200,
        enableSorting: false,
        header: () => <Box>{t('REFERENCE_INTERVAL')}</Box>,
        cell: (info) => {
          const {row} = info;
          if (row.subRows.length) {
            return null;
          }
          return <Box sx={{whiteSpace: 'pre-wrap'}}>{getReferenceText(row.original, t)}</Box>;
        },
      },
    ],
    [],
  );

  const isError = (row: any) => {
    return row.criticalReference?.isOutOfRef;
  };

  return (
    <NewTable
      columns={columns}
      data={data}
      heightColumns="auto"
      isError={isError} />
  );
};
