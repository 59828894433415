import {Dialog, DialogTitle} from '@components';
import {Stack, Typography} from '@mui/material';
import {useTranslate} from '@src/i18n/useTranslate';
import React, {FC, useState} from 'react';
import {Button} from 'ui-kit';
import {DeleteIcon} from 'ui-kit/lib/icons/24';

import {
  DeleteManualResultButtonProps,
} from './types';

export const DeleteManualResultButton: FC<DeleteManualResultButtonProps> = ({onDelete}) => {
  const {t} = useTranslate('details');
  const [isOpen, setIsOpen] = useState(false);
  const handleToggleOpen = () => setIsOpen(prev => !prev);

  const handleDelete = () => {
    onDelete();
    handleToggleOpen();
  };

  return (
    <>
      <Button
        startIcon={<DeleteIcon />}
        variant={'outlined'}
        color={'secondary'}
        fullWidth
        onClick={handleToggleOpen}
      >
        {t('DELETE')}
      </Button>
      <Dialog open={isOpen} onClose={handleToggleOpen} size={'sm'}>
        <DialogTitle onClose={handleToggleOpen}>{t('DELETE_RESULT')}</DialogTitle>
        <Stack>
          <Typography variant={'16_24_500'} p={'12px 24px'} mt={12}>{t('DELETE_RESULT_CONFIRM')}</Typography>
          <Stack flexDirection={'row'} justifyContent={'space-between'} p={24}>
            <Button
              variant={'outlined'}
              color={'secondary'}
              onClick={handleToggleOpen}
            >
              {t('CANCEL')}
            </Button>
            <Button
              color={'secondary'}
              onClick={handleDelete}
            >
              {t('DELETE')}
            </Button>
          </Stack>
        </Stack>
      </Dialog>
    </>
  );
};
