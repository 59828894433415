import {Box, SxProps, Theme, styled} from '@mui/material';

export const sx = {
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: (theme) => theme.pageTitleHeight,
  },
  emptyWrapper: {
    display: 'flex',
    flexGrow: 1,
    gap: 24,
  },
  contentHealthCaseSelector: {
    flexDirection: 'row',
    gap: 8,
    alignItems: 'center',
  },
  tableWrapper: (t) => ({
    overflow: 'auto',
    height: 'calc(100vh - 288px)',
    [t.breakpoints.down('sm')]: {
      height: 'calc(100vh - 276px)',
    },
  }),
  fixedButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    padding: 18,
    background: t => t.colors.all.white,
    gap: 12,
  },
  caseSelectorWrapper: {
    flexDirection: 'row',
    gap: 8,
    alignItems: 'center',
  },
} satisfies Record<string, SxProps<Theme>>;

export const Cell = styled(Box)(() => ({
  alignItems: 'flex-start',
  display: 'flex',
}));

export const MainRow = styled(Box)(({theme}) => ({
  display: 'grid',
  alignContent: 'center',
  minHeight: '48px',
  boxSizing: 'border-box',
  columnGap: '24px',
  paddingBottom: '0px',
  cursor: 'pointer',
  '& > div': {
    alignItems: 'center',
  },
  [theme.breakpoints.down('md')]: {
    columnGap: '12px',
    minHeight: 'initial',
    '& > div': {
      alignSelf: 'start',
    },
  },
}));

export const InternalRow = styled(Box)(() => ({
  display: 'grid',
  columnGap: '20px',
  minHeight: '30px',
  padding: '9px 0px 0px',
  boxSizing: 'border-box',
}));

export const ExpandableRowContainer = styled(Box)<{ critical: number, onlySemanticBlock?: boolean }>(
  ({theme, critical, onlySemanticBlock}) => ({
    paddingX: {xs: '12px', sm: '24px', lg: '36px'},
    paddingLeft: onlySemanticBlock ? '36px' : '24px',
    boxSizing: 'border-box',
    cursor: 'initial',
    borderBottom: critical
      ? `1px solid ${theme.palette.primary[300] || ''} !important`
      : `1px solid ${theme.palette.secondary[300] || ''}`,
    backgroundColor: critical ? theme.palette.primary[200] ?? '' : 'initial',
    '&:last-child': {
      borderBottom: 'none',
    },
    [theme.breakpoints.down('md')]: {
      padding: '12px',
    },
    [theme.breakpoints.only('md')]: {
      paddingLeft: '24px',
      paddingRight: '24px',
    },
    [theme.breakpoints.up('sm')]: {
      '&:hover': {
        backgroundColor: critical ? theme.palette.primary[200] : theme.palette.secondary[200],
      },
      '&:active': {
        backgroundColor: critical ? theme.palette.primary[200] : theme.palette.secondary[300],
      },
    },
  }),
);

ExpandableRowContainer.defaultProps = {
  paddingY: 0,
};
