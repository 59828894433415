import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  stackContainer: (t) => ({
    padding: {
      xs: '12px 18px 12px 18px',
      lg: '18px 24px 12px 24px',
    },
    borderTop: '1px solid',
    borderColor: t.palette.secondary[400],
    '&:last-child': {
      borderBottom: '1px solid',
      borderColor: t.palette.secondary[200],
    },
  }),
  requestAvatar: {
    width: 40,
    height: 40,
    marginRight: 10,
    backgroundColor: t => t.palette.secondary[600],
    color: t => t.palette.common.white,
  },
  personType: (t) => ({
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    [t.breakpoints.only('xs')]: {
      typography: '14_18_500',
    },
  }),
  personName: {
    cursor: 'pointer',
    typography: {xs: '18_24_700'},
  },
  wrapper: {
    pb: {
      xs: 12,
      sm: 40,
      lg: 38,
    },
    overflow: 'auto',
  },
  boxContainer: {
    display: 'flex',
    mb: {xs: 10, sm: 16},
    justifyContent: 'space-between',
  },
  widthLimitContainer: {
    minWidth: '10%',
    gap: 4,
  },
  bthContainer: {
    flexDirection: 'row',
    justifyContent: 'end',
    gap: 18,
  },
  rowInformation: {
    mb: {xs: 8, sm: 12},
  },
  emptyRequests: {
    color: t => t.palette.grey[500],
    p: {
      xs: 18,
      sm: '2px 24px',
    },
    typography: {
      xs: '12_16_500',
      sm: '14_18_500',
    },
  },
  date: {
    color: t => t.palette.grey[500],
    typography: {
      xs: '12_16_500',
      sm: '14_18_500',
    },
  },
  bottomContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
};
