import {MenuTypes, StorageFileCreateBody} from '@api';
import {PageHeader as PageTitle} from '@components';
import {Box, Stack, Typography} from '@mui/material';
import {EditDocumentDialog} from '@src/components/EditDocumentDialog';
import {FullContainerLoader} from '@src/components/FullContainerLoader';
import {PulseLoader} from '@src/components/PulseLoader';
import {ShareDocumentsMenu} from '@src/components/ShareDocumentsMenu';
import {HealthCaseSelector} from '@src/features/HealthCaseSelector';
import {useTranslate} from '@src/i18n/useTranslate';
import {ReactComponent as ShareIcon} from '@src/shared/assets/icons/18x18/share.svg';
import {ReactComponent as AddIcon} from '@src/shared/assets/icons/plus.svg';
import {useMQuery, useSidebarClassObserver} from '@src/shared/hooks';
import {healthCasesActions} from '@src/store/healthCases/slice';
import {addStorageFile, fetchStorageFiles, storageActions} from '@src/store/storage/slice';
import {useAppDispatch, useAppSelector} from '@store';
import {useEffect, useState} from 'react';
import {Button, IconButton} from 'ui-kit';

import {DocumentCard} from './components/DocumentCard';
import {EditDocumentDialogWrapper} from './components/EditDocumentDialogWrapper';
import {ErrorDialog} from './components/ErrorDialog';
import {Filters} from './components/Filters';
import {DEFAULT_PER_PAGE} from './constants';
import {MainContainer, MainWrapper, sx} from './styles';

export const Storage = () => {
  const {mobile, tabletPortrait} = useMQuery();
  const {t, ready} = useTranslate('storage');

  const {isSidebarOpen} = useSidebarClassObserver();
  const [editDialogData, setEditDialogData] = useState<{ id: string | null, isOpen: boolean }>({
    id: null,
    isOpen: false,
  });

  const handleOpenEditDialog = (id: string) => {
    setEditDialogData({id, isOpen: true});
  };

  const handleCloseEditDialog = () => {
    setEditDialogData({id: null, isOpen: false});
  };

  const dispatch = useAppDispatch();
  const {files, isLoading} = useAppSelector((state) => state.storage);
  const selectedDocuments = useAppSelector((state) => state.healthCases.selectedDocuments);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isFirstLoading, setIsFirstLoading] = useState(true);

  const [isAddDocumentDialogOpen, setIsAddDocumentDialogOpen] = useState(false);

  const handleToggleAddDocumentDialogOpen = () => {
    setIsAddDocumentDialogOpen(!isAddDocumentDialogOpen);
  };

  const handleScrollListener = (container: HTMLElement) => {
    const scrollTop = container.scrollTop;
    setIsScrolled(scrollTop > 0);
  };

  const handleFetch = (isNext: boolean) => {
    if (isNext && !files.hasNext) {
      return;
    }
    const {from, to, ...restFilters} = files.filters;
    void dispatch(
      fetchStorageFiles({
        ...restFilters,
        from: from ? from.toISOString().split('T')[0] : undefined,
        to: to ? to.toISOString().split('T')[0] : undefined,
        startPage: isNext ? files.page : 0,
        perPage: DEFAULT_PER_PAGE,
        isNext,
      }),
    );
  };

  const {phrase, category, from, to, menu} = files.filters;

  const isRecentOrFavorite = menu === MenuTypes.RECENT || menu === MenuTypes.FAVORITE;

  const isFieldsEmpty = !phrase && !category && !from && !to && !isRecentOrFavorite;

  const displayedMessage = isFieldsEmpty ? t('YOU_DONT_HAVE_DOCS') : t('NO_SUITABLE_RESULTS');

  const getHealthCaseTranslationText = () => {
    const count = selectedDocuments.size;
    if (!count) {
      return t('ADD_TO_HEALTH_CASE_DISABLED');
    }
    return count === 1
      ? t('ADD_TO_HEALTH_CASE_SINGULAR', {count: count || ''})
      : t('ADD_TO_HEALTH_CASE', {count: count || ''});
  };

  const healthCaseTranslationText = getHealthCaseTranslationText();

  useEffect(() => {
    handleFetch(false);
  }, [files.filters]);

  const handleAddNewFile = async (document: StorageFileCreateBody, file?: File) => {
    if (!file) {
      return;
    }
    void dispatch(
      addStorageFile({
        ...document,
        file,
      }),
    )
      .unwrap()
      .then(() => {
        setTimeout(() => {
          dispatch(storageActions.clearIsNew());
        }, 2000);
      })
      .catch(() => {
        dispatch(storageActions.setErrorText(t('DOCUMENT_NOT_SAVED')));
      });
  };

  useEffect(() => {
    return () => {
      dispatch(healthCasesActions.clearSelectedDocuments());
    };
  }, []);

  useEffect(() => {
    if (files.items.length && isFirstLoading) {
      setIsFirstLoading(false);
    }
  }, [files.items]);

  if (!ready || isFirstLoading) {
    return (
      <FullContainerLoader />
    );
  }

  return (
    <>
      <PageTitle
        height={'auto'}
        itemAction={
          mobile
            ? (
              <IconButton
                variant={'text'}
                color={'secondary'}
                sx={sx.iconButton}
                onClick={handleToggleAddDocumentDialogOpen}
              >
                <AddIcon />
              </IconButton>
            )
            : (
              <Stack gap={12} flexDirection={'row'}>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<AddIcon />}
                  onClick={handleToggleAddDocumentDialogOpen}
                >
                  {t('ADD_DOCUMENT')}
                </Button>
                {!tabletPortrait && (
                  <>
                    <HealthCaseSelector>
                      {healthCaseTranslationText}
                    </HealthCaseSelector>
                    <ShareDocumentsMenu documentIds={Array.from(selectedDocuments)}>
                      {(onOpen) => (
                        <Button
                          variant="outlined"
                          color="secondary"
                          startIcon={<ShareIcon />}
                          onClick={onOpen}
                          disabled={!selectedDocuments.size}
                        >
                          {selectedDocuments.size === 1
                            ? t('SHARE_ONE_DOCUMENT')
                            : t('SHARE_DOCUMENTS', {count: selectedDocuments.size || ''})}
                        </Button>
                      )}
                    </ShareDocumentsMenu>
                  </>
                )}
              </Stack>
            )
        }
      >
        {t('STORAGE')}
      </PageTitle>
      <Stack sx={sx.generalContainer}>
        <Filters isSidebarOpen={isSidebarOpen} isScrolled={isScrolled} />
        <MainWrapper
          isScrolled={isScrolled}
          isSidebarOpen={isSidebarOpen}
          onScroll={handleScrollListener}
          onLoad={() => handleFetch(true)}
          threshold={500}
        >
          {(!isLoading || !!files.items.length) && (
            <MainContainer isSidebarOpen={isSidebarOpen}>
              {files.items.map((file) => (
                <DocumentCard key={file.id} file={file} onEdit={handleOpenEditDialog}/>
              ))}
            </MainContainer>
          )}
          {!isLoading && !files.items.length && (
            <Typography sx={sx.emptyText}>
              {displayedMessage}
            </Typography>
          )}
          {isLoading && (
            <Box display={'flex'} justifyContent={'center'} p={36}>
              <PulseLoader loading={true} />
            </Box>
          )}
        </MainWrapper>
      </Stack>
      {(mobile || tabletPortrait) && (
        <Box sx={sx.fixedButtonContainer} zIndex={isSidebarOpen ? 0 : 1030}>
          <HealthCaseSelector externalIsMobile>
            {healthCaseTranslationText}
          </HealthCaseSelector>
          <ShareDocumentsMenu documentIds={Array.from(selectedDocuments)} externalIsMobile>
            {(onOpen) => (
              <Button
                variant="contained"
                color="secondary"
                onClick={onOpen}
                disabled={!selectedDocuments.size}
              >
                {selectedDocuments.size === 1
                  ? t('SHARE_ONE_DOCUMENT')
                  : t('SHARE_DOCUMENTS', {count: selectedDocuments.size || ''})}
              </Button>
            )}
          </ShareDocumentsMenu>
        </Box>
      )}
      <EditDocumentDialog
        isOpen={isAddDocumentDialogOpen}
        onClose={handleToggleAddDocumentDialogOpen}
        onSave={handleAddNewFile}
      />
      <ErrorDialog />
      {editDialogData.isOpen && editDialogData.id && (
        <EditDocumentDialogWrapper
          id={editDialogData.id}
          onClose={handleCloseEditDialog}
        />
      )}
    </>
  );
};
