import {Box, Typography} from '@mui/material';
import {HealthCase} from '@src/api/healthCases';
import {EllipsisTextTooltip} from '@src/components/EllipsisTextTooltip';
import {ROUTERS_PATH} from '@src/routers';
import {useMQuery, useSidebarClassObserver} from '@src/shared/hooks';
import {useWindowWidth} from '@src/shared/hooks/useWindowWidth';
import {Table as NewTable} from '@src/shared/ui/Table/Table';
import {DATE_FORMAT, dateToFormat} from '@src/shared/utils';
import {useAppDispatch, useAppSelector} from '@src/store';
import {healthCasesActions} from '@src/store/healthCases/slice';
import {createColumnHelper} from '@tanstack/react-table';
import {useTranslate} from 'i18n/useTranslate';
import {FC, useEffect, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';

import {BACKGROUND_DELAY} from '../../constants';
import {getTextValue} from '../../utils/getTextValue';

import {HealthCasesTableProps} from './types';

export const HealthCasesTable: FC<HealthCasesTableProps> = ({cases, onFetch, isLoading}) => {
  const {t} = useTranslate('healthCases');
  const columnHelper = createColumnHelper<HealthCase>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {desktop} = useMQuery();
  const windowWidth = useWindowWidth();
  const {isSidebarOpen} = useSidebarClassObserver();
  const widthColumn = desktop ? 1320 : 1420;
  const subtractWidthSidebar = isSidebarOpen && desktop ? 280 : 0;
  const lastCreatedCase = useAppSelector((state) => state.healthCases.lastCreatedCase);

  const physicianColumnWidth = Math.max((widthColumn - subtractWidthSidebar) - (1920 - windowWidth), 150);

  useEffect(() => {
    if (lastCreatedCase) {
      const timer = setTimeout(() => {
        dispatch(healthCasesActions.clearLastCreatedCase());
      }, BACKGROUND_DELAY);

      return () => clearTimeout(timer);
    }
  }, [lastCreatedCase]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('caseDate', {
        id: 'caseDate',
        header: t('DATE'),
        minSize: 150,
        maxSize: 150,
        enableSorting: false,
        cell: ({getValue}) => {
          return <Box>{dateToFormat(DATE_FORMAT, getValue() || '')}</Box>;
        },
      }),
      columnHelper.accessor('name', {
        id: 'name',
        header: t('NAME'),
        minSize: 150,
        maxSize: 150,
        enableSorting: false,
        cell: ({getValue}) => {
          return (
            <EllipsisTextTooltip
              variant='14_18_500'
              ellipsisText={getValue()}
            />
          );
        },
      }),
      columnHelper.accessor('description', {
        id: 'description',
        header: t('DESCRIPTION'),
        minSize: physicianColumnWidth,
        maxSize: physicianColumnWidth,
        enableSorting: false,
        cell: ({getValue}) => {
          return (
            <EllipsisTextTooltip
              variant='14_18_500'
              ellipsisText={getValue()}
            />
          );
        },
      }),
      columnHelper.accessor('objectCount', {
        id: 'objectCount',
        header: t('ATTACHMENTS'),
        minSize: 150,
        maxSize: 150,
        enableSorting: false,
        cell: ({getValue}) => {
          return (
            <Typography
              variant={'14_18_500'}
              color={'grey.400'}
            >{(getTextValue(getValue()))}</Typography>
          );
        },
      }),
    ], [columnHelper, t, physicianColumnWidth]);

  const handleViewPage = ({id}: { id: string }) => {
    return navigate(`${ROUTERS_PATH.HEALTH_CASES}/${id}`);
  };

  const handleFetchCases = () => {
    onFetch(true);
  };
  const isNew = (row: any) => {
    return row.id === lastCreatedCase;
  };

  return (
    <NewTable
      columns={columns}
      data={cases}
      onRowClick={handleViewPage}
      fetchNextPage={handleFetchCases}
      isFetching={isLoading}
      isNew={isNew}
    />
  );
};
