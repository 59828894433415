import {Stack, Typography, Box, Avatar, Theme} from '@mui/material';
import {RelationRequest} from '@src/api/relations';
import {EllipsisTextTooltip} from '@src/components/EllipsisTextTooltip';
import {useTranslate} from '@src/i18n/useTranslate';
import {useSortOfDate} from '@src/shared/hooks';
import {dateFormatted, getUserInitials} from '@src/shared/utils';
import {getName} from '@src/shared/utils/getName';
import {useApproveRelationRequest, useDeclineRelationRequest} from '@src/store/relations/hooks';
import {Link} from 'ui-kit';

import {sx} from './styles';

export const IncomingRequests = ({data}: { data: RelationRequest[] | null }) => {
  const {t} = useTranslate('connections');
  const {approve} = useApproveRelationRequest();
  const {decline} = useDeclineRelationRequest();
  const sortedData = useSortOfDate(data, 'createdDate');

  if (!data?.length) {
    return (
      <Typography
        sx={sx.emptyRequests}
      >
        {t('NO_INCOMING_REQUESTS')}
      </Typography>
    );
  }

  return (
    <Stack sx={sx.container}>
      {sortedData?.map((request) => (
        <Stack
          sx={sx.stackContainer}
          key={request.id}
        >
          <Box
            display="flex"
            mb={2}
            justifyContent={'space-between'}>
            <Stack sx={sx.widthLimitContainer}>
              <EllipsisTextTooltip
                ellipsisText={getName(request?.contact, {short: true})}
                sx={sx.personName}
              />
              <Typography sx={sx.personType}>{t(request?.type)}</Typography>
            </Stack>
            <Stack justifyContent={'end'}>
              <Avatar sx={sx.requestAvatar} src={request?.avatarUrl} alt={''}>
                <Typography variant="14_20_700">{getUserInitials(request?.contact)}</Typography>
              </Avatar>
            </Stack>
          </Box>
          <Stack sx={sx.bottomContainer}>
            <Stack sx={sx.bthContainer}>
              <Link
                size='md'
                color="primary"
                onClick={() => decline({requestId: request?.id})}
              >{t('DECLINE')}</Link>
              <Link
                size='md'
                color="secondary"
                onClick={() => approve({requestId: request?.id})}
              >{t('ACCEPT')}</Link>
            </Stack>
            <Typography sx={sx.date}>
              {dateFormatted(request?.createdDate)}
            </Typography>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};
