import {CircleStatus} from '@components';
import {Box, IconButton, SvgIcon, Typography} from '@mui/material';
import {EllipsisTextTooltip} from '@src/components/EllipsisTextTooltip';
import {ROUTERS_PATH} from '@src/routers';
import {ReactComponent as IconSVGArrowDown} from '@src/shared/assets/icons/chevron-down.svg';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {dateToFormat, DATE_FORMAT, getFullNameWithoutMiddleName} from '@src/shared/utils';
import {useAppDispatch, useAppSelector} from '@src/store';
import {healthCasesActions} from '@src/store/healthCases/slice';
import {paletteColors} from '@src/theme';
import {useTranslate} from 'i18n/useTranslate';
import React, {useState, useEffect, MouseEventHandler, ChangeEvent} from 'react';
import {useNavigate, useParams, useMatch} from 'react-router-dom';
import {Checkbox} from 'ui-kit';

import {EBackgroundFlagColor, EFlagColor, EFlagName} from './Results.constant';
import {Cell, ExpandableRowContainer, InternalRow, MainRow} from './styles';
import {IRowTableProps} from './types';

export const TableRow: React.FC<IRowTableProps> = ({className, gridTemplateColumns, data, onExpand, allExpanded, onlySemanticBlock = true, sx, isConnections, expandableTemplateColumn}) => {
  const navigate = useNavigate();
  const relation = useMatch(ROUTERS_PATH.CONNECTIONS_RELATION_RESULTS);
  const {mobile, tablet, tabletPortrait} = useMQuery();
  const onPortrait = (mobile || tabletPortrait);
  const {t} = useTranslate('results');
  const {selectedResults, initialResults, savedResults} = useAppSelector((state) => state.healthCases);
  const params = useParams();
  const dispatch = useAppDispatch();
  const {id, collected, physician, flag, companyId, healthCases, type, date} = data;
  const [expanded, setExpanded] = useState(false);
  const healthCasesList = healthCases?.map((item) => {
    return item.name;
  }) || [];

  const goToResult = () => {
    const query = new URLSearchParams();
    if (type) {
      query.set('type', type);
    }

    relation
      ? navigate(`${ROUTERS_PATH.CONNECTIONS_RELATIONS}/relation/${params?.id ?? ''}/${id}/${companyId || ''}/results`)
      : navigate(`${id}?${query.toString()}`);
  };

  const mobileGridRow = {
    display: 'grid',
    gridTemplateColumns: '24px 1fr 2fr',
  };

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>, id: string) => {
    if (!initialResults.has(id) && !savedResults.has(id)) {
      dispatch(healthCasesActions.toggleResultsSelection(id));
    }
  };

  const handleExpand: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    setExpanded(st => {
      const newVal = !st;
      onExpand?.(newVal);
      return !st;
    });
  };

  const isChecked = selectedResults.has(id);

  useEffect(() => {
    setExpanded(allExpanded ?? false);
  }, [allExpanded]);

  return (
    <ExpandableRowContainer
      sx={sx}
      className={className}
      onlySemanticBlock={onlySemanticBlock}
      critical={flag === 'CRITICAL' ? 1 : 0}>
      <MainRow
        onClick={goToResult}
        style={{gridTemplateColumns}}
      >
        {(onPortrait && !isConnections) && (
          <Cell>
            <Box pl={8} pt={2}>
              <Checkbox
                value={isChecked}
                disabled={initialResults.has(id) || savedResults.has(id)}
                onChange={(e) => handleCheckboxChange(e, id)}
                onClick={(e) => e.stopPropagation()}
              />
            </Box>
          </Cell>
        )}
        <Cell
          gap={6}
          sx={onPortrait ? mobileGridRow : undefined}
          pl={(onPortrait || (tablet && isConnections)) ? 0 : 24}
          typography={onPortrait ? '12_22_500' : '14_18_500'}
        >
          {(onPortrait || (tablet && isConnections)) && flag && (
            <Box
              width={24}
              display='flex'
              justifyContent='center'>
              <CircleStatus color={EFlagColor[flag]} />
            </Box>
          )}
          {dateToFormat(DATE_FORMAT, collected || date || '')}
        </Cell>
        {(!onPortrait && !isConnections) && (
          <>
            <Cell typography='14_18_500'>{dateToFormat(DATE_FORMAT, collected || date || '')}</Cell>
            <Cell sx={{overflow: 'hidden', minWidth: '10%'}}>
              <EllipsisTextTooltip variant='14_18_500' ellipsisText={getFullNameWithoutMiddleName(physician) || ''} />
            </Cell>
            <Cell>
              {flag
                ? (
                  <Typography
                    variant='14_20_500'
                    padding='4px 8px'
                    sx={{backgroundColor: EBackgroundFlagColor[flag], opacity: 0.7}}
                    color={EFlagColor[flag]}>{EFlagName[flag]}</Typography>
                )
                : null}
            </Cell>
          </>
        )}
        {(onPortrait) && (
          <>
            <Cell typography='12_22_500'>{getFullNameWithoutMiddleName(physician) || ''}</Cell>
          </>
        )}
        {onPortrait && !isConnections && (
          <Cell sx={{justifyContent: 'end'}}>
            <IconButton sx={{p: 0}} onClick={handleExpand}>
              {expanded
                ? (
                  <SvgIcon sx={{transform: 'rotate(180deg)', width: 24, height: 24, color: paletteColors.grey[300]}}>
                    <IconSVGArrowDown width={24} height={24} style={{marginBottom: 10}} />
                  </SvgIcon>
                )
                : (
                  <SvgIcon sx={{width: 24, height: 24, color: paletteColors.grey[300]}}>
                    <IconSVGArrowDown width={24} height={24} style={{marginBottom: 10}} />
                  </SvgIcon>
                )}
            </IconButton>
          </Cell>
        )}
      </MainRow>
      {(onPortrait || tablet) && expanded && (
        <Box>
          {!isConnections && (
            <InternalRow
              sx={expandableTemplateColumn || {gridTemplateColumns}}
            >
              <Cell ml={0}>
                <Typography ml={20} typography={mobile || tabletPortrait ? '12_18_700' : '14_18_700'}>{t('HEALTH_CASES')}</Typography>
              </Cell>
              <Cell>
                <Typography
                  typography={mobile || tabletPortrait ? '12_18_500' : '14_18_500'}>
                  {healthCasesList.join(', ')}
                </Typography>
              </Cell>
            </InternalRow>
          )}
        </Box>
      )}
    </ExpandableRowContainer>
  );
};
