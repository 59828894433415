import {SexualOrientation, SexualOrientationMap} from '@src/api';
import {
  RelationshipType,
  StateType,
  EditRelationAddressData,
  EditRelationContactData,
  RelationContactSearch,
  RelationRequestCreateRequest,
  RelationHealthcareInsurancePolicyChangeRequest,
} from '@src/api/relations';
import {
  IRelationContactBatchCreateRequestSaveData,
  EWhichPrimaryInsuranceUse,
} from '@src/pages/Connections/fragments/Relations/CreateNewPatient/types';
import {groupRelationsByType} from '@src/pages/Connections/fragments/Relations/helpers/functionHelpers';
import {NOT_FOUND_CODE} from '@src/shared/constants/statusConstants';
import {UUID} from '@src/types';
import {Nullable} from '@src/types/NullableModel';
import {useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useAppSelector} from 'store';

import {PER_PAGE, relationsActions, selectDynamicsData} from './slice';

export const useRelationRequests = () => {
  const {relationRequests, fetching} = useAppSelector((state) => state.relations);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!relationRequests) {
      dispatch(relationsActions.requestRelationRequests());
    }
  }, [dispatch]);

  return {
    incoming: relationRequests ? relationRequests.incoming : null,
    outgoing: relationRequests ? relationRequests.outgoing : null,
    fetching: !!fetching?.relation,
  };
};

export const useContactRelations = (contactId?: UUID) => {
  const {relations, fetching} = useAppSelector((state) => state.relations);
  const groupedRelations = groupRelationsByType(relations);
  const relationsByContactId = groupedRelations.find(
    (relation) => relation.contact.id === contactId,
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (!relations?.length) {
      dispatch(relationsActions.requestRelations());
    }
  }, [dispatch]);
  return {
    fetching: !!fetching?.relation,
    groupedRelations,
    relationId: relationsByContactId?.id,
  };
};

export const useRelation = (relationId?: UUID) => {
  const {relation, fetching, save: isSave} = useAppSelector((state) => state.relations);
  const dispatch = useDispatch();

  useEffect(() => {
    if (relationId && !isSave) {
      dispatch(relationsActions.requestRelation({relationId}));
    }
  }, [dispatch, relationId]);

  return {
    fetching: !!fetching?.relation,
    relation,
  };
};

export const useRelationResults = (relationId?: UUID) => {
  const {results, fetching} = useAppSelector((state) => state.relations);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!results && relationId && !fetching?.relation) {
      dispatch(relationsActions.requestResultsById({relationId}));
    }

    return () => {
      if (results) {
        dispatch(relationsActions.setResults({results: null}));
      }
    };
  }, [relationId, results, dispatch, fetching?.relation]);

  return {
    fetching: fetching?.relation,
    results,
  };
};

export const useRelationDetails = ({
  relationId,
  resultId,
}: {
  relationId?: UUID
  resultId?: UUID
}) => {
  const {resultDetails, errorStatus, fetching} = useAppSelector((state) => state.relations);
  const dispatch = useDispatch();
  const details = resultId ? resultDetails?.byID[resultId] : null;
  const isLoading = !details && !errorStatus;
  const isNotFound = errorStatus === NOT_FOUND_CODE;

  useEffect(() => {
    if (!details && relationId && resultId) {
      dispatch(relationsActions.requestResultDetails({relationId, resultId}));
    }
  }, [resultId, details, dispatch, relationId]);
  return {
    isLoading,
    isFetching: !!fetching?.relation,
    result: details,
    isNotFound,
  };
};

export const useDynamicsData = (relationId?: UUID) => {
  const dynamics = useAppSelector(selectDynamicsData);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(false);
  }, [dynamics]);

  useEffect(() => {
    if (!dynamics.testResults.length && relationId) {
      dispatch(relationsActions.requestDynamics({relationId}));
      setIsLoading(true);
    }
  }, [dispatch, dynamics, relationId]);

  return {
    dynamics,
    isLoading,
  };
};

export const useContactForm = () => {
  const dispatch = useDispatch();
  const callbacks = useMemo(() => {
    return {
      edit: ({relationId, data}: { relationId?: UUID, data: EditRelationContactData }) => {
        if (!relationId || !data) {
          console.error('[useContactForm] relationId or data is undefined');
        } else {
          dispatch(relationsActions.editRelationContactData({relationId, data}));
        }
      },
    };
  }, [dispatch]);
  return {
    ...callbacks,
  };
};

export const useAddressForm = () => {
  const dispatch = useDispatch();
  const callbacks = useMemo(() => {
    return {
      edit: ({relationId, data}: { relationId?: UUID, data: EditRelationAddressData }) => {
        if (!relationId || !data) {
          console.error('[useAddressForm] relationId or data is undefined');
        } else {
          dispatch(relationsActions.editRelationAddressData({relationId, data}));
        }
      },
    };
  }, [dispatch]);
  return {
    ...callbacks,
  };
};

export const useApproveRelationRequest = () => {
  const relationRequests = useAppSelector((state) => state.relations.relationRequests);
  const dispatch = useDispatch();
  const callbacks = useMemo(() => {
    return {
      approve: ({requestId}: { requestId?: UUID }) => {
        if (!requestId) {
          console.error('[useApproveRelationRequest] companyId or relationId is undefined');
        } else {
          dispatch(relationsActions.approveRelationRequest({requestId}));
          const updatedRequests = relationRequests?.incoming.filter(
            (request) => request.id !== requestId,
          );
          if (updatedRequests && relationRequests) {
            dispatch(
              relationsActions.setRelationRequests({
                relationRequests: {
                  incoming: updatedRequests,
                  outgoing: relationRequests.outgoing,
                },
              }),
            );
          }
        }
      },
    };
  }, [dispatch, relationRequests]);
  return {
    ...callbacks,
  };
};

export const useHealthPoliciesForm = () => {
  const dispatch = useDispatch();
  const callbacks = useMemo(() => {
    return {
      edit: ({
        relationId,
        data,
      }: {
        relationId?: UUID
        data: RelationHealthcareInsurancePolicyChangeRequest
      }) => {
        if (!relationId || !data) {
          console.error('[useHealthPoliciesForm] relationId or data is undefined');
        } else {
          dispatch(relationsActions.editRelationHealthPoliciesData({relationId, data}));
        }
      },
    };
  }, [dispatch]);
  return {
    ...callbacks,
  };
};

export const useDeclineRelationRequest = () => {
  const relationRequests = useAppSelector((state) => state.relations.relationRequests);
  const dispatch = useDispatch();
  const callbacks = useMemo(() => {
    return {
      decline: ({requestId}: { requestId?: UUID }) => {
        if (!requestId) {
          console.error('[useDeclineRelationRequest] companyId or relationId is undefined');
        } else {
          dispatch(relationsActions.declineRelationRequest({requestId}));
          const updatedRequests = relationRequests?.incoming.filter(
            (request) => request.id !== requestId,
          );
          if (updatedRequests && relationRequests) {
            dispatch(
              relationsActions.setRelationRequests({
                relationRequests: {
                  incoming: updatedRequests,
                  outgoing: relationRequests.outgoing,
                },
              }),
            );
          }
        }
      },
    };
  }, [dispatch, relationRequests]);
  return {
    ...callbacks,
  };
};

export const usePrimaryInsuranceCarriers = () => {
  const dispatch = useDispatch();
  const callbacks = useMemo(() => {
    return {
      getPrimaryInsuranceCarriers: (filter: string) => {
        if (!filter) {
          console.error('[usePrimaryInsuranceCarriers] filter is undefined');
        } else {
          dispatch(relationsActions.requestPrimaryInsuranceCarriers({filter}));
        }
      },
    };
  }, [dispatch]);
  return {
    ...callbacks,
  };
};

export const useDeleteRelationRequest = () => {
  const relationRequests = useAppSelector((state) => state.relations.relationRequests);
  const dispatch = useDispatch();
  const callbacks = useMemo(() => {
    return {
      deleteRequest: ({requestId}: { requestId?: UUID }) => {
        if (!requestId) {
          console.error('[useDeleteRelationRequest] companyId or relationId is undefined');
        } else {
          dispatch(relationsActions.deleteRelationRequest({requestId}));
          const updatedRequests = relationRequests?.outgoing.filter(
            (request) => request.id !== requestId,
          );
          if (updatedRequests && relationRequests) {
            dispatch(
              relationsActions.setRelationRequests({
                relationRequests: {
                  incoming: relationRequests.incoming,
                  outgoing: updatedRequests,
                },
              }),
            );
          }
        }
      },
    };
  }, [dispatch, relationRequests]);
  return {
    ...callbacks,
  };
};

export const useSecondaryInsuranceCarriers = () => {
  const dispatch = useDispatch();
  const callbacks = useMemo(() => {
    return {
      getSecondaryInsuranceCarriers: (filter: string) => {
        if (!filter) {
          console.error('[useSecondaryInsuranceCarriers] filter is undefined');
        } else {
          dispatch(relationsActions.requestSecondaryInsuranceCarriers({filter}));
        }
      },
    };
  }, [dispatch]);
  return {
    ...callbacks,
  };
};

export const usePrimaryInsurancePlans = (insuranceCarrierId?: UUID) => {
  const insurancePlans = useAppSelector((state) => state.relations.primaryInsurancePlans);
  const dispatch = useDispatch();
  useEffect(() => {
    if (insuranceCarrierId) {
      dispatch(relationsActions.requestPrimaryInsurancePlans({insuranceCarrierId}));
    }
  }, [dispatch, insuranceCarrierId]);
  return {
    fetching: null,
    insurancePlans,
  };
};

export const useSecondaryInsurancePlans = (insuranceCarrierId?: UUID) => {
  const insurancePlans = useAppSelector((state) => state.relations.secondaryInsurancePlans);
  const dispatch = useDispatch();
  useEffect(() => {
    if (insuranceCarrierId) {
      dispatch(relationsActions.requestSecondaryInsurancePlans({insuranceCarrierId}));
    }
  }, [dispatch, insuranceCarrierId]);
  return {
    fetching: null,
    insurancePlans,
  };
};

export const useSearchRelationContacts = () => {
  const dispatch = useDispatch();
  const {fetching, relationContactsHasNext, contacts, relationContacts} = useAppSelector(
    (state) => state.relations,
  );
  const callbacks = useMemo(() => {
    return {
      search: ({data}: { data: RelationContactSearch }) => {
        if (!data) {
          console.error('[useSearchRelationContacts] data is undefined');
        } else {
          dispatch(relationsActions.searchRelationContacts({data}));
        }
      },
      loadMore: () => {
        if (relationContactsHasNext && relationContacts) {
          dispatch(
            relationsActions.searchRelationContacts({
              data: {
                relationType: contacts.relationType ?? '',
                startPage: Math.round(relationContacts?.length / PER_PAGE) ?? 0,
                filter: contacts.filter ?? '',
                perPage: PER_PAGE,
                policyNumber: contacts.policyNumber ?? '',
                recaptcha: '',
              },
            }),
          );
        }
      },
    };
  }, [
    contacts.filter,
    contacts.policyNumber,
    contacts.relationType,
    dispatch,
    relationContacts,
    relationContactsHasNext,
  ]);
  return {
    fetching: fetching?.relation || false,
    relationContacts,
    ...callbacks,
  };
};

export const useSendRelationRequest = () => {
  const dispatch = useDispatch();
  const callbacks = useMemo(() => {
    return {
      sendRelationRequest: ({data}: { data: RelationRequestCreateRequest }) => {
        if (!data) {
          console.error('[useSendRelationRequest] data is undefined');
        } else {
          dispatch(relationsActions.sendRelationRequest({data}));
        }
      },
    };
  }, [dispatch]);
  return {
    ...callbacks,
  };
};

export const useCreateRelationContact = () => {
  const dispatch = useDispatch();
  const {successCreatedContact, failureCreatedContact, fetching} = useAppSelector(state => state.relations);

  const callbacks = useMemo(() => {
    return {
      createRelationContact: (
        {
          relationShipType,
          firstName,
          lastName,
          middleName,
          birthDate,
          birthSex,
          gender,
          race,
          ethnicity,
          sexualOrientation,
          phone,
          email,
          primary,
          insurancePlan,
          policyId,
          endDate,
          insuranceId,
          guarantorRelationshipType,
          guarantorFirstName,
          guarantorLastName,
          guarantorMiddleName,
          guarantorBirthDate,
          guarantorBirthSex,
          guarantorPhone,
          guarantorState,
          guarantorCity,
          guarantorAddress1,
          guarantorAddress2,
          guarantorSsnTail,
          guarantorPostalCode,
          insurancePlanId,
          whichPrimaryInsuranceUse,
          myInsurance,
          address1,
          address2,
          location,
          state,
          city,
          postalCode,
        }: Nullable<IRelationContactBatchCreateRequestSaveData>,
        force: boolean = false,
      ) => {
        const whichGuarantorUse = () => {
          return whichPrimaryInsuranceUse === EWhichPrimaryInsuranceUse.My && myInsurance
            ? {
              address1: myInsurance.guarantor?.main || '',
              address2: {
                value: myInsurance.guarantor?.additional || '',
              },
              birthDate: myInsurance.guarantor?.birthDate || '',
              birthSex: myInsurance.guarantor?.birthSex || null,
              city: myInsurance.guarantor?.city || '',
              firstName: myInsurance.guarantor?.firstName || '',
              lastName: myInsurance.guarantor?.lastName || '',
              middleName: {
                value: myInsurance.guarantor?.middleName || '',
              },
              phone: {
                value: myInsurance.guarantor?.phone || '',
              },
              postalCode: myInsurance.guarantor?.postalCode || '',
              relationType:
                  (myInsurance.guarantor?.relationType as unknown as RelationshipType) || null,
              ssnTail: {
                value: myInsurance.guarantor?.ssnTail || '',
              },
              state: (myInsurance.guarantor?.state as unknown as StateType) || null,
            }
            : {
              address1: guarantorAddress1 || '',
              address2: {
                value: guarantorAddress2 || '',
              },
              birthDate: guarantorBirthDate || '',
              birthSex: guarantorBirthSex || null,
              city: guarantorCity || '',
              firstName: guarantorFirstName || '',
              lastName: guarantorLastName || '',
              middleName: {
                value: guarantorMiddleName || '',
              },
              phone: {
                value: guarantorPhone || '',
              },
              postalCode: guarantorPostalCode || '',
              relationType: guarantorRelationshipType || null,
              ssnTail: {
                value: guarantorSsnTail || '',
              },
              state: guarantorState || null,
            };
        };

        const address = address1
          ? {
            address2: address2 || undefined,
            city: city || '',
            location: location || undefined,
            address1: address1 || '',
            postalCode: postalCode || '',
            state: state || '',
          }
          : undefined;
        const data = {
          address,
          birthDate: birthDate || '',
          birthSex: birthSex || null,
          email: email || undefined,
          ethnicity: ethnicity || undefined,
          firstName: firstName || '',
          gender: gender || null,
          insurance:
            whichPrimaryInsuranceUse === EWhichPrimaryInsuranceUse.Patient && insurancePlan
              ? {
                endDate: {
                  value: endDate || '',
                },
                guarantor: whichGuarantorUse(),
                insurancePlanId: insurancePlanId || '',
                number: policyId || '',
                primary: primary || false,
              }
              : undefined,
          insuranceId:
            whichPrimaryInsuranceUse === EWhichPrimaryInsuranceUse.My && !insurancePlan
              ? insuranceId || undefined
              : undefined,
          lastName: lastName || '',
          middleName: middleName || undefined,
          phone: phone || undefined,
          race: race || null,
          recaptcha: '',
          relationType: relationShipType || null,
          sexualOrientation: sexualOrientation
            ? (SexualOrientationMap[
              sexualOrientation as keyof typeof SexualOrientationMap
            ] as unknown as SexualOrientation) || null
            : undefined,
        };
        if (!data) {
          console.error('[useCreateRelationContact] data is undefined');
        } else {
          dispatch(relationsActions.createRelationContact({data, force}));
        }
      },
    };
  }, [dispatch]);

  return {
    fetching: fetching?.relation || false,
    successCreatedContact,
    failureCreatedContact,
    ...callbacks,
  };
};

export const useRemoveInsurance = () => {
  const dispatch = useDispatch();
  const callbacks = useMemo(() => {
    return {
      removeInsurance: ({
        data,
        relationId,
      }: {
        data: RelationHealthcareInsurancePolicyChangeRequest
        relationId: UUID
      }) => {
        if (!data) {
          console.error('[useRemoveInsurance] data is undefined');
        } else {
          dispatch(relationsActions.deleteInsurance({data, relationId}));
        }
      },
    };
  }, [dispatch]);
  return {
    ...callbacks,
  };
};

export const useEmailForm = () => {
  const dispatch = useDispatch();
  const callbacks = useMemo(() => {
    return {
      submit: ({
        relationId,
        resultId,
        email,
      }: {
        relationId?: UUID
        resultId?: UUID
        email: string
      }) => {
        if (!resultId) {
          console.error('[useEmailForm] resultId is undefined');
        } else if (!relationId) {
          console.error('[useEmailForm] relationId is undefined');
        } else {
          dispatch(relationsActions.sendResultsByEmail({relationId, resultId, email}));
        }
      },
    };
  }, [dispatch]);
  return {
    ...callbacks,
  };
};
