import {getErrorForDisplay} from '@src/shared/hooks/useFormikError';
import {useFormikContext, Field, FieldProps} from 'formik';
import {SelectControl, SelectControlProps} from 'ui-kit';

type Props<T> = Omit<SelectControlProps<T>, 'name' | 'value'> & {
  name: string
  value?: T
};

export const FormSelectControl = <T extends string>({
  name,
  value,
  onChange,
  children,
  ...props
}: Props<T>) => {
  const formik = useFormikContext();

  if (!formik) {
    return (
      <SelectControl<T>
        name={name}
        value={value ?? undefined}
        onChange={onChange}
        {...props}
      >
        {children}
      </SelectControl>
    );
  }

  return (
    <Field name={name}>
      {({field, meta, form}: FieldProps) => {
        const {onChange: formikOnChange, value: formikValue, ...restField} = field;

        const error = getErrorForDisplay({
          error: meta.error,
          value: meta.value,
          touched: meta.touched,
          submitCount: form.submitCount,
        });

        const handleSelectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          onChange?.(e);
          formikOnChange(e);
        };

        return (
          <SelectControl<T>
            name={restField.name}
            value={formikValue ?? undefined}
            error={error}
            onChange={handleSelectChange}
            {...props}
          >
            {children}
          </SelectControl>
        );
      }}
    </Field>
  );
};
