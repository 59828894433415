import {ChangeEvent, FC} from 'react';
import {useController, useFormContext} from 'react-hook-form';
import {TextareaControl} from 'ui-kit';

import {FormTextareaControlProps} from './types';

export const FormTextareaControl: FC<FormTextareaControlProps> = ({name, limit, ...restProps}) => {
  const formContext = useFormContext();
  const {
    field: {value, onChange},
    fieldState: {error},
  } = useController({
    control: formContext.control,
    name,
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (limit && e.target.value.length > limit) {
      return;
    }
    onChange(e);
  };

  return (
    <TextareaControl
      {...restProps}
      name={name}
      value={value}
      onChange={handleChange}
      error={error?.message}
    />
  );
};
