import {SxProps, Theme} from '@mui/material';

export const sx = {
  textContainer: {
    p: '12px 24px',
    mt: 12,
  },
  addActionContainer: {
    flexDirection: 'row',
    p: 24,
    justifyContent: 'space-between',
    borderTop: '1px solid',
    borderColor: 'secondary.300',
  },
  mobileTitle: {
    height: 54,
    mb: 18,
  },
} satisfies Record<string, SxProps<Theme>>;
