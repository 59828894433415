/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-invalid-void-type */
import {Stack, Typography, Box} from '@mui/material';
import {RelationType, StateType} from '@src/api';
import {ListWrapper, RowInformation, WBox} from '@src/components';
import {FormSelectControl} from '@src/components/FormSelectControl';
import Grid from '@src/components/Grid';
import {DateControl} from '@src/components/form/DateControl';
import {useTranslate} from '@src/i18n/useTranslate';
import {POSTAL_CODE_LENGTH, touchedData} from '@src/pages/Connections/constants';
import {ROUTERS_PATH} from '@src/routers';
import {BIRTH_SEX_TYPE_MAP, RELATIONSHIP_TYPE_MAP, STATES_TYPE_MAP} from '@src/shared/constants/enumsMaps';
import {MAX_ADDRESS_LENGTH, MAX_CITY_LENGTH, MAX_INPUT_LENGTH, MAX_SSN_LENGTH} from '@src/shared/constants/formFields';
import {useColumnsNumber} from '@src/shared/hooks';
import {useErrorTextHeightObserve} from '@src/shared/hooks/useErrorTextHeightObserve';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {dateFormatted, dateToFormat} from '@src/shared/utils';
import {normalizeString} from '@src/shared/utils/normalizeString';
import {validateValue} from '@src/shared/utils/validateValue';
import {useAppSelector} from '@src/store';
import {
  useContactRelations,
  useHealthPoliciesForm,
  useRelation,
} from '@src/store/relations/hooks';
import {relationsActions} from '@src/store/relations/slice';
import {RelationshipTypes} from '@src/types';
import {Formik, FormikHelpers} from 'formik';
import {isEqual} from 'lodash-es';
import {ChangeEvent, useEffect, useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {generatePath, useNavigate, useParams} from 'react-router-dom';
import {Button, InputControl, MenuItem, PhoneInputControl} from 'ui-kit';

import {getOnlyActiveInsurances, makeMapOfAccess, sortInsurances} from '../../../helpers/functionHelpers';
import {setGuarantorFieldsBySelf} from '../../../helpers/setGuarantorFieldsBySelf';
import {Loader} from '../../Loader';
import {getHealthPoliciesRoute} from '../../helpers';
import {birthSexSelectOptions, relationshipTypeSelectOptions, statesSelectOptions} from '../constants';
import {matrix} from '../matrix';

import {defaultColumns, customColumns, fieldAccesses} from './constants';
import {StyledForm, sx} from './styles';
import {saveData} from './utils/saveData';
import {useValidationSchema} from './utils/useValidationSchema';

// eslint-disable-next-line

export const RelationHealthPoliciesEdit = () => {
  const {t, ready} = useTranslate('connections');
  const {t: tEnums, ready: enumsReady} = useTranslate('enums');

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {id} = useParams();
  const {edit} = useHealthPoliciesForm();
  const {mobile: isMobile, desktop: isDesktop} = useMQuery();
  const {relationId, fetching} = useContactRelations(id);
  const {relation} = useRelation(relationId);
  const {hasInsuranceError} = useAppSelector(state => state.relations);

  const primary = sessionStorage.getItem('primary');
  const sortedInsurance = sortInsurances(relation?.healthcareInsurancePolicies);
  const insurance = sortedInsurance?.find((insurance) => insurance.primary === JSON.parse(primary || ''));
  const isValidateGuarantorEdit = insurance?.guarantor?.firstName;
  const isPrimaryInsurance = insurance?.primary;

  const onlyActiveInsurances = useMemo(() => getOnlyActiveInsurances(relation?.healthcareInsurancePolicies), [relation?.healthcareInsurancePolicies]);
  const accesses = makeMapOfAccess({fieldAccesses, relation, matrix, entity: 'guarantor'});

  const healthPoliciesRoute = generatePath(ROUTERS_PATH.CONNECTIONS_RELATION_HEALTH_POLICIES, {id: id || ''});

  const initialValue = {
    relationship: insurance?.guarantor?.relationType || null,
    birthDate: insurance?.guarantor?.birthDate || null,
    birthSex: insurance?.guarantor?.birthSex || null,
    firstName: insurance?.guarantor?.firstName || null,
    lastName: insurance?.guarantor?.lastName || null,
    middleName: insurance?.guarantor?.middleName || null,
    ssnTail: insurance?.guarantor?.ssnTail || null,
    state: insurance?.guarantor?.state ? StateType[insurance?.guarantor?.state] : null,
    city: insurance?.guarantor?.city || null,
    postalCode: insurance?.guarantor?.postalCode || null,
    address1: insurance?.guarantor?.main || null,
    address2: insurance?.guarantor?.additional || null,
    phone: insurance?.guarantor?.phone || null,
    endDate: insurance?.endDate || null,
    id: insurance?.id || null,
  };

  const columnSpacingValue = isDesktop ? 48 : 36;
  const spacingValue = isMobile ? 18 : 24;

  const listWrapperPadding = isMobile ? '16px' : '12px';

  const handleClickBtnCancel = (resetForm: FormikHelpers<typeof initialValue>['resetForm']) => {
    navigate(getHealthPoliciesRoute(id));
    resetForm();
  };

  const handleClickBtnSave = (
    setTouched: FormikHelpers<typeof initialValue>['setTouched'],
    handleSubmit: VoidFunction,
  ) => {
    setTouched(touchedData);
    handleSubmit();
  };

  useEffect(() => {
    if (onlyActiveInsurances?.length === 2) {
      dispatch(relationsActions.setInsuranceStep({step: 'twoInsurances'}));
    }
    if (onlyActiveInsurances?.length === 1 && onlyActiveInsurances[0].primary) {
      dispatch(relationsActions.setInsuranceStep({step: 'onlyPrimaryInsurance'}));
    }
    if (onlyActiveInsurances?.length === 1 && !onlyActiveInsurances[0].primary) {
      dispatch(relationsActions.setInsuranceStep({step: 'onlySecondaryInsurance'}));
    }
    if (!onlyActiveInsurances?.length) {
      dispatch(relationsActions.setInsuranceStep({step: 'noInsurances'}));
    }
  }, [dispatch, onlyActiveInsurances]);

  const canEditOrCreate = (fieldName: string) => {
    if (accesses) {
      return !accesses[fieldName]?.includes('C') &&
        !accesses[fieldName]?.includes('D') &&
        !accesses[fieldName]?.includes('U');
    } else return false;
  };

  const gapMobile = isMobile ? 14 : 0;

  const breakpoints = {sm: 592};

  const validationSchema = useValidationSchema({accesses, insurance, isValidateGuarantorEdit: !!isValidateGuarantorEdit});
  const {colsNumberSm, containerRef} = useColumnsNumber({breakpoints, defaultColumns, customColumns});

  const showLoader = fetching || !ready || !enumsReady;

  useEffect(() => {
    if (hasInsuranceError === false) {
      navigate(healthPoliciesRoute);
      dispatch(relationsActions.setHasInsuranceError(null));
    }
  }, [hasInsuranceError, navigate, dispatch]);

  if (showLoader) {
    return <Loader />;
  }

  return (
    <Formik<typeof initialValue>
      enableReinitialize
      onSubmit={(values) => {
        if (!isEqual(initialValue, values)) {
          const normalizedValues = {
            ...values,
            firstName: normalizeString(values.firstName),
            lastName: normalizeString(values.lastName),
            middleName: normalizeString(values.middleName),
            city: normalizeString(values.city),
            address1: normalizeString(values.address1),
            address2: normalizeString(values.address2),
            relationType: values.relationship !== RelationshipTypes.SELF ? values.relationship : null,
          };

          saveData({...normalizedValues, edit, relationId});
        }
      }}
      initialValues={initialValue}
      validationSchema={validationSchema}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        setFieldValue,
        errors,
        touched,
        setFieldTouched,
        resetForm,
        setTouched,
      }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const {errorRef, isErrorTall} = useErrorTextHeightObserve();
        return (
          <StyledForm>
            <WBox sx={sx.whiteBox}>
              <Stack sx={sx.mainContainer}>
                <Stack sx={sx.flexContainerInsurance}>
                  <Typography sx={sx.insuranceTitle}>
                    {isPrimaryInsurance ? t('PRIMARY_INSURANCE') : t('SECONDARY_INSURANCE')}
                  </Typography>
                  <ListWrapper padding={listWrapperPadding} noLast={{xs: 0}}>
                    <RowInformation
                      name={t('INSURANCE_CARRIER')}
                      value={insurance?.insurancePlan?.carrier?.name}
                      noMargin
                      tooltip={isDesktop}
                      noWrap={!isDesktop}
                    />
                    <RowInformation
                      name={t('INSURANCE_PLAN')}
                      value={insurance?.insurancePlan?.name}
                      noMargin
                      tooltip={isDesktop}
                      noWrap={!isDesktop}
                    />
                    <RowInformation
                      tooltip={isDesktop}
                      name={t('POLICY_ID')}
                      value={insurance?.number}
                      noWrap={!isDesktop}
                      noMargin />
                    <Grid
                      ref={containerRef}
                      container
                      spacing={24}
                      columnSpacing={48}>
                      <Grid
                        xl={4}
                        sm={colsNumberSm}
                        xs={12}>
                        <DateControl
                          labelTop
                          placeholder='mm/dd/yyyy'
                          reverseYears
                          disabledPast
                          onlyFutureYears
                          name="endDate"
                          helperText={t('OPTIONAL')}
                          value={dateToFormat('P', values.endDate) || ''}
                          onTouched={setFieldTouched}
                          onChange={handleChange}
                          label={t('END_DATE')}
                          error={touched.endDate ? errors.endDate : ''}
                          optional
                        />
                      </Grid>
                    </Grid>
                  </ListWrapper>
                </Stack>
                <Stack sx={sx.flexContainerGuarantor}>
                  <Stack flexGrow={1} gap={16}>
                    <Box sx={sx.boxContainer}>
                      <Typography component="h4" sx={sx.title}>
                        {t('GUARANTOR')}
                      </Typography>
                    </Box>
                    <Grid
                      ref={containerRef}
                      container
                      spacing={spacingValue}
                      columnSpacing={columnSpacingValue}>
                      <Grid
                        xl={4}
                        sm={colsNumberSm}
                        xs={12}>
                        <FormSelectControl<keyof typeof RELATIONSHIP_TYPE_MAP>
                          disabled={canEditOrCreate('relationType')}
                          optional
                          placeholder={t('SELECT')}
                          name="relationship"
                          onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                            handleChange(e);
                            if (e.target.value === RelationType.SELF) {
                              setGuarantorFieldsBySelf(setFieldValue, relation);
                            }
                          }}

                          label={t('RELATIONSHIP')}
                          renderValue={(v) => (v ? tEnums(RELATIONSHIP_TYPE_MAP[v]) : '')}
                        >
                          {relationshipTypeSelectOptions.map(({name, value}, key) => (
                            <MenuItem key={key} value={value}>
                              {tEnums(name)}
                            </MenuItem>
                          ))}
                        </FormSelectControl>
                      </Grid>
                    </Grid>
                  </Stack>
                  <Stack gap={spacingValue}>
                    <Stack sx={sx.content} flexGrow={1}>
                      <Grid
                        ref={containerRef}
                        container
                        spacing={spacingValue}
                        columnSpacing={columnSpacingValue}
                        gap={gapMobile}
                      >
                        <Grid
                          xl={4}
                          lg={6}
                          sm={colsNumberSm}
                          xs={12}>
                          <InputControl
                            disabled={canEditOrCreate('firstName')}
                            name="firstName"
                            label={t('FIRST_NAME')}
                            value={values.firstName || ''}
                            error={touched?.firstName ? errors.firstName : ''}
                            onChange={handleChange}
                            maxlength={MAX_INPUT_LENGTH}
                          />
                        </Grid>
                        <Grid
                          xl={4}
                          lg={6}
                          sm={colsNumberSm}
                          xs={12}>
                          <InputControl
                            disabled={canEditOrCreate('lastName')}
                            name="lastName"
                            label={t('LAST_NAME')}
                            value={values.lastName || ''}
                            error={touched?.lastName ? errors.lastName : ''}
                            onChange={handleChange}
                            maxlength={MAX_INPUT_LENGTH}
                          />
                        </Grid>
                        <Grid
                          xl={4}
                          lg={6}
                          sm={colsNumberSm}
                          xs={12}>
                          <InputControl
                            optional
                            disabled={canEditOrCreate('middleName')}
                            name="middleName"
                            label={t('MIDDLE_NAME')}
                            value={values.middleName || ''}
                            error={touched?.middleName ? errors.middleName : ''}
                            onChange={handleChange}
                            maxlength={MAX_INPUT_LENGTH}
                          />
                        </Grid>
                        <Grid
                          xl={4}
                          lg={6}
                          sm={colsNumberSm}
                          xs={12}
                          sx={{mb: isErrorTall ? 12 : 0}}
                        >
                          <DateControl
                            ref={errorRef}
                            labelTop
                            placeholder='mm/dd/yyyy'
                            disabled={() => canEditOrCreate('birthDate')}
                            name="birthDate"
                            disabledFuture
                            value={dateFormatted(values.birthDate) || ''}
                            onChange={handleChange}
                            onTouched={setFieldTouched}
                            label={t('DATE_OF_BIRTH')}
                            hideTip={false}
                            errorTextStyles={{
                              whiteSpace: 'break-spaces',
                            }}
                            error={touched?.birthDate ? errors.birthDate : ''}
                          />
                        </Grid>
                        <Grid
                          xl={4}
                          lg={6}
                          sm={colsNumberSm}
                          xs={12}>
                          <FormSelectControl<keyof typeof BIRTH_SEX_TYPE_MAP>
                            disabled={canEditOrCreate('birthSex')}
                            placeholder={t('SELECT')}
                            name="birthSex"
                            label={t('BIRTH_SEX')}
                            renderValue={(v) => (v ? tEnums(BIRTH_SEX_TYPE_MAP[v]) : '')}
                          >
                            {birthSexSelectOptions.map(({name, value}, key) => (
                              <MenuItem key={key} value={value}>
                                {tEnums(name)}
                              </MenuItem>
                            ))}
                          </FormSelectControl>
                        </Grid>
                        <Grid
                          xl={4}
                          lg={6}
                          sm={colsNumberSm}
                          xs={12}
                          pb={isMobile ? 16 : 0}
                        >
                          <InputControl
                            type='number'
                            optional
                            disabled={canEditOrCreate('ssnTail')}
                            name="ssnTail"
                            label={t('SSN_LAST_4')}
                            error={touched?.ssnTail ? errors.ssnTail : ''}
                            value={values.ssnTail || ''}
                            onChange={handleChange}
                            maxlength={MAX_SSN_LENGTH}
                          />
                        </Grid>
                      </Grid>
                    </Stack>
                    <Stack sx={sx.content} flexGrow={1}>
                      <Grid
                        ref={containerRef}
                        container
                        spacing={spacingValue}
                        columnSpacing={columnSpacingValue}
                        gap={gapMobile}
                      >
                        <Grid
                          xl={4}
                          lg={6}
                          sm={colsNumberSm}
                          xs={12}>
                          <FormSelectControl<keyof typeof STATES_TYPE_MAP>
                            disabled={canEditOrCreate('state')}
                            placeholder={t('SELECT')}
                            name="state"
                            label={t('STATE')}
                            renderValue={(v) => (v ? tEnums(STATES_TYPE_MAP[v]) : '')}
                          >
                            {statesSelectOptions.map(({name, value}, key) => (
                              <MenuItem key={key} value={value}>
                                {tEnums(name)}
                              </MenuItem>
                            ))}
                          </FormSelectControl>
                        </Grid>
                        <Grid
                          xl={4}
                          lg={6}
                          sm={colsNumberSm}
                          xs={12}>
                          <InputControl
                            disabled={canEditOrCreate('city')}
                            name="city"
                            label={t('CITY')}
                            value={values.city || ''}
                            error={touched?.city ? errors.city : ''}
                            onChange={handleChange}
                            maxlength={MAX_CITY_LENGTH}
                          />
                        </Grid>
                        <Grid
                          xl={4}
                          lg={6}
                          sm={colsNumberSm}
                          xs={12}>
                          <InputControl
                            simpleTextInput
                            disabled={canEditOrCreate('postalCode')}
                            name="postalCode"
                            label={t('POSTAL_CODE')}
                            value={validateValue(values.postalCode || '')}
                            error={touched?.postalCode ? errors.postalCode : ''}
                            onChange={handleChange}
                            maxlength={POSTAL_CODE_LENGTH}
                          />
                        </Grid>
                        <Grid
                          xl={4}
                          lg={6}
                          sm={colsNumberSm}
                          xs={12}>
                          <InputControl
                            disabled={canEditOrCreate('address1')}
                            name="address1"
                            label={t('ADDRESS_1')}
                            error={touched?.address1 ? errors.address1 : ''}
                            value={values.address1 || ''}
                            onChange={handleChange}
                            maxlength={MAX_ADDRESS_LENGTH}
                          />
                        </Grid>
                        <Grid
                          xl={4}
                          lg={6}
                          sm={colsNumberSm}
                          xs={12}>
                          <InputControl
                            optional
                            disabled={canEditOrCreate('address2')}
                            name="address2"
                            label={t('ADDRESS_2')}
                            error={touched?.address2 ? errors.address2 : ''}
                            value={values.address2 || ''}
                            onChange={handleChange}
                            maxlength={MAX_ADDRESS_LENGTH}
                          />
                        </Grid>
                        <Grid
                          xl={4}
                          lg={6}
                          sm={colsNumberSm}
                          xs={12}>
                          <PhoneInputControl
                            disabled={canEditOrCreate('phone')}
                            optional
                            name="phone"
                            label={t('PHONE')}
                            error={touched?.phone ? errors.phone : ''}
                            placeholder={t('ENTER_A_PHONE')}
                            value={values.phone || ''}
                            onChange={handleChange}
                          />
                        </Grid>
                      </Grid>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
              <Stack sx={sx.buttonsWrapper}>
                <Button
                  sx={sx.button}
                  variant="outlined"
                  color="primary"
                  onClick={() => handleClickBtnCancel(resetForm)}
                >
                  {t('CANCEL')}
                </Button>
                <Button
                  disabled={isEqual(initialValue, values)}
                  sx={sx.button}
                  variant="contained"
                  color="primary"
                  onClick={() => handleClickBtnSave(setTouched, handleSubmit)}
                >
                  {t('SAVE')}
                </Button>
              </Stack>
            </WBox>
          </StyledForm>
        );
      }}
    </Formik>
  );
};
