import {ResultDetail} from '@src/api';

export const formatOrderDetails = (data: ResultDetail[]) => {
  if (!data) {
    return null;
  }

  const sortedData = data.sort((a, b) => {
    const dateA = new Date(a?.orderDetails?.collected).getTime();
    const dateB = new Date(b?.orderDetails?.collected).getTime();
    return dateB - dateA;
  });

  return sortedData.map((item) => ({
    id: item?.id,
    providerId: item?.orderDetails?.provider?.id,
    collected: item?.orderDetails?.collected,
    firstName: item?.orderDetails?.provider?.firstName,
    lastName: item?.orderDetails?.provider?.lastName,
    middleName: item?.orderDetails?.provider?.middleName,
    type: item.type,
    tests: item,
  }));
};
