import {SelectChangeEvent, Stack, Typography} from '@mui/material';
import {SettingsButton} from '@src/components/fragments/SettingsButton';
import {initialFilter} from '@src/pages/Results/Results.constant';
import {dateInInterval} from '@src/pages/Results/Results.helpers';
import {TableRow} from '@src/pages/Results/TableRow';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {getFullName} from '@src/shared/utils';
import {sortByDate} from '@src/shared/utils/sortByDate';
import {useContactRelations, useRelationResults} from '@src/store/relations/hooks';
import {Result} from 'api';
import {EmptySearch, Table, ITableScrollUp} from 'components';
import {BoxBody, WhiteBox} from 'components/WhiteBox/WhiteBox';
import {useTranslate} from 'i18n/useTranslate';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';

import {Loader} from '../Loader';

import {ResultsFilterDialog} from './components/ResultsFilterDialog';
import {ResultsFilterTable} from './components/ResultsFilterTable';
import {ResultsTable} from './components/ResultsTable';
import {initiialSort, useHeader} from './constants';
import {sx} from './styled';

export const ResultsTab = () => {
  const {mobile, tablet, desktop, lg, tabletPortrait} = useMQuery();
  const {t} = useTranslate('results');
  const {id} = useParams();

  const {relationId} = useContactRelations(id);
  const {results: data, fetching: isLoading} = useRelationResults(relationId);

  const [filter, setFilter] = useState(initialFilter);
  const [tempFilter, setTempFilter] = useState(filter);
  const [isLocalLoading, setIsLocalLoading] = useState(true);
  const [result, setResult] = useState<Result[]>();
  const [filterOpened, setFilterOpened] = useState(false);
  const [selectItem, setSelectItem] = useState('');
  const [tempSelectItem, setTempSelectItem] = useState(selectItem);
  const [filterApplied, setFilterApplied] = useState(false);
  const [sort, setSort] = useState(initiialSort);
  const [collectedPosition, setCollectedPosition] = useState(true);

  const refTable = useRef<ITableScrollUp>();

  const gridTemplateColumns = mobile || tabletPortrait ? '1fr 1fr 24px' : '160px 160px 1fr 90px';

  const calcPaddingLeft = () => {
    if (tablet && !tabletPortrait) return '24px';
    if (mobile || tabletPortrait) return '42px';
    return '36px';
  };

  const paddingLeft = calcPaddingLeft();

  const handleSetResult = (newValue: Result[]) => {
    setResult(newValue);
    setIsLocalLoading(false);
  };

  useEffect(() => {
    if (isLoading) {
      setIsLocalLoading(true);
    }
  }, [isLoading]);

  useEffect(() => {
    if (data) handleSetResult(data);
  }, [data]);

  const getPhysicians = [
    ...new Map(data?.map((item) => [item?.physician?.id, item.physician])).values(),
  ]
    .map((item) => ({value: item?.id || '', name: getFullName(item), key: item?.id || ''}))
    .filter((item) => item.name && item.value);

  const handleApplyFilter = useCallback(() => {
    const {physician, collectionDateFrom, collectionDateTo} =
      tempFilter;
    setFilter(tempFilter);
    setSelectItem(tempSelectItem);
    setFilterApplied(true);
    const doFiltration = (resultItem: Result) => {
      const filterByPhysician = physician ? resultItem.physician?.id === physician.value : true;

      const filterByCollectionDate =
        collectionDateFrom || collectionDateTo
          ? dateInInterval(collectionDateFrom, collectionDateTo, resultItem.collected)
          : true;
      return filterByPhysician && filterByCollectionDate;
    };
    if (data) handleSetResult(data?.filter((item) => doFiltration(item)));
    refTable.current?.scrollUp();
    setFilterOpened(false);
  }, [data, filter, tempFilter]);

  const handleClearFilter = () => {
    setTempSelectItem('');
    setTempFilter(initialFilter);
  };

  const handleClearCollectionDate = () => {
    setTempFilter((prev) => {
      return {
        ...prev,
        collectionDateFrom: null,
        collectionDateTo: null,
      };
    });
  };

  const handlePhysicianSelect = (event: SelectChangeEvent<string>) => {
    setTempSelectItem(event.target.value);
    const data = getPhysicians.find((item) => item.name === event.target.value);
    if (data && event) {
      const item = event?.target?.value;
      setTempFilter((prev) => ({
        ...prev,
        physician: item ? {value: data?.value, name: data?.name} : null,
      }));
    }
  };

  const filterResultByDate = (columnName: string) => () => {
    setSort((prev) => ({...prev, sortBy: columnName, sortDesc: !prev.sortDesc}));
    if (columnName === 'collected') setCollectedPosition((prev) => !prev);
  };

  const {HEADER_LIST} = useHeader(filterResultByDate, collectedPosition);

  const handleChangeDate = (name: string) => (date: Date | null) => {
    setTempFilter((prev) => ({...prev, [name]: date}));
  };

  const handleOpenFilterClick = () => {
    setFilterOpened(true);
    setFilterApplied(false);
    setTempFilter(filter);
    setTempSelectItem(selectItem);
  };

  const handleCloseFilterDialog = () => {
    if (!filterApplied) {
      setTempFilter(filter);
      setTempSelectItem(selectItem);
    }
    setFilterOpened(false);
  };

  useEffect(() => {
    if (!filterOpened) {
      handleApplyFilter();
    }
  }, [tempFilter]);

  useEffect(() => {
    if (result) {
      const arrayForSort = [...result];
      handleSetResult(arrayForSort.sort((a, b) => sortByDate(a, b, sort)));
    }
  }, [sort]);

  const isDisplayedSwich = result?.length !== 0;

  if (isLocalLoading) {
    return <Loader />;
  }

  if (data?.length === 0) {
    return (
      <Stack p={mobile ? 0 : 24}>
        <Typography variant="24_34_500">{t('RESULTS')}</Typography>
        <Typography variant="14_18_500" sx={sx.patientNotFound}>
          {t('NO_RESULTS_WERE_FOUND')}
        </Typography>
      </Stack>
    );
  }

  return (
    <>
      {(mobile || tablet) && (
        <Stack
          sx={{
            ...sx.headerWrapper,
            padding: !mobile && !tabletPortrait ? '24px 24px 0px 24px' : 0,
          }}>
          <Typography variant='22_26_500'>{t('RESULTS')}</Typography>
          <SettingsButton
            sx={{boxSizing: 'initial'}}
            onClick={handleOpenFilterClick}
          />
        </Stack>
      )}
      <WhiteBox noPaddings noBorder={(!mobile && !tabletPortrait) || !isDisplayedSwich}>
        {desktop && (
          <Stack
            sx={{
              ...sx.headerWrapper,
              p: {lg: 24, xl: '24px 24px 0 24px'},
            }}>
            <Typography component="h4" variant="24_34_500">
              {t('RESULTS')}
            </Typography>
            {lg && <SettingsButton onClick={handleOpenFilterClick} />}
          </Stack>
        )}
        <BoxBody>
          {!lg && desktop && (
            <ResultsFilterTable
              filter={tempFilter}
              selectItem={selectItem}
              changeSelectPhysicians={handlePhysicianSelect}
              getPhysicians={getPhysicians}
              changeDate={handleChangeDate}
              onClearCollectionDate={handleClearCollectionDate}
              onClear={handleClearFilter}
            />
          )}
          {(mobile || tabletPortrait) && (
            <Table
              classNames={{header: 'table-header'}}
              ref={refTable}
              gridTemplateColumns={gridTemplateColumns}
              paddingLeft={paddingLeft}
              data={result || []}
              headerList={HEADER_LIST}
              loading={isLocalLoading ?? false}
              rowComponent={{component: TableRow, props: {isConnections: true}}}
              emptyData={<EmptySearch isLoading={isLocalLoading} isNew isResults/>}
            />
          )}
          {!mobile && !tabletPortrait && (
            <ResultsTable
              result={result}
              isLoading={isLocalLoading}
              isConnections
            />
          )}
        </BoxBody>
      </WhiteBox>
      <ResultsFilterDialog
        filter={tempFilter}
        selectItem={tempSelectItem}
        filterOpened={filterOpened}
        changeSelectPhysicians={handlePhysicianSelect}
        getPhysicians={getPhysicians}
        changeDate={handleChangeDate}
        onClearCollectionDate={handleClearCollectionDate}
        onClear={handleClearFilter}
        onApply={handleApplyFilter}
        onClose={handleCloseFilterDialog}
      />
    </>
  );
};
