import {Box, SxProps, Stack, Theme} from '@mui/material';
import {WBox} from '@src/components/WhiteBox';
import {useTranslate} from '@src/i18n/useTranslate';
import {ROUTERS_PATH} from '@src/routers';
import {OverviewProps} from '@src/types';
import {useNavigate} from 'react-router-dom';

import {Contacts} from './fragments/Contacts';
import {GeneralInfo} from './fragments/GeneralInfo';
import {OverviewEmptyFragment} from './fragments/OverviewEmptyFragment';
import {OverviewInsurance} from './fragments/OverviewInsurance';
import {OverviewLastResults} from './fragments/OverviewLastResults';
import {OverviewRelations} from './fragments/OverviewRelations';

const sx: Partial<Record<string, SxProps<Theme>>> = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: 24,
  },
  boxContainer: (t: Theme) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    minWidth: 744,
    width: '100%',
    maxWidth: '100%',
    gap: 24,
    [t.breakpoints.only('lg')]: {
      minWidth: 408,
    },
  }),
  infoContainer: {
    padding: '0',
    maxWidth: '100%',
    minHeight: 470,
  },
  contactsContainer: {
    padding: '0',
    maxWidth: '100%',
  },
};

export const OverviewDesktopLgOpenSidebar = ({
  accountProfile,
  data,
  isSidebarOpen,
  insurances,
}: OverviewProps) => {
  const {t} = useTranslate('overview');
  const navigate = useNavigate();

  return (
    <Stack sx={sx.container}>
      <Box sx={sx.boxContainer}>
        <WBox sx={sx.infoContainer}>
          <GeneralInfo
            isSidebarOpen={isSidebarOpen}
            contact={accountProfile?.contact}
            avatarUrl={accountProfile?.avatarUrl}
          />
        </WBox>
        <WBox sx={sx.contactsContainer}>
          <Contacts
            isSidebarOpen={isSidebarOpen}
            profile={accountProfile}
          />
        </WBox>
        <WBox noPaddings={true}>
          {accountProfile?.relations?.length
            ? (
              <OverviewRelations
                isSidebarOpen={isSidebarOpen}
                cardsCount={2}
              />
            )
            : (
              <OverviewEmptyFragment
                variant="14_18_500"
                title={t('RELATIONS')}
                button={t('ADD_RELATIONS')}
                isDisabled={false}
                onClick={() => navigate(ROUTERS_PATH.CONNECTIONS_ADD_RELATION)}
                description={t('NO_RELATIONSHIPS_WERE_FOUND')}
              />
            )}
        </WBox>
        <WBox noPaddings={true}>
          {data?.length
            ? (
              <OverviewLastResults results={data} />
            )
            : (
              <OverviewEmptyFragment
                variant="14_18_500"
                title={t('LAST_RESULTS')}
                description={t('NO_RESULTS')}
              />
            )}
        </WBox>
        <WBox noPaddings={true}>
          {insurances.length
            ? (
              <OverviewInsurance
                insurances={insurances}
                isSidebarOpen={isSidebarOpen}
                cardsCount={2}
              />
            )
            : (
              <OverviewEmptyFragment
                variant="14_18_500"
                isDisabled={false}
                title={t('INSURANCE')}
                button={t('ADD_INSURANCE')}
                onClick={() => navigate(ROUTERS_PATH.ADD_INSURANCE)}
                description={t('NO_INSURANCE_PLANS_WERE_FOUND')}
              />
            )}
        </WBox>
      </Box>
    </Stack>
  );
};
