import {SxProps, Theme} from '@mui/material';

export const sx = {
  container: {
    padding: 12,
    gap: 24,
    flexDirection: {sm: 'row'},
    alignItems: {sm: 'center'},
    background: theme => theme.colors.all.grey7,
    border: '1px solid',
    borderColor: theme => theme.colors.all.grey11,
    '--icon-fill': theme => theme.colors.all.grey10,
  },
  biomarkerSelect: {
    width: 170,
  },
  noResultContainer: {
    pt: 6,
    width: 240,
    color: theme => theme.colors.defaultTextColor,
  },
  optionText: {
    whiteSpace: 'wrap',
  },
  valueInput: {
    width: 126,
    '*': {
      minWidth: 'unset',
    },
    '.MuiInputBase-root:after': {
      borderRight: 'unset',
    },
    '.MuiInputBase-input': {
      pr: 10,
    },
  },
  unitOption: {
    width: {sm: 110, xs: 126},
  },
  unitSelect: {
    width: {sm: 114, xs: 126},
    maxWidth: {sm: 114, xs: 126},
  },
  wrapperForm: {
    flexDirection: 'row',
    width: {
      xs: 'calc(100% - 54px)',
      sm: 'auto',
    },
  },
} satisfies Record<string, SxProps<Theme>>;
