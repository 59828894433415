import {Panel, Product, AccountDetails, LaboratoryResultDetail, ManualResultDetail, Test, TestStatus} from '@api';
import {TKeys} from '@src/i18n/useTranslate';
import {dateFormatted, getAge, getFullName} from '@src/shared/utils';
import {getResultTitle} from '@src/shared/utils/getResultTitle';

export const getReferenceText = (test: Test, t: (str?: TKeys<'details'> | null) => string) => {
  if (test.status !== null && test.status !== TestStatus.COMPLETED) {
    return '';
  }

  const results = [];
  const {reference, criticalReference, qualityReference} = test;

  if (reference?.minValue != null && reference?.maxValue != null) {
    const referenceText = `${t('OPTIMAL')}: ${reference.minValue} - ${reference.maxValue}`;
    results.push(referenceText);
  }

  if (criticalReference) {
    if (criticalReference.minValue !== null && criticalReference.maxValue !== null) {
      const criticalText = `${t('CRITICAL')}: <${criticalReference.minValue}; >${
        criticalReference.maxValue
      }`;
      results.push(criticalText);
    } else if (criticalReference.minValue !== null) {
      const criticalText = `${t('CRITICAL')}: <${criticalReference.minValue}`;
      results.push(criticalText);
    } else if (criticalReference.maxValue !== null) {
      const criticalText = `${t('CRITICAL')}: >${criticalReference.maxValue}`;
      results.push(criticalText);
    }
  }

  if (
    reference?.minValue !== null &&
    reference?.maxValue !== null &&
    criticalReference &&
    criticalReference?.minValue !== null &&
    criticalReference?.maxValue !== null
  ) {
    const abnormalText = `${t('ABNORMAL')}: ${reference?.minValue || ''} - ${
      criticalReference.minValue
    }; ${reference?.maxValue || ''} - ${criticalReference.maxValue}`;
    results.push(abnormalText);
  }

  if (qualityReference) {
    results.push(qualityReference.value);
  }

  return results.length > 0 ? results.join('\n') : '';
};

export const getBreakpoints = () => ({
  xxl: 1500,
  xl: 1381,
  lg: 1116,
  sm: 700,
  md: 1080,
});

export const getResultColor = (test: Test) => {
  const {reference, criticalReference, qualityReference, status} = test;
  if (status === TestStatus.IN_PROGRESS || status === TestStatus.REJECTED) {
    return 'grey.500';
  }

  const isOutReference = reference?.isOutOfRef;
  const isOutCriticalReference = criticalReference?.isOutOfRef;
  const isOutQualityReference = qualityReference?.isOutOfRef;

  if (isOutCriticalReference || isOutQualityReference) {
    return '#EA3D5C';
  }
  if (isOutReference) {
    return '#FAB41C';
  }

  if (reference !== null || criticalReference !== null || qualityReference !== null) {
    if (
      (reference?.minValue !== null && reference?.maxValue !== null) ||
      (criticalReference?.minValue !== null && criticalReference?.maxValue !== null) ||
      (qualityReference?.value !== undefined && qualityReference?.value !== null)
    ) {
      return '#05B7AC';
    }
  }

  return '';
};

export const getStatus = (status: string, value: string | null) => {
  switch (status) {
    case TestStatus.IN_PROGRESS:
      return TestStatus.IN_PROGRESS;
    case TestStatus.REJECTED:
      return TestStatus.REJECTED;
    default:
      return value;
  }
};

export const getLaboratoryData = (
  resultDetail: LaboratoryResultDetail | null | undefined,
  t: (str: TKeys<'details'> | null | undefined, params?: any) => string,
) => {
  if (!resultDetail) return null;

  const {orderDetails, patient, products, panels, healthCases} = resultDetail;
  const {birthDate, birthSex, firstName, lastName} = patient;
  const {collected, number, provider, reported} = orderDetails;

  const tableData = [...products, ...panels];

  return {
    birthDateValue: birthDate
      ? `${dateFormatted(birthDate) || ''} (${getAge(birthDate)} ${t('YEARS')})`
      : null,
    resultTitle: getResultTitle(orderDetails),
    sex: t(birthSex),
    firstName,
    lastName,
    collected: dateFormatted(collected),
    number: number.toString(),
    provider: getFullName(provider),
    reported: dateFormatted(reported),
    healthCases,
    tableData,
  };
};

export const getManualData = (
  resultDetail: ManualResultDetail | null | undefined,
  t: (str: TKeys<'details'> | null | undefined, params?: any) => string,
  account: AccountDetails | null,
) => {
  if (!resultDetail) return null;

  const {date, description, name, healthCases, storageBiomarkers, hasFile} = resultDetail;

  const tableData: Test[] =
    storageBiomarkers?.map((biomarker) => {
      return {
        name: biomarker.biomarker || '',
        value: biomarker.value || '',
        units: biomarker.unit || '',
        loinc: biomarker.biomarkerObj?.loinc || '',
        id: biomarker.id,
        qualityReference: null,
        criticalReference: null,
        reference: null,
        status: TestStatus.COMPLETED,
        code: '',
        comment: '',
      };
    }) || [];

  return {
    resultTile: date ? dateFormatted(date) : '',
    description,
    name,
    healthCases,
    sex: t(account?.birthSex),
    firstName: account?.firstName,
    lastName: account?.lastName,
    birthDateValue: account?.birthDate
      ? `${dateFormatted(account.birthDate) || ''} (${getAge(account.birthDate)} ${t('YEARS')})`
      : null,
    tableData,
    hasFile,
  };
};

const filterTests = (tests: Test[] | undefined) =>
  tests?.filter(
    (test) =>
      (test.status === TestStatus.COMPLETED && test.value) ||
      test.status === TestStatus.IN_PROGRESS ||
      test.status === TestStatus.REJECTED,
  );

export const filterValidTestsForProducts = (products: Product[]) =>
  products
    .map((product) => ({
      ...product,
      tests: filterTests(product.tests),
    }))
    .filter((product) => product.tests && product.tests.length > 0);

export const filterValidTestsForPanels = (panels: Panel[]) =>
  panels
    .map((panel) => ({
      ...panel,
      products: filterValidTestsForProducts(panel.products || []),
    }))
    .filter((panel) => panel.products && panel.products.length > 0);
