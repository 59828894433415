import {StorageFileDetails} from '@api';

import {DEFAULT_DOCUMENT_FORM_VALUES, INITIAL_BIOMARKER} from './constants';
import {EditFileDialogForm} from './types';

export const getDefaultValues = (fileDetails?: StorageFileDetails): EditFileDialogForm => {
  if (!fileDetails) {
    return DEFAULT_DOCUMENT_FORM_VALUES;
  }

  const {name, description, date, category, recognition, biomarkers, healthCases} = fileDetails;
  return {
    name,
    date,
    description,
    category,
    recognize: recognition,
    editBiomarkers: biomarkers.map(({value, biomarkerObj, biomarkerUnit, id}) => ({
      value,
      biomarkerId: biomarkerObj?.id,
      biomarkerName: biomarkerObj?.name,
      biomarkerUnitId: biomarkerUnit?.id,
      biomarkerUnitName: biomarkerUnit?.name,
      hasUnits: !!biomarkerUnit?.id,
      initialId: id,
    })),
    createBiomarkers: biomarkers?.length ? [] : [INITIAL_BIOMARKER],
    healthCaseIds: healthCases.map(({id}) => id),
  };
};
