import {createSelector} from '@reduxjs/toolkit';
import {Result} from '@src/api';
import {ResultType} from '@src/api/__generated__/webApi';
import {FORBIDDEN_CODE, NOT_FOUND_CODE} from '@src/shared/constants/statusConstants';
import {UUID} from '@src/types';
import {RootState, useAppSelector} from '@store';
import {useEffect, useMemo, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {resultsActions, selectDynamicsData} from './slice';

const selectResults = createSelector(
  (state: RootState) => state.results.results,
  (results) => {
    return results?.allIDs.map((id) => results.byID[id] as Result);
  });
const dummy: any[] = [];
export const useResults = () => {
  const results = useSelector(selectResults);
  const {fetching, filteredResults} = useSelector((state: RootState) => state.results);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resultsActions.requestResults());
  }, [dispatch]);
  return {
    // TODO
    isLoading: Boolean(fetching?.results),
    filteredResults: filteredResults ?? dummy as Result[],
    data: results ?? dummy as Result[],
  };
};

export const useResultDetail = ({resultId, type}: {resultId?: UUID, type?: string}) => {
  const {resultManualDetails, resultLaboratoryDetails, errorStatus: statusError} = useAppSelector(state => state.results);
  const dispatch = useDispatch();

  const manualDetails = resultId ? resultManualDetails?.byID[resultId] : null;
  const laboratoryDetails = resultId ? resultLaboratoryDetails?.byID[resultId] : null;
  const isLoading = !manualDetails && !laboratoryDetails && !statusError;

  const refetch = () => {
    if (resultId && type) {
      const typedType = type as ResultType;
      dispatch(resultsActions.requestResultDetails({resultId, type: typedType}));
    }
  };

  useEffect(() => {
    refetch();
  }, [resultId, dispatch]);

  const isNotFound = statusError === NOT_FOUND_CODE;
  const isForbidden = statusError === FORBIDDEN_CODE;
  return {
    isLoading,
    isFetching: false,
    resultManualDetails: manualDetails,
    resultLaboratoryDetails: laboratoryDetails,
    isNotFound,
    isForbidden,
    refetch,
  };
};

export const useEmailForm = () => {
  const dispatch = useDispatch();
  const callbacks = useMemo(() => {
    return {
      submit: ({resultId, email, type}: {resultId?: UUID, email: string, type: ResultType}) => {
        if (!resultId) {
          console.error('[useEmailForm] resultId is undefined');
        } else {
          dispatch(resultsActions.sendResultsByEmail({resultId, email, type}));
        }
      },
    };
  }, [dispatch]);
  return {
    ...callbacks,
  };
};

export const useDynamicsData = () => {
  const dynamics = useSelector(selectDynamicsData);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(false);
  }, [dynamics]);

  useEffect(() => {
    if (!dynamics.testResults.length) {
      setIsLoading(true);
      dispatch(resultsActions.requestDynamics());
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  return {
    dynamics,
    isLoading,
  };
};
