import {Interpolation} from '@emotion/react';
import {GlobalStyles, Theme} from '@mui/material';
import {alpha} from '@src/theme/utils';
import {forwardRef, ForwardRefRenderFunction} from 'react';
import {SearchControl, useInternalControlled} from 'ui-kit';

const _GoogleAutocomplete: ForwardRefRenderFunction<
HTMLInputElement | null,
{
  error?: string
  placeholder?: string
  value?: string
  label?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: VoidFunction
}
> = ({error, placeholder, value, label, onChange, onBlur}, ref) => {
  const d = useInternalControlled({onChange, value});
  return (
    <>
      <GlobalStyles styles={dropdownStyles} />
      <SearchControl
        label={label}
        simpleTextInput
        error={error}
        inputRef={ref}
        value={d.internalValue}
        onChange={d.handleChange}
        placeholder={placeholder}
        onBlur={onBlur}
      />
    </>
  );
};

export const AutocompleteInputView = forwardRef(_GoogleAutocomplete);

const dropdownStyles: Interpolation<Theme> = (t) => ({
  '.pac-item': {
    height: 40,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    border: 'none',
    span: {
      fontFamily: 'inherit',
      fontSize: '14px',
      color: 'inherit',
    },
    '&:hover': {
      backgroundColor: alpha(t.palette.secondary.main, 7),
      color: t.palette.secondary.main,
      span: {
        color: 'inherit',
      },
    },
  },
  '.pac-container': {
    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    fontFamily: 'inherit',
    zIndex: 3000,
  },
  [t.breakpoints.down('sm')]: {
    '.pac-icon-marker': {
      display: 'none',
    },
    '.pac-item': {
      display: 'block',
      padding: '10px 12px',
      minHeight: 10,
      height: 'initial',
      lineHeight: '20px',
      span: {
        whiteSpace: 'normal',
        wordBreak: 'break-word',
      },
    },
    '.pac-container': {
      paddingTop: 6,
    },
  },
});
