import {ResultDetails, ResultsHealthDetails} from '../types';

export const formatOrderDetails = (data: ResultDetails[]): ResultsHealthDetails[] => {
  if (!data) {
    return [];
  }

  const sortedData = data.sort((a, b) => {
    if ('orderDetails' in a && 'orderDetails' in b) {
      const dateA = new Date(a?.orderDetails?.collected).getTime();
      const dateB = new Date(b?.orderDetails?.collected).getTime();
      return dateB - dateA;
    }
    if ('date' in a && 'date' in b && a.date && b.date) {
      const dateA = new Date(a.date).getTime();
      const dateB = new Date(b.date).getTime();
      return dateB - dateA;
    }
    return 0;
  });

  return sortedData.map((item) => {
    if ('orderDetails' in item) {
      return {
        id: item.id,
        providerId: item.orderDetails?.provider?.id,
        collected: item.orderDetails?.collected || '',
        firstName: item.orderDetails?.provider?.firstName,
        lastName: item.orderDetails?.provider?.lastName,
        middleName: item.orderDetails?.provider?.middleName,
        type: item.type,
        tests: item.tests,
      };
    } else {
      return {
        id: item.id,
        providerId: '',
        collected: item.date || '',
        firstName: '',
        lastName: '',
        middleName: '',
        type: item.type,
        tests: item.storageBiomarkers?.map(({biomarker}) => ({name: biomarker || ''})) || [],
      };
    }
  });
};
