import {DatePickerRange} from '@components';
import {Stack} from '@mui/material';
import {TempSelect} from '@src/components/TempSelect';
import {FiltersDialog as FiltersDialogContainer} from '@src/components/fragments/FiltersDialog';
import {useTranslate} from '@src/i18n/useTranslate';
import {DYNAMICS_DATA_TEST_ID} from '@src/pages/Dynamics/testIds';
import {TimePeriod} from '@src/pages/Dynamics/types';
import {testId} from '@src/shared/utils/testId';
import {FC, useEffect, useState} from 'react';
import {ActionsButton, ActionsRow, MenuItem, SelectControl} from 'ui-kit';

import {getTimePeriod} from '../../Dynamic.helpers';
import {initialDates, initialFilters} from '../../Dynamics.constant';

import {IFilterDialogProps} from './types';

export const FiltersDialog: FC<IFilterDialogProps> = ({
  filters,
  isOpen,
  onClose,
  testsOptions,
  filteredTestsOptions,
  onSetNewFilters,
  timePeriod,
  onSetTimePeriod,
  onClearSearchValue,
  onChangeSearchValue,
}) => {
  const {t} = useTranslate('details');
  const [dialogFilters, setDialogFilters] = useState(filters);
  const [dialogTimePeriod, setDialogTimePeriod] = useState('');

  useEffect(() => {
    if (isOpen) {
      setDialogFilters(filters);
      setDialogTimePeriod(timePeriod);
    }
  }, [isOpen]);

  const changeDate = (name: string) => (date: Date | null) => {
    setDialogFilters((prevState) => ({...prevState, [name]: date}));
    setDialogTimePeriod(TimePeriod.CUSTOM);
  };

  const clearDate = () => {
    setDialogFilters((prevState) => ({...prevState, ...initialDates}));
  };

  const changeTimePeriod = (value: string) => {
    setDialogTimePeriod(value);

    const dates = getTimePeriod(value);
    setDialogFilters((prev) => ({...prev, ...dates}));
  };

  const changeTestCodes = (e: { target: { value: string[] } }) => {
    const {
      target: {value},
    } = e;
    const selectedTests = testsOptions.filter(option => value.includes(option.value));

    setDialogFilters((prev) => ({...prev, selectedTests}));
  };

  const handleTestsCheckToggle = () => {
    if (dialogFilters.selectedTests.length > testsOptions.length / 2) {
      setDialogFilters((prev) => ({...prev, selectedTests: []}));
    } else {
      setDialogFilters((prev) => ({...prev, selectedTests: testsOptions}));
    }
  };

  const handleClear = () => {
    setDialogFilters(initialFilters);
    setDialogTimePeriod('');
  };

  const handleApply = () => {
    onClose();
    onSetNewFilters(dialogFilters);
    onSetTimePeriod(dialogTimePeriod);
  };

  return (
    <FiltersDialogContainer
      isOpen={isOpen}
      title={t('FILTERS')}
      onClose={onClose}
      onApply={handleApply}
      onClear={handleClear}
    >
      <Stack gap={28}>
        <TempSelect
          value={dialogTimePeriod}
          onChange={changeTimePeriod}
          options={Object.values(TimePeriod).map(value => ({name: t(value), value}))}
          label={t('TIME_PERIOD')}
          placeholder={t('SELECT')}
        />
        <DatePickerRange
          sx={{mb: '20px !important'}}
          isIconVisible={false}
          variant="outlined"
          label={t('DATE_RANGE')}
          startDate={dialogFilters.start}
          endDate={dialogFilters.end}
          startAdornmentName="Date"
          setStartDate={changeDate('start')}
          setEndDate={changeDate('end')}
          clearDate={clearDate}
          isLabelOutside={true}
          labelTop
        />
        <SelectControl<string>
          data-test-id={testId(DYNAMICS_DATA_TEST_ID.dynamicsDialogFiltersTestsSelect)}
          value={dialogFilters.selectedTests}
          onChange={changeTestCodes}
          multiple
          label={t('TESTS')}
          placeholder={t('SELECT')}
          onSearch={onChangeSearchValue}
          onClose={onClearSearchValue}
          ignoreLabelHeight
          dropdownFooterRow={
            <ActionsRow>
              <ActionsButton
                data-test-id={testId(DYNAMICS_DATA_TEST_ID.dynamicsFiltersTestsSelectDropdownCheckUncheckAll)}
                onClick={handleTestsCheckToggle}
              >
                {dialogFilters.selectedTests.length > testsOptions.length / 2 ? t('UNCHECK_ALL') : t('CHECK_ALL')}
              </ActionsButton>
            </ActionsRow>
          }
        >
          {filteredTestsOptions.length
            ? filteredTestsOptions.map((option, index) => (
              <MenuItem
                key={index}
                value={option.value}
                data-test-id={testId(DYNAMICS_DATA_TEST_ID.dynamicsDialogFiltersTestsSelectDropdownItem)}
              >
                {option.label}
              </MenuItem>
            ))
            : (
              <ActionsRow>
                {t('NO_SUITABLE_RESULTS')}
              </ActionsRow>
            )}
        </SelectControl>
      </Stack>
    </FiltersDialogContainer>
  );
};
