import {Box, Typography} from '@mui/material';
import {EmptySearch} from '@src/components';
import {EllipsisTextTooltip} from '@src/components/EllipsisTextTooltip';
import {CRITICAL_FLAG} from '@src/pages/Connections/fragments/Relations/fragments/ResultsTab/constants';
import {EBackgroundFlagColor, EFlagColor, EFlagName} from '@src/pages/Results/Results.constant';
import {ROUTERS_PATH} from '@src/routers';
import {useMQuery, useSidebarClassObserver} from '@src/shared/hooks';
import {useWindowWidth} from '@src/shared/hooks/useWindowWidth';
import {Table as NewTable} from '@src/shared/ui/Table/Table';
import {DATE_FORMAT, dateToFormat, getFullNameWithoutMiddleName} from '@src/shared/utils';
import {useAppDispatch, useAppSelector} from '@src/store';
import {healthCasesActions} from '@src/store/healthCases/slice';
import {createColumnHelper} from '@tanstack/react-table';
import {Result} from 'api';
import {useTranslate} from 'i18n/useTranslate';
import {ChangeEvent, FC, useMemo} from 'react';
import {useMatch, useNavigate, useParams} from 'react-router-dom';
import {Checkbox} from 'ui-kit';

interface ResultsTableProps {
  result?: Result[]
  isLoading?: boolean
  isResults?: boolean
}

const emptyData = <Typography variant={'14_18_500'} color={'grey.400'}>—</Typography>;

export const ResultsTable: FC<ResultsTableProps> = ({result, isLoading, isResults}) => {
  const {t} = useTranslate('results');
  const columnHelper = createColumnHelper<Result>();
  const selectedResults = useAppSelector((state) => state.healthCases.selectedResults);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const relation = useMatch(ROUTERS_PATH.CONNECTIONS_RELATION_RESULTS);
  const params = useParams();
  const {desktop} = useMQuery();
  const windowWidth = useWindowWidth();
  const {isSidebarOpen} = useSidebarClassObserver();
  const widthColumn = desktop ? 1100 : 1200;
  const subtractWidthSidebar = isSidebarOpen && desktop ? 280 : 0;

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>, data: Result) => {
    e.nativeEvent.stopImmediatePropagation();
    dispatch(healthCasesActions.toggleResultsSelection(data.id));
  };

  const physicianColumnWidth = Math.max((widthColumn - subtractWidthSidebar) - (1920 - windowWidth), 150);

  const columns = useMemo(
    () => [
      columnHelper.accessor('id', {
        id: 'checkbox',
        header: '',
        maxSize: 50,
        enableSorting: false,
        cell: ({row}) => {
          const isChecked = selectedResults.has(row.original.id);
          return (
            <Checkbox
              value={isChecked}
              onChange={(e) => handleCheckboxChange(e, row.original)}
            />
          );
        },
      }),
      columnHelper.accessor('collected', {
        id: 'collected',
        header: t('COLLECTED'),
        minSize: 200,
        sortingFn: 'datetime',
        cell: ({getValue, row}) => {
          const date = row.original.date;
          return <Box>{dateToFormat(DATE_FORMAT, getValue() || date) || emptyData}</Box>;
        },
      }),
      columnHelper.accessor('physician', {
        id: 'physician',
        header: t('PHYSICIAN'),
        minSize: 250,
        enableSorting: false,
        cell: ({getValue}) => {
          return <Box>{getFullNameWithoutMiddleName(getValue()) || emptyData}</Box>;
        },
      }),
      columnHelper.accessor('companyId', {
        id: 'healthCases',
        header: t('HEALTH_CASES'),
        minSize: physicianColumnWidth,
        maxSize: physicianColumnWidth,
        enableSorting: false,
        cell: ({row}) => {
          const healthCases = row.original.healthCases?.map((item) => {
            return item.name;
          }) || [];

          if (!healthCases.length) {
            return emptyData;
          }
          return (
            <EllipsisTextTooltip
              variant='14_18_500'
              ellipsisText={healthCases.join(', ')}
            />
          );
        },
      }),
      columnHelper.accessor('flag', {
        id: 'flag',
        header: t('FLAG'),
        minSize: 100,
        enableSorting: false,
        cell: ({getValue}) => {
          const flag = getValue();
          return (
            <Box>
              {flag
                ? (
                  <Typography
                    variant='14_20_500'
                    padding='4px 8px'
                    sx={{backgroundColor: EBackgroundFlagColor[flag], opacity: 0.7}}
                    color={EFlagColor[flag]}>{EFlagName[flag]}</Typography>
                )
                : emptyData}
            </Box>
          );
        },
      }),
    ], [columnHelper, t, physicianColumnWidth]);

  const handleViewPage = ({id, companyId, type}: { id: string, companyId: string, type?: string }) => {
    if (relation && params?.id) {
      return navigate(
        `${ROUTERS_PATH.CONNECTIONS_RELATIONS}/relation/${params.id}/${id}/${companyId}/results`,
      );
    } else {
      const query = new URLSearchParams();
      if (type) {
        query.set('type', type);
      }
      return navigate(`${id}?${query.toString()}`);
    }
  };

  const isError = (row: any) => row.flag === CRITICAL_FLAG;

  if (!result?.length) {
    return (
      <Box p={12}>
        <EmptySearch isLoading={isLoading} isNew isResults={isResults} />
      </Box>
    );
  }

  return (
    <NewTable
      columns={columns}
      data={result}
      onRowClick={handleViewPage}
      isError={isError}
    />
  );
};
