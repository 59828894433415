import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  stackContainer: (t) => ({
    gap: 6,
    flexDirection: 'column',
    padding: {xs: '12px 18px', lg: '16px 18px'},
    justifyContent: 'space-between',
    borderTop: '1px solid',
    borderColor: t.palette.secondary[400],
    '&:last-child': {
      borderBottom: '1px solid',
      borderColor: t.palette.secondary[400],
    },
  }),
  container: {
    paddingBottom: {
      xs: 18,
      sm: 40,
      lg: 24,
    },
    overflow: 'auto',
  },
  requestAvatar: (t) => ({
    width: {xs: 40, sm: 40},
    height: {xs: 40, sm: 40},
    marginRight: 10,
    backgroundColor: t.palette.secondary[600],
    color: t.palette.common.white,
  }),
  requestType: (t) => ({
    typography: '14_20_500',
    [t.breakpoints.only('xs')]: {
      typography: '12_20_600',
    },
  }),
  emptyRequests: (t) => ({
    color: {
      xs: t.palette.grey[500],
      sm: t.palette.grey[600],
    },
    p: {
      xs: '0px 18px 18px 18px',
      sm: '0px 18px',
      lg: '0px 24px',
    },
    typography: {
      xs: '12_16_500',
      sm: '14_18_500',
    },
  }),
  boxContainer: {
    display: 'flex',
    overflow: 'hidden',
  },
  flexContainer: {
    flex: '1',
    minWidth: '10%',
    gap: 6,
  },
  date: {
    color: t => t.palette.grey[500],
    typography: {
      xs: '12_16_500',
      sm: '14_18_500',
    },
  },
};
