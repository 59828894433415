export const defaultColumns = {
  lg: 4,
  md: 4,
};

export const customColumns = {
  lg: 12,
  md: 12,
};

export const INITIAL_COLOR = 'initial';

export const laboratoryDesktopGridColumns = '348px auto 100px 160px 140px';
export const laboratoryDesktopLgGridColumns = '348px minmax(160px, 1fr) 100px 160px 140px';
export const laboratoryDesktopLgOpenSidebarGridColumns = '285px minmax(180px, 1fr) 100px 160px 140px';
export const laboratoryTabletGridColumns = '28.79% 26.77% 5.55% 12.59% 15.65%';
export const laboratoryMobileGridColumns = '50px 1fr';

export const manualDesktopGridColumns = '255px auto 100px 160px 140px';
export const manualDesktopLgGridColumns = '255px minmax(160px, 1fr) 100px 160px 140px';
export const manualDesktopLgOpenSidebarGridColumns = '196px minmax(188px, 1fr) 100px 160px 140px';
export const manualTabletGridColumns = '25.39% 28.17% 6.05% 12.59% 18.25%';
export const manualMobileGridColumns = '50px 1fr';
