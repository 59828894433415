import {
  getStorageFileDetails,
  getStorageFileThumbnails,
  StorageThumbnailType,
} from '@src/api';
import {healthCasesApi} from '@src/api/healthCases';
import {resultsApi} from '@src/api/results';
import {notifyError} from '@src/shared/utils/notifyError';
import {RootState, useAppSelector} from '@src/store';
import {healthCasesActions} from '@src/store/healthCases/slice';
import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import {CaseData, CaseFile, DocumentFile, ResultDetails} from './types';
import {updateCaseValue} from './utils/updateCaseValue';

export const useCaseData = (id?: string) => {
  const dispatch = useDispatch();
  const [caseFiles, setCaseFiles] = useState<CaseFile[]>([]);
  const [caseData, setCaseData] = useState<CaseData>();
  const [resultDetails, setResultDetails] = useState<ResultDetails[]>([]);
  const [documentsDetails, setDocumentsDetails] = useState<DocumentFile[]>([]);
  const [collectedResultsIds, setResultsCollectedIds] = useState<string[]>([]);
  const [collectedDocumentsIds, setDocumentsCollectedIds] = useState<string[]>([]);
  const [isLoadingCaseData, setIsLoadingCaseData] = useState(true);
  const [isLoadingResultsDetails, setIsLoadingResultsDetails] = useState(true);
  const [isLoadingDocumentsDetails, setIsLoadingDocumentsDetails] = useState(true);

  const {savedResults, savedDocuments, removeItems} = useAppSelector(
    (state: RootState) => state.healthCases,
  );

  const {results} = useAppSelector(
    (state: RootState) => state.results,
  );

  const isLoading = isLoadingCaseData || isLoadingResultsDetails || isLoadingDocumentsDetails;

  const handleFetchCaseFiles = async () => {
    if (!id) {
      setIsLoadingCaseData(false);
      setIsLoadingResultsDetails(false);
      setIsLoadingDocumentsDetails(false);
      return;
    }
    try {
      const response = await healthCasesApi.getHealthCaseFiles(id);
      const updatedCaseFiles = response.map((file: CaseFile) => ({
        ...file,
        value: updateCaseValue(file.value),
      }));

      const filteredCaseFiles = updatedCaseFiles.filter(
        (file) => !removeItems.includes(file.value),
      );
      setCaseFiles(filteredCaseFiles);
      return updatedCaseFiles;
    } catch (e) {
      console.error(e);
      notifyError(dispatch);
    }
  };

  const handleFetchCaseData = async () => {
    setIsLoadingCaseData(true);
    try {
      if (id) {
        const response = await healthCasesApi.getHealthCaseData(id);
        setCaseData(response);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoadingCaseData(false);
    }
  };

  const handleFetchResultsDetails = async () => {
    setIsLoadingResultsDetails(true);
    try {
      const resultResponses = await Promise.all(
        collectedResultsIds.map((id) => {
          const type = results?.byID[id]?.type;
          return resultsApi.getResultByid({id, type});
        }),
      );

      const mergedResults = await Promise.all(
        resultResponses.map(async (resultDetail) => {
          const tests = await healthCasesApi.getResultsTests({
            resultId: resultDetail.id,
          });

          return {
            ...resultDetail,
            tests,
          };
        }),
      );

      setResultDetails(mergedResults);
    } catch (e) {
      console.error(e);
      notifyError(dispatch);
    } finally {
      setIsLoadingResultsDetails(false);
    }
  };

  const handleFetchDocuments = async () => {
    setIsLoadingDocumentsDetails(true);
    try {
      const resultResponses = await Promise.all(
        collectedDocumentsIds.map(async (id) => {
          const fileData = await getStorageFileThumbnails({
            fileIds: [id],
            types: [StorageThumbnailType.SMALL],
          });
          const fileDetails = await getStorageFileDetails(id);

          return {
            ...fileDetails,
            data: fileData[0]?.data,
            thumbnailType: fileData[0]?.type,
          };
        }),
      );

      setDocumentsDetails(resultResponses);
    } catch (e) {
      console.error(e);
      notifyError(dispatch);
    } finally {
      setIsLoadingDocumentsDetails(false);
    }
  };

  const updateCollectedIds = () => {
    const {resultIds, storageIds} = caseFiles.reduce<{ resultIds: string[], storageIds: string[] }>(
      (acc, caseItem) => {
        const {type, value} = caseItem;

        if (!removeItems.includes(value)) {
          if (type === 'RESULT') {
            acc.resultIds.push(value);
          } else if (type === 'STORAGE') {
            acc.storageIds.push(value);
          }
        }

        return acc;
      },
      {resultIds: [], storageIds: []},
    );

    const uniqueResultIds = Array.from(
      new Set([...collectedResultsIds, ...resultIds, ...Array.from(savedResults)]),
    );
    setResultsCollectedIds(uniqueResultIds);
    dispatch(healthCasesActions.initialSelectedResults(new Set(resultIds)));

    const uniqueDocumentsIds = Array.from(
      new Set([...collectedDocumentsIds, ...storageIds, ...Array.from(savedDocuments)]),
    );
    setDocumentsCollectedIds(uniqueDocumentsIds);
    dispatch(healthCasesActions.initialSelectedDocuments(new Set(storageIds)));
  };

  const fetchData = async () => {
    setIsLoadingCaseData(true);
    setIsLoadingResultsDetails(true);
    setIsLoadingDocumentsDetails(true);
    await handleFetchCaseFiles();
    await handleFetchCaseData();
  };

  useEffect(() => {
    void fetchData();
  }, []);

  useEffect(() => {
    updateCollectedIds();
  }, [caseFiles, savedResults, savedDocuments]);

  useEffect(() => {
    if (collectedResultsIds.length > 0) {
      void handleFetchResultsDetails();
    } else {
      setIsLoadingResultsDetails(false);
    }
  }, [collectedResultsIds]);

  useEffect(() => {
    if (collectedDocumentsIds.length > 0) {
      void handleFetchDocuments();
    } else {
      setIsLoadingDocumentsDetails(false);
    }
  }, [collectedDocumentsIds]);

  return {caseFiles, resultDetails, caseData, documentsDetails, isLoading};
};
