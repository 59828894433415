import {Box, Stack} from '@mui/material';
import {ResultType} from '@src/api/__generated__/webApi';
import {EllipsisTextTooltip} from '@src/components/EllipsisTextTooltip';
import {ROUTERS_PATH} from '@src/routers';
import {ReactComponent as CloseIcon} from '@src/shared/assets/icons/close-icon.svg';
import {useMQuery, useSidebarClassObserver} from '@src/shared/hooks';
import {useWindowWidth} from '@src/shared/hooks/useWindowWidth';
import {Table as NewTable} from '@src/shared/ui/Table/Table';
import {DATE_FORMAT, dateToFormat} from '@src/shared/utils';
import {getName} from '@src/shared/utils/getName';
import {useAppDispatch} from '@src/store';
import {healthCasesActions} from '@src/store/healthCases/slice';
import {createColumnHelper} from '@tanstack/react-table';
import {useTranslate} from 'i18n/useTranslate';
import {FC, useMemo} from 'react';
import {generatePath} from 'react-router-dom';
import {IconButtonNew, Tooltip} from 'ui-kit';

import {ResultsHealthDetails} from '../../types';

import {ResultsHealthTableProps} from './types';

export const ResultsHealthTable: FC<ResultsHealthTableProps> = ({results}) => {
  const columnHelper = createColumnHelper<ResultsHealthDetails>();
  const {t} = useTranslate('healthCases');
  const {desktop} = useMQuery();
  const windowWidth = useWindowWidth();
  const {isSidebarOpen} = useSidebarClassObserver();
  const widthColumn = desktop ? 1390 : 1500;
  const subtractWidthSidebar = isSidebarOpen && desktop ? 280 : 0;
  const dispatch = useAppDispatch();

  const physicianColumnWidth = Math.max((widthColumn - subtractWidthSidebar) - (1920 - windowWidth), 150);

  const handleRemove = (e: React.MouseEvent<HTMLDivElement>, id: string) => {
    e.stopPropagation();
    dispatch(healthCasesActions.removeResults(id));
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('collected', {
        id: 'collected',
        header: t('COLLECTED'),
        minSize: 150,
        maxSize: 150,
        enableSorting: false,
        cell: ({getValue}) => {
          return <Box>{dateToFormat(DATE_FORMAT, getValue() || '')}</Box>;
        },
      }),
      columnHelper.accessor('firstName', {
        id: 'firstName',
        header: t('PHYSICIAN'),
        minSize: 180,
        maxSize: 180,
        enableSorting: false,
        cell: ({row}) => {
          const firstName = row.original.firstName;
          const lastName = row.original.lastName;
          const middleName = row.original.middleName;
          return (
            <EllipsisTextTooltip
              variant='14_18_500'
              ellipsisText={getName({firstName, lastName, middleName})}
            />
          );
        },
      }),
      columnHelper.accessor('tests', {
        id: 'tests',
        header: t('CONTAINS'),
        minSize: physicianColumnWidth,
        maxSize: physicianColumnWidth,
        enableSorting: false,
        cell: ({row}) => {
          if (!row?.original?.tests) return null;
          const tests = row?.original?.tests?.tests?.map((item) => item.name).join(', ');
          return (
            <EllipsisTextTooltip
              variant='14_18_500'
              ellipsisText={tests}
            />
          );
        },
      }),
      columnHelper.accessor('id', {
        id: 'id',
        header: '',
        minSize: 40,
        maxSize: 50,
        enableSorting: false,
        cell: ({getValue}) => {
          const id = getValue();
          return (
            <Stack
              justifyContent={'end'}
              onClick={(e) => handleRemove(e, id)}
            >
              <Tooltip
                title={t('REMOVE_FROM_HEALTH_CASE')}
                placement="top-start"
              >
                <IconButtonNew
                  color={'tertiary'}
                  size={'xs'}>
                  <CloseIcon />
                </IconButtonNew>
              </Tooltip>
            </Stack>
          );
        },
      }),
    ], [columnHelper, t]);

  const handleViewPage = ({id, type}: { id: string, type: ResultType }) => {
    if (!id) return;
    const query = new URLSearchParams();
    query.set('type', type);
    const resultDetailPath = generatePath(ROUTERS_PATH.RESULT_DETAILS, {
      resultId: id,
    });
    const url = `${resultDetailPath}?${query.toString()}`;
    window.open(url, '_blank');
  };

  return (
    <NewTable
      columns={columns}
      data={results}
      onRowClick={handleViewPage}
      isHiddenColumn
    />
  );
};
