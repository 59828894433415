import {FC} from 'react';
import {useController, useFormContext} from 'react-hook-form';
import {SelectControl} from 'ui-kit';

import {FormSelectProps} from './type';

export const FormSelectControlNew: FC<FormSelectProps> = ({
  name,
  options,
  showErrorText = true,
  ...restProps
}) => {
  const formContext = useFormContext();
  const {
    field: {value, onChange},
    fieldState: {error},
  } = useController({
    control: formContext.control,
    name,
  });

  const errorTextHandler = () => {
    if (showErrorText) {
      return error?.message;
    }
    return error?.message ? ' ' : error?.message;
  };

  const valueWithLabel = {
    value,
    label: options.find(option => option.value === value)?.label || '',
  };

  return (
    <SelectControl
      value={valueWithLabel}
      onChange={onChange}
      error={errorTextHandler()}
      {...restProps}
    />
  );
};
