/* eslint-disable no-comments/disallowComments */
import {
  Biomarker,
  BiomarkerUnit,
  FetchBiomarkersParams,
  FetchBiomarkersResponse,
  FetchStorageFileThumbnailsBody,
  StorageBiomarker,
  StorageFile,
  StorageFileData,
  StorageFileDetails,
  StorageFileThumbnail,
  StorageHealthCase,
} from '@src/api';
import {CreateStorageFilePayload, UpdateStorageFilePayload} from '@src/api/__generated__/webApi';
import {apiClient} from '@src/client/ApiClient';
import Qs from 'qs';

import {FetchStorageFilesRequestParams, FetchStorageFilesResponse} from './models';

const STORAGE_URL = '/api/storage';

export const getStorageFiles = async (params: FetchStorageFilesRequestParams) => {
  const response = await apiClient.get<FetchStorageFilesResponse>(`${STORAGE_URL}/search`, {
    params,
    paramsSerializer: function (params) {
      return Qs.stringify(params, {arrayFormat: 'repeat'});
    },
  });
  return response.data;
};

/**
 * @description Updating storage file
 *
 * @tags Storage
 * @name UpdateStorageFile
 * @summary Update storage file
 * @request POST:/api/storage/update/{id}
 * @docs https://patient-web.test.private.lifedl.net/swagger-ui/index.html#Storage/updateStorageFile
 * @response `200` `StorageFile` OK
 * @response `400` `ValidationError` Bad request
 * @response `401` `undefined` Unauthorized
 * @response `500` `BaseError` Internal Server Error
 */
export const updateStorageFile = async (id: string, {data}: UpdateStorageFilePayload) => {
  const formData = new FormData();
  formData.append('data', JSON.stringify(data));

  const response = await apiClient.post<StorageFile>(`${STORAGE_URL}/update/${id}`, formData);
  return response.data;
};

export const deleteStorageFile = async (id: StorageFile['id']) => {
  const response = await apiClient.post(`${STORAGE_URL}/delete/${id}`);
  return response.status;
};

/**
 * @description Getting storage file data
 *
 * @tags Storage
 * @name GetStorageFileData
 * @summary Get storage file data
 * @request GET:/api/storage/{id}
 * @docs https://patient-web.test.private.lifedl.net/swagger-ui/index.html#Storage/getStorageFileData
 * @response `200` `StorageFileData` OK
 * @response `400` `ValidationError` Bad request
 * @response `401` `undefined` Unauthorized
 * @response `500` `BaseError` Internal Server Error
 */
export const getStorageFileData = async (id: StorageFile['id']) => {
  const response = await apiClient.get<StorageFileData>(`${STORAGE_URL}/${id}`);
  return response.data;
};

export const getStorageFileDetails = async (id: StorageFile['id']) => {
  const response = await apiClient.get<StorageFileDetails>(`${STORAGE_URL}/${id}/details`);
  return response.data;
};

export const getStorageFileThumbnails = async (data: FetchStorageFileThumbnailsBody) => {
  const response = await apiClient.post<StorageFileThumbnail[]>(
    `${STORAGE_URL}/fn/get-all-thumbnails`,
    data,
  );
  return response.data;
};

export const getBiomarkers = async (params: FetchBiomarkersParams) => {
  const response = await apiClient.get<FetchBiomarkersResponse>('/api/biomarkers', {
    params,
  });
  return response.data;
};

export const getBiomarkersUnits = async (data: { requests: Array<Biomarker['id']> }) => {
  const response = await apiClient.post<BiomarkerUnit[]>(
    '/api/biomarkers/fn/get-biomarker-units-by-biomarker-ids',
    data,
  );
  return response.data;
};

/**
 * @description Creating storage file
 *
 * @tags Storage
 * @name CreateStorageFile
 * @summary Create storage file
 * @request POST:/api/storage/create
 * @docs https://patient-web.test.private.lifedl.net/swagger-ui/index.html#Storage/createStorageFile
 * @response `200` `StorageFile` OK
 * @response `400` `ValidationError` Bad request
 * @response `401` `undefined` Unauthorized
 * @response `500` `BaseError` Internal Server Error
 */
export const createStorageFile = async ({file, data}: CreateStorageFilePayload) => {
  const formData = new FormData();
  formData.append('data', JSON.stringify(data));
  if (file) {
    formData.append('file', file);
  }

  const response = await apiClient.post<StorageFile>(`${STORAGE_URL}/create`, formData);
  return response.data;
};

export const shareStorageFiles = async (storageIds: Array<StorageFile['id']>) => {
  const response = await apiClient.post<{ url: string }>(`${STORAGE_URL}/share`, {storageIds});
  return response.data;
};

export const deleteStorageBiomarker = async (id: StorageBiomarker['id']) => {
  await apiClient.delete(`${STORAGE_URL}/biomarkers/${id}`);
  return true;
};

export const getStorageHealthCases = async () => {
  const response = await apiClient.get<StorageHealthCase[]>(
    '/api/storage/fn/available-health-cases',
  );
  return response.data;
};

export * as storageApi from './';
export * from './models';
