/* eslint-disable @typescript-eslint/no-misused-promises */
import {
  Box,
  InputAdornment,
  MenuItem,
  MenuList,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {MobileInteractionView} from '@src/components/MobileInteractionView';
import {TKeys, useTranslate} from '@src/i18n/useTranslate';
import {INITIAL_STATE} from '@src/pages/HealthCase/constants';
import {HealthCaseData} from '@src/pages/HealthCase/types';
import {DEFAULT_PER_PAGE} from '@src/pages/HealthCases/constants';
import {ReactComponent as PlusIcon} from '@src/shared/assets/icons/plus.svg';
import {ReactComponent as SearchIcon} from '@src/shared/assets/icons/search-small.svg';
import {useMQuery, useSidebarClassObserver} from '@src/shared/hooks';
import {useClickOutside} from '@src/shared/hooks/useClickOutside';
import {notifyError} from '@src/shared/utils/notifyError';
import {useAppDispatch, useAppSelector} from '@src/store';
import {fetchHealthCases, healthCasesActions, saveHealthCase} from '@src/store/healthCases/slice';
import {HealthCasesFilters} from '@src/store/healthCases/types';
import {notifyActions} from '@src/store/notifications/slice';
import {initialColors} from '@src/theme';
import {debounce} from 'lodash-es';
import {
  ChangeEvent,
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {Button} from 'ui-kit';

import {CreateHealthCaseDialog} from './CreateHealthCaseDialog';
import {SEARCH_DELAY_TIME, MENU_WIDTH, MAX_HEALTH_CASE_NAME_LENGTH} from './constants';
import {MenuWrapper, sx} from './styles';
import {HealthCaseSelectorProps} from './types';
import {getFilteredItems} from './utils/getFilteredItems';
import {setupResizeObserver} from './utils/setupResizeObserver';

export const HealthCaseSelector: FC<PropsWithChildren<HealthCaseSelectorProps>> = ({
  children,
  externalIsMobile,
  isText,
  healthCases,
  containerRef,
  isDetails,
  enableDisabledBtn = true,
}) => {
  const {t} = useTranslate('healthCases');
  const {
    cases: {items, filters, ...cases},
    selectedDocuments,
    selectedResults,
    isLoading,
  } = useAppSelector((state) => state.healthCases);
  const dispatch = useAppDispatch();
  const {mobile} = useMQuery();
  const isMobile = mobile || !!externalIsMobile;
  const buttonVariant = isMobile ? 'contained' : 'outlined';
  const textVariant = isText ? 'text' : buttonVariant;
  const [isListOpen, setIsListOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [menuWidth, setMenuWidth] = useState(MENU_WIDTH);
  const [menuPosition, setMenuPosition] = useState(0);
  const [healthCase, setHealthCase] = useState(INITIAL_STATE);
  const [searchValue, setSearchValue] = useState(filters.phrase);
  const {isSidebarOpen} = useSidebarClassObserver();
  const observer = useRef<ResizeObserver | null>(null);

  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const isActive = selectedDocuments.size > 0 || selectedResults.size > 0;
  const isShowBtn = enableDisabledBtn || isActive;
  const savedIdsResults = Array.from(selectedResults);
  const savedIdsDocuments = Array.from(selectedDocuments);

  const healthCasesItems = getFilteredItems(items, healthCases);

  const countResultsText = t(savedIdsResults.length > 1
    ? 'COUNT_RESULTS_ADDED'
    : 'COUNT_RESULT_ADDED') as TKeys<'healthCases'>;

  const countDocumentText = t(savedIdsDocuments.length > 1
    ? 'COUNT_DOCUMENTS_ADDED'
    : 'COUNT_DOCUMENT_ADDED') as TKeys<'healthCases'>;

  const resultsNotificationText = t(countResultsText, {
    count: savedIdsResults.length,
  });
  const documentsNotificationText = t(countDocumentText, {
    count: savedIdsDocuments.length,
  });
  const textNotificationRender = savedIdsResults.length
    ? resultsNotificationText
    : documentsNotificationText;

  const handleOpenList = () => {
    setIsListOpen(true);
  };

  const handleCloseList = () => {
    setIsListOpen(false);
    dispatch(healthCasesActions.clearFilters());
    setSearchValue('');
  };

  const handleFetch = (isNext: boolean) => {
    if (isNext && !cases.hasNext) {
      return;
    }
    const {from, to, ...restFilters} = filters;
    void dispatch(
      fetchHealthCases({
        ...restFilters,
        from: from ? from.toISOString().split('T')[0] : undefined,
        to: to ? to.toISOString().split('T')[0] : undefined,
        startPage: isNext ? cases.page : 0,
        perPage: DEFAULT_PER_PAGE,
        isNext,
      }),
    );
  };

  const handleDispatchSetFilters = ({
    newState,
    updatedValue,
  }: {
    newState?: HealthCasesFilters
    updatedValue?: { key: keyof HealthCasesFilters, value: any }
  }) => {
    dispatch(healthCasesActions.setFilters({newState, updatedValue}));
  };

  const debouncedApplyFilters = useCallback(
    debounce((value) => {
      handleDispatchSetFilters({
        updatedValue: {key: 'phrase', value},
      });
    }, SEARCH_DELAY_TIME),
    [],
  );

  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchValue(value);
    debouncedApplyFilters(value);
  };

  const handleChangeName = (value: string) => {
    if (value.length > MAX_HEALTH_CASE_NAME_LENGTH) {
      return;
    }
    setHealthCase({...healthCase, name: value});
  };

  const saveCase = (data: HealthCaseData) => {
    return dispatch(
      saveHealthCase({
        id: data?.id || '',
        data,
        savedIdsResults,
        savedIdsDocuments,
        removeItems: [],
        isDetails,
      }),
    ).unwrap();
  };

  const notifySuccess = (data: HealthCaseData) => {
    dispatch(
      notifyActions.showNotifications([
        {id: data?.id || '', type: 'success', text: textNotificationRender},
      ]),
    );
  };

  const handleCreateLink = async (data: HealthCaseData) => {
    if (!isActive) {
      return;
    }
    try {
      await saveCase(data);
      notifySuccess(data);
    } catch (e) {
      console.error(e);
      notifyError(dispatch);
    }
    handleCloseList();
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (!observer.current) {
      if (isText && containerRef?.current && buttonRef.current) {
        observer.current = setupResizeObserver(containerRef, buttonRef, setMenuPosition);
      }
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
        observer.current = null;
      }
    };
  }, [isText, isSidebarOpen]);

  useEffect(() => {
    if (isText) return;
    if (buttonRef.current) {
      const {width} = buttonRef.current.getBoundingClientRect();
      setMenuWidth(width);
    }
  }, [isListOpen]);

  const menuRef = useClickOutside(() => {
    handleCloseList();
  });

  const handleOpenCreateHealthCase = () => {
    setIsModalOpen(true);
    setHealthCase(INITIAL_STATE);
    handleCloseList();
  };

  const handleCloseCreateModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    handleFetch(false);
  }, [filters]);

  const renderMenuList = () => {
    return (
      <>
        <TextField
          fullWidth
          sx={{backgroundColor: initialColors.white}}
          InputProps={{
            sx: sx.searchInput,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          placeholder={t('SEARCH')}
          value={searchValue}
          onChange={handleChangeSearch}
        />
        {!isLoading && !healthCasesItems.length && (
          <Box sx={sx.notFound}>
            <Typography variant="14_18_500" fontStyle={'italic'} color={'grey.400'}>
              {t('NO_RESULTS_FOUND')}
            </Typography>
          </Box>
        )}
        {!!items.length && (
          <MenuList sx={sx.menuList}>
            {healthCasesItems.map((item) => (
              <MenuItem key={item.id} sx={sx.menuItem} onClick={() => handleCreateLink(item)}>
                <Typography variant="14_18_500">{item.name}</Typography>
              </MenuItem>
            ))}
          </MenuList>
        )}
      </>
    );
  };

  return (
    <>
      <Stack sx={sx.selectedWrapper}>
        {isShowBtn && (
          <Button
            ref={buttonRef}
            disabled={!isActive}
            onClick={handleOpenList}
            fullWidth={isMobile}
            variant={textVariant}
            color="secondary"
            sx={{
              padding: isText ? '0px' : '6px 12px',
              minWidth: isText ? 'auto' : '102px',
              height: isText ? 'auto' : '42px',
            }}
          >
            {children}
          </Button>
        )}
        {isMobile && (
          <MobileInteractionView
            isOpen={isListOpen}
            title={t('ADD_TO_A_HEALTH_CASE')}
            onBack={handleCloseList}
            onClose={handleCloseList}
          >
            <Stack flex={1} overflow={'scroll'}>
              <Stack sx={sx.mobileContent}>{renderMenuList()}</Stack>
              <Box sx={sx.buttonContainer}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={handleOpenCreateHealthCase}
                >
                  {t('CREATE_NEW_HEALTH_CASE')}
                </Button>
              </Box>
            </Stack>
          </MobileInteractionView>
        )}
        {!isMobile && (
          <>
            {isListOpen && (
              <MenuWrapper ref={menuRef} menuWidth={menuWidth} menuPosition={menuPosition}>
                {renderMenuList()}
                <Box sx={sx.createButtonWrapper}>
                  <Button
                    sx={sx.createButton}
                    variant="text"
                    color="secondary"
                    onClick={handleOpenCreateHealthCase}
                  >
                    <PlusIcon />
                    <Typography variant="14_18_600">{t('CREATE_NEW_HEALTH_CASE')}</Typography>
                  </Button>
                </Box>
              </MenuWrapper>
            )}
          </>
        )}
      </Stack>
      <CreateHealthCaseDialog
        isOpen={isModalOpen}
        onClose={handleCloseCreateModal}
        healthCase={healthCase}
        handleChangeName={handleChangeName}
        handleCreateLink={handleCreateLink}
      />
    </>
  );
};
