import {Dialog, DialogTitle} from '@components';
import {Stack, Typography} from '@mui/material';
import {storageApi, StorageFileCreateBody} from '@src/api';
import {
  ResultType,
  StorageFileCategory,
  StorageFileCreateRequest,
} from '@src/api/__generated__/webApi';
import {EditResultDialog} from '@src/components/EditResultDialog';
import {useTranslate} from '@src/i18n/useTranslate';
import {envs} from '@src/shared/constants/envs';
import {notifyActions} from '@src/store/notifications/slice';
import {resultsActions} from '@src/store/results/slice';
import {useAppDispatch} from '@store';
import {FC, useState} from 'react';
import {Button} from 'ui-kit';

import {sx} from './styles';
import {AddResultDialogProps} from './types';

export const AddResultDialog: FC<AddResultDialogProps> = ({isOpen, onClose}) => {
  const {t} = useTranslate('results');
  const [isRecognize, setIsRecognize] = useState<boolean | null>(null);
  const dispatch = useAppDispatch();

  const handleSelectManual = () => setIsRecognize(false);
  const handleSelectRecognize = () => setIsRecognize(true);
  const handleClose = () => {
    setIsRecognize(null);
    onClose();
  };

  const handleSave = async (result: StorageFileCreateBody, file?: File) => {
    try {
      const {
        date,
        description,
        favorite: _favorite,
        name,
        category: _category,
        ...otherData
      } = result;
      const data: StorageFileCreateRequest = {
        date: {
          value: date,
        },
        description: {
          value: description,
        },
        favorite: {
          value: false,
        },
        name: name
          ? {
            value: name,
          }
          : undefined,
        category: StorageFileCategory.LABORATORY_REPORT,
        ...otherData,
      };

      const newResult = await storageApi.createStorageFile({data, file});

      dispatch(
        resultsActions.addManualResult({
          id: newResult.id,
          date: newResult.date,
          reported: newResult.date,
          collected: newResult.date,
          type: ResultType.MANUAL,
          healthCases: [],
        }),
      );
      dispatch(
        notifyActions.showNotifications([
          {id: '', type: 'success', text: t('RESULT_SAVED_SUCCESS')},
        ]),
      );
    } catch (e: any) {
      console.error(e);
      dispatch(
        notifyActions.showNotifications([
          {id: '', type: 'error', text: t('SMTH_WENT_WRONG', {link: envs.PATIENT_CONTACT_SUPPORT_URL})},
        ]),
      );
    }
    handleClose();
  };

  return (
    <>
      <Dialog
        open={isOpen && isRecognize === null}
        size={'sm'}
        scroll={'body'}
        border={'unset'}
        onClose={handleClose}
      >
        <DialogTitle onClose={handleClose}>{t('ADD_RESULT')}</DialogTitle>
        <Typography variant={'14_18_500'} sx={sx.textContainer}>
          {t('RECOGNITION_TIP')}
        </Typography>
        <Stack sx={sx.addActionContainer}>
          <Button color={'secondary'} onClick={handleSelectManual}>
            {t('MANUAL_ENTRY')}
          </Button>
          <Button color={'secondary'} onClick={handleSelectRecognize}>
            {t('AUTOMATIC_RECOGNITION')}
          </Button>
        </Stack>
      </Dialog>
      {isRecognize !== null && (
        <EditResultDialog
          isOpen={true}
          onClose={handleClose}
          recognize={isRecognize}
          onSave={handleSave}
        />
      )}
    </>
  );
};
