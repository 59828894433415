import {flexRender} from '@tanstack/react-table';
import {FC, useState} from 'react';

import {StyledTD, StyledTR} from '../styles';
import {ITableBody} from '../types';

export const TableBody: FC<ITableBody> = ({
  table,
  isHorizontalScrolled,
  handleOnRowClick,
  isError,
  isPointer,
  height,
  minHeight,
  isDisabled,
  isHiddenColumn,
  isNew,
  customTdPaddings,
}) => {
  const [hoveredRow, setHoveredRow] = useState<string | null>(null);

  const handleSetHoverRow = (rowId: string | null) => () => {
    if (!isHiddenColumn) {
      return;
    }
    setHoveredRow(rowId);
  };

  return (
    <tbody
      style={{
        display: 'grid',
      }}>
      {table.getRowModel().rows.map((row, rowIndex) => {
        const cells = row.getVisibleCells();
        const rows = table.getRowModel().rows;
        const lastRowIndex = rows.length - 1;
        const lastCellIndex = cells.length - 1;
        const rowIsError = isError ? isError(row.original) : false;
        const rowIsDisabled = isDisabled ? isDisabled(row.original) : false;
        const isLastRow = rowIndex === lastRowIndex;
        const rowIsNew = isNew ? isNew(row.original) : false;

        return (
          <StyledTR
            key={row.id}
            onMouseEnter={handleSetHoverRow(row.id)}
            onMouseLeave={handleSetHoverRow(null)}
            onClick={(event) => handleOnRowClick(event, row.original)}
            isError={rowIsError}
            isDisabled={rowIsDisabled}
            isPointer={isPointer}
            height={height}
            minHeight={minHeight}
            customTdPaddings={customTdPaddings}
          >
            {row.getVisibleCells().map((cell, cellIndex) => {
              const isFirstColumn = cellIndex === 0;
              const isLastColumn = cellIndex !== lastCellIndex;
              return (
                <StyledTD
                  key={cellIndex}
                  isFirstColumn={isFirstColumn}
                  isLastColumn={isLastColumn}
                  isHiddenColumn={isHiddenColumn}
                  isHorizontalScrolled={isHorizontalScrolled}
                  isRowHovered={hoveredRow === row.id}
                  height={height}
                  isError={rowIsError}
                  isLastRow={isLastRow}
                  isNew={rowIsNew}
                  sx={{
                    minWidth: cell.column.columnDef.minSize,
                    maxWidth: cell.column.columnDef.maxSize,
                  }}
                >
                  {flexRender(
                    cell.column.columnDef.cell,
                    cell.getContext(),
                  )}
                </StyledTD>
              );
            })}
          </StyledTR>
        );
      })}
    </tbody>
  );
};
