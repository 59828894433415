import {DateTime, DurationLike} from 'luxon';

const getMinusDate = (duration: DurationLike) => DateTime.now().minus(duration).toJSDate();

export const getTimePeriod = (period: string) => {
  const end = new Date();
  switch (period) {
    case 'One week':
      return {
        start: getMinusDate({week: 1}),
        end,
      };
    case 'One month':
      return {
        start: getMinusDate({month: 1}),
        end,
      };
    case 'Six month':
      return {
        start: getMinusDate({month: 6}),
        end,
      };
    case 'One year':
      return {
        start: getMinusDate({year: 1}),
        end,
      };
    default:
      return {
        start: new Date(),
        end,
      };
  }
};
