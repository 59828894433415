import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Result} from '@src/api';
import {ResultType} from '@src/api/__generated__/webApi';
import {HealthCase, HealthCaseCreateProps, healthCasesApi} from '@src/api/healthCases';
import i18n from '@src/i18n/i18n';
import {envs} from '@src/shared/constants/envs';
import {getError} from '@src/store/common/error-handlers';
import {errorHandler} from '@src/store/errorHandler';
import {storageActions} from '@src/store/storage/slice';
import {RootState} from '@store';
import {enableMapSet} from 'immer';

import {notifyActions} from '../notifications/slice';

import {INITIAL_FILTERS, INITIAL_HEALTH_CASE} from './constants';
import {
  DeleteHealthCaseProps,
  FetchHelathCasesProps,
  HealthCasesFlowSteps,
  HealthCasesState,
  SaveHealthCaseProps,
  SetFiltersProps,
} from './types';

const initialState: HealthCasesState = {
  cases: {
    items: [],
    page: 1,
    filters: INITIAL_FILTERS,
    hasNext: false,
  },
  isLoading: false,
  step: 'init',
  isSaving: false,
  result: [],
  lastCreatedCase: '',
  selectedResults: new Set<string>(),
  savedResults: new Set<string>(),
  initialResults: new Set<string>(),
  selectedDocuments: new Set<string>(),
  savedDocuments: new Set<string>(),
  initialDocuments: new Set<string>(),
  removeItems: [] as string[],
};

enableMapSet();

export const fetchHealthCases = createAsyncThunk(
  'healthCases/fetchHealthCases',
  async ({isNext, ...payload}: FetchHelathCasesProps, {rejectWithValue}) => {
    try {
      return {data: await healthCasesApi.getHealthCases(payload), isNext};
    } catch (e) {
      const error = getError(e);
      errorHandler(error);
      return rejectWithValue(error);
    }
  },
);

export const addHealthCase = createAsyncThunk(
  'healthCases/addHealthCase',
  async (payload: HealthCaseCreateProps, {rejectWithValue}) => {
    try {
      return await healthCasesApi.createHealthCase(payload);
    } catch (e) {
      const error = getError(e);
      errorHandler(error);
      return rejectWithValue(error);
    }
  },
);

export const updateHealthCase = createAsyncThunk(
  'healthCases/updateHealthCase',
  async (payload: { id: HealthCase['id'], data: HealthCaseCreateProps }, {rejectWithValue}) => {
    try {
      return await healthCasesApi.updateHealthCase(payload.id, payload.data);
    } catch (e) {
      const error = getError(e);
      errorHandler(error);
      return rejectWithValue(error);
    }
  },
);

export const saveHealthCase = createAsyncThunk(
  'healthCases/saveHealthCase',
  async (payload: SaveHealthCaseProps & { isDetails?: boolean }, {rejectWithValue, dispatch, getState}) => {
    const isEdit = !!payload.id;
    const isDetails = payload.isDetails;

    let currentHealthCase = INITIAL_HEALTH_CASE;

    try {
      const state = getState();
      const {
        healthCases: {
          cases: {items: healthCasesItems},
        },
        storage: {
          files: {
            items: documentsItems,
          },
        },
        results: {
          results: resultsItems,
        },
      } = state as RootState;

      if (isEdit) {
        const updatedHCase = healthCasesItems.find((hCase) => hCase.id === payload.id);

        if (updatedHCase?.id) {
          currentHealthCase = updatedHCase;
        }
      }

      if (!isEdit) {
        currentHealthCase = await dispatch(addHealthCase(payload.data)).unwrap();
      }
      const array = resultsItems?.byID ? Object.values(resultsItems?.byID) : [];

      const updatedManualResults: Result[] = [];

      const filteredResultsIds = payload.savedIdsResults.filter((resultId) => {
        const result = array.find((resultsItems) => resultsItems?.id === resultId);
        if (result?.type === ResultType.MANUAL) {
          updatedManualResults.push(result);
          return false;
        }
        return !result?.healthCases?.some((resultHCase) => resultHCase.id === currentHealthCase.id);
      });

      const addResultsPromises = filteredResultsIds.map((resultId) =>
        healthCasesApi.addResultToHealthCase({
          resultId,
          healthCaseId: payload.id || currentHealthCase.id,
        })
          .then(() => {
            dispatch(
              storageActions.addHealthCase({id: resultId, healthCase: currentHealthCase}),
            );
            if (!isDetails) {
              dispatch(healthCasesActions.clearSelectedResults());
            }
          })
          .catch((e) => console.error(e)),
      );

      const filteredDocumentsIds = payload.savedIdsDocuments.filter(documentId => {
        const document = documentsItems.find(documentItem => documentItem.id === documentId);
        return !document?.healthCases.some(documentHCase => documentHCase.id === currentHealthCase.id);
      });

      console.log({updatedManualResults});

      if (updatedManualResults.length) {
        updatedManualResults.forEach(result => {
          filteredDocumentsIds.push(result.id);
        });
      }

      const addDocumentsPromises = filteredDocumentsIds.map((documentId) =>
        healthCasesApi
          .addDocumentToHealthCase({
            storageId: documentId,
            healthCaseId: currentHealthCase.id,
          })
          .then(() => {
            dispatch(
              storageActions.addHealthCase({id: documentId, healthCase: currentHealthCase}),
            );
            dispatch(healthCasesActions.clearSelectedDocuments());
          })
          .catch((e) => console.error(e)),
      );

      const deleteFilesPromises = (payload.removeItems || []).map((fileId) =>
        healthCasesApi.deleteHealthCaseFile(fileId),
      );

      await Promise.all([...addResultsPromises, ...addDocumentsPromises, ...deleteFilesPromises]);

      if (isEdit && currentHealthCase.id) {
        await dispatch(updateHealthCase({id: currentHealthCase.id, data: payload.data})).unwrap();
      }

      return currentHealthCase.id;
    } catch (e) {
      console.error(e);
      return rejectWithValue(e);
    }
  },
);

export const deleteHealthCase = createAsyncThunk(
  'storage/deleteHealthCase',
  async (payload: DeleteHealthCaseProps, {rejectWithValue, dispatch}) => {
    try {
      await healthCasesApi.deleteHealthCase(payload.id);
      dispatch(
        notifyActions.showNotifications([
          {id: payload.id, type: 'success', text: i18n.t('HEALTH_CASE_DELETE_SUCCESS')},
        ]),
      );
      return payload.id;
    } catch (e) {
      dispatch(
        notifyActions.showNotifications([
          {
            id: '',
            type: 'error',
            text: i18n.t('SMTH_WENT_WRONG', {link: envs.PATIENT_CONTACT_SUPPORT_URL}),
          },
        ]),
      );
      const error = getError(e);
      return rejectWithValue(error);
    }
  },
);

export const healthCases = createSlice({
  name: 'healthCases',
  initialState,
  reducers: {
    setFilters: (state, {payload}: PayloadAction<SetFiltersProps>) => {
      if (payload.updatedValue) {
        state.cases.filters = {
          ...state.cases.filters,
          [payload.updatedValue.key]: payload.updatedValue.value,
        };
      }
      if (payload.newState) {
        state.cases.filters = payload.newState;
      }
    },
    setStep (state, {payload}: PayloadAction<{ step: HealthCasesFlowSteps }>) {
      state.step = payload.step;
    },
    toggleResultsSelection: (state, {payload}: PayloadAction<string>) => {
      if (state.selectedResults.has(payload)) {
        state.selectedResults.delete(payload);
      } else {
        state.selectedResults.add(payload);
      }
    },
    clearFilters: (state) => {
      state.cases.filters = INITIAL_FILTERS;
    },
    setResult: (state, {payload}: PayloadAction<Result[]>) => {
      state.result = payload;
    },
    saveSelectedResults: (state) => {
      state.selectedResults.forEach((result) => {
        state.savedResults.add(result);

        const index = state.removeItems.indexOf(result);
        if (index !== -1) {
          state.removeItems.splice(index, 1);
        }
      });
    },
    initialSelectedResults: (state, {payload}: PayloadAction<Set<string>>) => {
      state.initialResults = payload;
    },
    clearSelectedResults: (state) => {
      state.selectedResults.clear();
    },
    clearSavedResults: (state) => {
      state.savedResults.clear();
    },
    toggleDocumentsSelection: (state, {payload}: PayloadAction<string>) => {
      if (state.selectedDocuments.has(payload)) {
        state.selectedDocuments.delete(payload);
      } else {
        state.selectedDocuments.add(payload);
      }
    },
    saveSelectedDocuments: (state) => {
      state.selectedDocuments.forEach((file) => {
        state.savedDocuments.add(file);

        const index = state.removeItems.indexOf(file);
        if (index !== -1) {
          state.removeItems.splice(index, 1);
        }
      });
    },
    initialSelectedDocuments: (state, {payload}: PayloadAction<Set<string>>) => {
      state.initialDocuments = payload;
    },
    clearSelectedDocuments: (state) => {
      state.selectedDocuments.clear();
    },
    clearSavedDocuments: (state) => {
      state.savedDocuments.clear();
    },
    removeDocument: (state, {payload}: PayloadAction<string>) => {
      state.savedDocuments.delete(payload);
      state.initialDocuments.delete(payload);
      state.removeItems.push(payload);
    },
    removeResults: (state, {payload}: PayloadAction<string>) => {
      state.savedResults.delete(payload);
      state.initialResults.delete(payload);
      state.removeItems.push(payload);
    },
    clearRemovedItems: (state) => {
      state.removeItems = [];
    },
    clearLastCreatedCase: (state) => {
      state.lastCreatedCase = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchHealthCases.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchHealthCases.fulfilled, (state, action) => {
      if (action.payload) {
        const {data, isNext} = action.payload;
        state.cases = isNext
          ? {
            ...state.cases,
            hasNext: data.hasNext,
            page: state.cases.page + 1,
            items: [...state.cases.items, ...data.items],
          }
          : {...data, filters: state.cases.filters, page: 1};
      }
      state.isLoading = false;
    });
    builder.addCase(updateHealthCase.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateHealthCase.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateHealthCase.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(saveHealthCase.pending, (state) => {
      state.isSaving = true;
    });
    builder.addCase(saveHealthCase.fulfilled, (state) => {
      state.isSaving = false;
    });
    builder.addCase(saveHealthCase.rejected, (state) => {
      state.isSaving = false;
    });
    builder.addCase(addHealthCase.fulfilled, (state, {payload}) => {
      state.cases.items = [{...payload}, ...state.cases.items];
      state.lastCreatedCase = payload.id;
    });
  },
});

export const healthCasesReducer = healthCases.reducer;
export const healthCasesActions = healthCases.actions;
export const healthCasesStoreName = healthCases.name;
