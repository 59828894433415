import {DialogBiomarker} from '../EditDocumentDialog/types';

import {EditResultDialogForm} from './types';

export const INITIAL_BIOMARKER: DialogBiomarker = {value: '', biomarkerId: '', biomarkerName: '', biomarkerUnitId: '', hasUnits: false};

export const DEFAULT_RESULT_FORM_VALUES: EditResultDialogForm = {
  name: '',
  date: '',
  description: '',
  createBiomarkers: [INITIAL_BIOMARKER],
  recognize: false,
};

export const FILES_ACCEPT = '.pdf, .jpeg, .jpg, .png';
export const MAX_FILE_SIZE_BYTES = 1024 * 1024 * 5;
export const MAX_DESCRIPTION_LENGTH = 1000;
