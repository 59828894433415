import {Box, debounce, InputAdornment, Menu, MenuItem, Stack, TextField, Typography} from '@mui/material';
import {MobileInteractionView} from '@src/components/MobileInteractionView';
import {useTranslate} from '@src/i18n/useTranslate';
import {ReactComponent as SearchIcon} from '@src/shared/assets/icons/search-small.svg';
import {useMQuery} from '@src/shared/hooks';
import {getBoldText} from '@src/shared/utils/boldText';
import React, {FC, useEffect, useMemo, useRef, useState} from 'react';
import {Button, InputControl} from 'ui-kit';

import {DEFAULT_SCROLL_OFFSET_FETCH} from './constants';
import {sx} from './styles';
import {TempSelectProps, MSelectOption} from './types';

export const TempSelect: FC<TempSelectProps> = ({
  options,
  renderValue,
  value,
  onChange,
  label,
  mobileLabel,
  placeholder = 'Select',
  onFullMobileClose,
  onNext,
  optional,
  disabled,
  error,
  inputSx,
  noMobileFullscreen,
  noOptionsText,
  searchValue,
  onChangeSearchValue,
  compact,
}) => {
  const {t} = useTranslate('common');
  const {mobile} = useMQuery();
  const isFullscreen = mobile && !noMobileFullscreen;

  const [selectedOption, setSelectedOption] = useState<string>('');

  const [anchorEl, setAnchorEl] = useState<null | HTMLInputElement>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const optionsName = useMemo(() => {
    const valueNames: Record<string, string> = {};
    options.forEach(({name, value}) => {
      valueNames[value] = name;
    });

    return valueNames;
  }, [options]);

  useEffect(() => {
    setSelectedOption(value || '');
  }, [value]);

  const handleOpen = (event: MouseEvent) => {
    setAnchorEl(event.currentTarget as HTMLInputElement);
  };

  useEffect(() => {
    if (!disabled) {
      inputRef.current?.addEventListener('click', handleOpen);
    }
    return () => {
      inputRef.current?.removeEventListener('click', handleOpen);
    };
  }, [disabled]);

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedOption(value || '');
  };

  const handleSelectOption = (optionValue: MSelectOption['value']) => {
    if (isFullscreen) {
      setSelectedOption(optionValue);
      return;
    }
    onChange(optionValue);
    setAnchorEl(null);
  };

  const handleApply = () => {
    onChange(selectedOption);
    setAnchorEl(null);
  };

  const handleFetchNext = debounce(() => onNext?.(), 300);

  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const {scrollTop, scrollHeight, offsetHeight} = e.currentTarget;
    if (scrollHeight - offsetHeight < scrollTop + DEFAULT_SCROLL_OFFSET_FETCH) {
      handleFetchNext();
    }
  };

  const renderContent = () => {
    return (
      <Box display={'contents'}>
        {onChangeSearchValue && (
          <TextField
            fullWidth
            InputProps={{
              sx: sx.searchInput,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            placeholder={t('SEARCH')}
            value={searchValue}
            onChange={onChangeSearchValue}
          />
        )}
        {!options.length && noOptionsText && (
          <Typography p={12} variant={'14_18_500'}>
            {noOptionsText}
          </Typography>
        )}
        <Stack sx={sx.list} onScroll={handleScroll}>
          {options.map(({name, value}) => (
            <MenuItem
              sx={sx.option}
              key={value}
              onClick={() => handleSelectOption(value)}
              selected={value === selectedOption}
            >
              <Typography
                variant={'14_18_500'}
                dangerouslySetInnerHTML={{
                  __html: searchValue ? getBoldText(name, searchValue) : name,
                }} />
            </MenuItem>
          ))}
        </Stack>
      </Box>
    );
  };

  return (
    <>
      <InputControl
        error={error}
        disabled={disabled}
        readOnly
        optional={optional}
        label={label}
        compact={compact}
        placeholder={placeholder}
        value={renderValue?.(selectedOption) || optionsName[selectedOption] || ''}
        inputRef={inputRef}
        sx={{
          '.MuiInputBase-input': {
            cursor: 'pointer',
          },
          '.MuiInputBase-root:after': anchorEl && {
            border: '2px solid',
            borderColor: 'grey.600',
          },
          ...inputSx,
        }}
      />
      {isFullscreen && (
        <MobileInteractionView
          isOpen={!!anchorEl}
          title={mobileLabel || label || 'Select'}
          onBack={handleClose}
          onClose={() => {
            handleClose();
            onFullMobileClose?.();
          }}
        >
          {renderContent()}
          <Stack p={18}>
            <Button color={'secondary'} onClick={handleApply}>
              {t('APPLY')}
            </Button>
          </Stack>
        </MobileInteractionView>
      )}
      {!isFullscreen && (
        <Menu
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={handleClose}
          MenuListProps={{
            sx: {
              p: 0,
              width: anchorEl?.offsetWidth,
            },
          }}
        >
          {renderContent()}
        </Menu>
      )}
    </>
  );
};
