import {Stack, Typography, Box, Avatar, Theme} from '@mui/material';
import {AccessRequest} from '@src/api/accesses';
import {EllipsisTextTooltip} from '@src/components/EllipsisTextTooltip';
import {useTranslate} from '@src/i18n/useTranslate';
import {useMQuery, useSortOfDate} from '@src/shared/hooks';
import {getUserInitials, dateFormatted} from '@src/shared/utils';
import {getName} from '@src/shared/utils/getName';
import {useDeleteAccessesRequest} from '@src/store/accesses/hooks';
import {Link} from 'ui-kit';

import {sx} from './styles';

export const OutgoingRequests = ({data}: { data: AccessRequest[] | null }) => {
  const {t} = useTranslate('accesses');
  const {deleteRequest} = useDeleteAccessesRequest();
  const sortedData = useSortOfDate(data, 'createdDate');
  const {mobile: isMobile} = useMQuery();

  if (!data?.length) {
    return (
      <Typography
        variant='14_18_500'
        sx={sx.emptyRequests}>
        {t('NO_OUTGOING_REQUESTS')}
      </Typography>
    );
  };

  return (
    <Stack sx={sx.wrapper}>
      {sortedData?.map((request) => (
        <Stack
          sx={sx.stackWrapper}
          key={request.id}
        >
          <Box sx={sx.boxContainer}>
            <Stack sx={sx.flexContainer}>
              <EllipsisTextTooltip
                variant={isMobile ? '16_20_700' : '18_24_700'}
                ellipsisText={t('TO_NAME', {name: getName(request?.contact, {short: true})})}
                sx={sx.personName}
              />
              <Typography variant="14_18_500" sx={sx.requestType}>
                {t(request?.access)}
              </Typography>
            </Stack>
            <Avatar sx={sx.requestAvatar} src={request?.avatarUrl} alt={''}>
              <Typography variant="14_20_700">{getUserInitials(request?.contact)}</Typography>
            </Avatar>
          </Box>
          <Stack justifyContent={'space-between'} flexDirection={'row'}>
            <Link
              size='md'
              color="primary"
              onClick={() => deleteRequest({requestId: request.id})}
            >{t('DELETE')}</Link>
            <Typography sx={sx.date}>
              {dateFormatted(request.createdDate)}
            </Typography>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};
