// NOTE paletteColor - @deprecated
export const paletteColors = {
  red: {
    100: '#F9EFF2',
    200: '#F3DFE5',
    300: '#DF546D',
    400: '#A81D42',
    500: '#73001C',
    gradient: ['#A81D42', '#DF546D'],
  },
  blue: {
    100: '#EEF5F8',
    200: '#DEE9ED',
    300: '#60D2E9',
    400: '#19A1B7',
    500: '#007287',
    gradient: ['#014550', '#007287'],
  },
  grey: {
    100: '#FAFAFA',
    200: '#F3F4F6',
    300: '#C7CDD6',
    400: '#999FAB',
    500: '#858D9C',
    600: '#6B7280',
    700: '#4B5563',
    800: '#374151',
  },
  error: {
    100: '#F4E3E6',
    200: '#EA3D5C',
  },
  success: {
    100: '#DDEFEE',
    200: '#05B7AC',
  },
  warning: {
    100: '#F6EFE0',
    200: '#FAB41C',
  },
  focus: '#FF47F8',
  valid: '#05B7AC',
  info: '#0E75BF',
  waiting: '#846CC8',
  asked: '#FF810E',
  unknown: '#997A8D',
  blocked: '#4B5563',
  none: '#C7CDD6',
  shadow: '#0000001A',
};

// NOTE these were existed before designers organized them as palette colors
export const initialColors = {
  attention: paletteColors.warning[200],
  transparent: 'transparent',
  white: '#fff',
  grey7: paletteColors.grey[100],
  grey6: paletteColors.grey[200],
  grey5: '#D1D5DB',
  grey4: '#9CA3AF',
  grey3: paletteColors.grey[600],
  grey2: paletteColors.grey[700],
  grey1: paletteColors.grey[800],
  grey8: paletteColors.grey[400],
  grey9: paletteColors.grey[500],
  grey10: paletteColors.grey[300],
  grey11: '#E2E5EB',
  blueLight: '#DFF2F5',
  secondary5: paletteColors.blue[100],
  secondary10: paletteColors.blue[200],
  secondaryLight: paletteColors.blue[300],
  secondary: paletteColors.blue[400],
  secondaryDark: paletteColors.blue[500],
  secondary400: '#CFEBF0',
  secondaryAvatar: '#C6DAE1',
  errorLegacy: paletteColors.error[200],
  successLegacy: paletteColors.success[200],
  primaryLight: paletteColors.red[300],
  primary: paletteColors.red[400],
  primaryDark: paletteColors.red[500],
  grayBackground: '#EFF8FA',
  blueCardBorder: '#BFE5EB',
  activeGrayBackground: '#E2E5EB',
  fileCardBackground: '#6EC4D2',
  fileCardErrorBackground: '#F28B9E',
} as const;
