import {Dialog, DialogActions, DialogTitle} from '@components';
import {Box, Typography} from '@mui/material';
import {useTranslate} from '@src/i18n/useTranslate';
import {ReactComponent as Error} from '@src/shared/assets/icons/error.svg';
import {deleteStorageFile} from '@src/store/storage/slice';
import {useAppDispatch} from '@store';
import {FC, useState} from 'react';
import {Button} from 'ui-kit';

import {sx} from './styles';
import {DeleteDialogProps} from './types';

export const DeleteDialog: FC<DeleteDialogProps> = ({
  isOpen,
  id,
  name,
  onClose,
  afterDeleteAction,
}) => {
  const {t} = useTranslate('storage');
  const dispatch = useAppDispatch();
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);

  const handleErrorDialogOpen = () => {
    setIsErrorDialogOpen(true);
  };
  const handleErrorDialogClose = () => {
    setIsErrorDialogOpen(false);
    onClose();
  };

  const handleDelete = () => {
    void dispatch(deleteStorageFile({
      id,
      successText: t('DELETE_SUCCESS'),
    }))
      .unwrap()
      .then(() => {
        onClose();
        afterDeleteAction?.();
      })
      .catch(() => {
        handleErrorDialogOpen();
      });
  };

  return (
    <>
      <Dialog
        open={isOpen}
        size={'sm'}
        border={'unset'}>
        <DialogTitle onClose={onClose} width='65px'>{t('DELETE_DOCUMENT')}</DialogTitle>
        <Box sx={sx.mainDialogContent}>
          <Typography variant={'16_20_500'}>{t('DELETE_DOCUMENT_SURE')}</Typography>
        </Box>
        <DialogActions sx={sx.btnContainer}>
          <Button
            sx={sx.btn}
            variant={'outlined'}
            color={'secondary'}
            onClick={onClose}>
            {t('CANCEL')}
          </Button>
          <Button sx={sx.btn} color={'secondary'} onClick={handleDelete}>
            {t('DELETE')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isErrorDialogOpen} sx={sx.dialog} size={'sm'}>
        <DialogTitle sx={sx.dialogTitle} width='65px' onClose={handleErrorDialogClose}>
          {<Error />}
          {t('ERROR_EXCLAMATION')}
        </DialogTitle>
        <Box sx={sx.dialogContent}>
          <Typography variant={'14_18_500'}>{t('DOCUMENT_NOT_DELETED', {name})}</Typography>
        </Box>
        <DialogActions sx={sx.dialogActions}>
          <Button color={'white'} sx={sx.okBtn} onClick={handleErrorDialogClose}>
            {t('OK')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
