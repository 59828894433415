import {TimePeriod} from '@src/pages/Dynamics/types';
import {DynamicTestTransformed} from '@src/store/results/helpers';
import {DateTime, DurationLike} from 'luxon';

export const dataWithoutEmptyValue = (data: DynamicTestTransformed[]) => {
  const allIndexesRemove: number[] = [];
  data.forEach((item) => {
    item.resultLaboratoryAnalyses?.forEach((res, indexRes) => {
      if (res.value === null || res.value === undefined) allIndexesRemove.push(indexRes);
    });
  });
  const indexes = [...new Set(allIndexesRemove)];
  const indexEmptyInAllTests = indexes.filter(
    (inx) => allIndexesRemove.filter((allIndex) => allIndex === inx).length === data.length,
  );

  const setOfIndexes = new Set(indexEmptyInAllTests);

  const result = data.map((testItem) => {
    return {
      ...testItem,
      resultLaboratoryAnalyses:
        testItem.resultLaboratoryAnalyses?.filter(
          (item: any, indexRes: number) => !setOfIndexes.has(indexRes),
        ) || [],
    };
  });

  return result;
};

const getMinusDate = (duration: DurationLike) => DateTime.now().minus(duration).toJSDate();

export const getTimePeriod = (period: string) => {
  const end = new Date();
  switch (period as TimePeriod) {
    case TimePeriod.ONE_WEEK:
      return {
        start: getMinusDate({week: 1}),
        end,
      };
    case TimePeriod.ONE_MONTH:
      return {
        start: getMinusDate({month: 1}),
        end,
      };
    case TimePeriod.SIX_MONTH:
      return {
        start: getMinusDate({month: 6}),
        end,
      };
    case TimePeriod.ONE_YEAR:
      return {
        start: getMinusDate({year: 1}),
        end,
      };
    default:
      return {
        start: new Date(),
        end,
      };
  }
};
