import {SxProps, Theme} from '@mui/material';
import {alpha} from '@src/theme/utils';

export const sx = {
  mainContainer: (t) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: 36,
    flexGrow: 1,
    boxSizing: 'border-box',
    border: '1px solid',
    width: 'calc(100vw - 602px)',
    borderColor: alpha(t.palette.secondary.main, 14),
    '.tablet-layout &': {
      width: 'auto',
      display: 'flex',
      flexDirection: 'column',
      gap: 36,
      border: 'unset',
    },
    '.mobile-layout &': {
      width: 'auto',
      display: 'flex',
      flexDirection: 'column',
      gap: 24,
      border: 'unset',
    },
  }),
  graphicsWrapper: {
    display: 'flex',
    flex: 1,
    maxWidth: '100%',
    borderTop: '1px solid',
  },
  topContainer: {
    flexDirection: 'initial',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 'initial',
    flexGrow: 1,
    padding: 24,
    paddingBottom: 0,
    '.tablet-layout &': {
      flexDirection: 'column',
      alignItems: 'initial',
      padding: 0,
      gap: 24,
      flexGrow: 'initial',
    },
    '.mobile-layout &': {
      flexDirection: 'column',
      alignItems: 'initial',
      padding: 0,
      gap: 24,
      flexGrow: 'initial',
    },
  },
  container: {
    gap: 24,
    '.tablet-layout &': {
      flexDirection: 'column',
      alignItems: 'initial',
      padding: 0,
      gap: 24,
    },
    '.mobile-layout &': {
      flexDirection: 'column',
      alignItems: 'initial',
      padding: 0,
      gap: 24,
    },
  },
  testsSelect: {
    label: {
      flex: 'unset',
    },
  },
  mobileDialogContainer: {
    mt: 24,
  },
  emptySearchWrapper: {
    p: {xs: '36px 36px 0 22px', md: '36px 0 0 12px'},
  },
  toggleWrapper: {
    p: {sm: '18px 24px', xs: 18},
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid',
    borderColor: 'secondary.300',
  },
} satisfies Record<string, SxProps<Theme>>;
