import {StorageFileCategory} from '@api';
import {DatePickerRange} from '@components';
import {SelectChangeEvent, Stack} from '@mui/material';
import {FormSelectControl} from '@src/components/FormSelectControl';
import {MultiSelect} from '@src/components/MultiSelect';
import {FiltersDialog as FiltersDialogContainer} from '@src/components/fragments/FiltersDialog';
import {useTranslate} from '@src/i18n/useTranslate';
import {STORAGE_CATEGORY_TYPE_MAP} from '@src/shared/constants/enumsMaps';
import {useMQuery} from '@src/shared/hooks';
import {INITIAL_DATES, INITIAL_FILTERS} from '@src/store/storage/constants';
import {ChangeEvent, FC, useEffect, useState} from 'react';
import {MenuItem} from 'ui-kit';

import {categoryOptions} from '../../helpers';
import {useStorageTranslate} from '../../hooks';
import {StyledAdaptiveFieldWrapper} from '../Filters/styles';

import {FiltersDialogProps} from './types';

export const FiltersDialog: FC<FiltersDialogProps> = ({
  filters,
  onUpdateFilters,
  isOpen,
  onClose,
  isSidebarOpen,
  healthCases,
}) => {
  const {xl, xxl} = useMQuery();
  const {t: tEnums} = useTranslate('enums');
  const translate = useStorageTranslate();
  const [dialogFilters, setDialogFilters] = useState(filters);

  const [searchValueHealthCase, setSearchValueHealthCase] = useState('');

  const handleChangeSearchValue = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchValueHealthCase(value);
  };

  const handleClearSearchValue = () => {
    setSearchValueHealthCase('');
  };

  useEffect(() => {
    if (isOpen && (isSidebarOpen ? xxl : xl || xxl)) {
      onClose();
    }
  }, [xl, xxl, isOpen]);

  useEffect(() => {
    if (isOpen) {
      setDialogFilters(filters);
    }
  }, [isOpen]);

  const handleChangeHealthCases = (values: string[]) => {
    setDialogFilters((prevState) => ({...prevState, cases: values}));
  };
  const handleChangeCategory = (event: SelectChangeEvent<StorageFileCategory>) => {
    const value = event.target.value as StorageFileCategory;
    setDialogFilters((prevState) => ({...prevState, category: value}));
  };
  const handleChangeDate = (name: 'from' | 'to') => (date: Date | null) => {
    setDialogFilters((prevState) => ({...prevState, [name]: date}));
  };
  const handleClearDate = () => {
    setDialogFilters((prevState) => ({...prevState, ...INITIAL_DATES}));
  };

  const handleClearFilters = () => {
    setDialogFilters(INITIAL_FILTERS);
  };

  const handleApply = () => {
    onClose();
    onUpdateFilters(dialogFilters);
  };

  return (
    <FiltersDialogContainer
      isOpen={isOpen}
      title={translate('FILTER')}
      onClose={onClose}
      onApply={handleApply}
      onClear={handleClearFilters}
    >
      <Stack gap={24}>
        <StyledAdaptiveFieldWrapper
          downBreakpoint={'xs'}
          upBreakpoint={'lg'}
          isSidebarOpen={isSidebarOpen}
        >
          <FormSelectControl
            name="category"
            label={translate('CATEGORY')}
            placeholder={translate('SELECT')}
            value={dialogFilters.category}
            onChange={handleChangeCategory}
            renderValue={(v) => (v ? tEnums(STORAGE_CATEGORY_TYPE_MAP[v]) : '')}
          >
            {categoryOptions.map(({name, value}, key) => (
              <MenuItem key={key} value={value}>
                {tEnums(name)}
              </MenuItem>
            ))}
          </FormSelectControl>
        </StyledAdaptiveFieldWrapper>
        <StyledAdaptiveFieldWrapper downBreakpoint={'xs'} isSidebarOpen={isSidebarOpen}>
          <MultiSelect
            options={healthCases.map(({name, id: value}) => ({name, value}))}
            value={dialogFilters.cases}
            onChange={handleChangeHealthCases}
            label={translate('HEALTH_CASES')}
            placeholder={translate('SELECT')}
            onChangeSearchValue={handleChangeSearchValue}
            searchValue={searchValueHealthCase}
            onClear={handleClearSearchValue}
            onFullMobileClose={onClose}
            renderValue={(val) => (val.length > 1 ? val[0] + ` +${val.length - 1}` : val[0] || '')}
          />
        </StyledAdaptiveFieldWrapper>
        <StyledAdaptiveFieldWrapper downBreakpoint={'xs'} isSidebarOpen={isSidebarOpen}>
          <DatePickerRange
            sx={{mb: '0 !important'}}
            isIconVisible={false}
            variant="outlined"
            label={translate('DATE')}
            startDate={dialogFilters.from}
            endDate={dialogFilters.to}
            setStartDate={handleChangeDate('from')}
            setEndDate={handleChangeDate('to')}
            clearDate={handleClearDate}
            labelTop
            startRangeWithCurrentMonth
          />
        </StyledAdaptiveFieldWrapper>
      </Stack>
    </FiltersDialogContainer>
  );
};
